import { actionCreatorFactory } from "typescript-fsa";
import { User } from "../../../types/user.types";
import { Research, ResearchListAutomatic, ResearchListManual } from "core/types/research.types";

const actionCreator = actionCreatorFactory("research");

export const resetResearch = actionCreator<void>("RESET");

export const researchSuccess = actionCreator<{ research: Research }>(
  "RESEARCH_SUCCESS"
);

export const isResearchLoading = actionCreator<{ isResearchLoading: boolean }>(
  "IS_RESEARCH_LOADING"
);

export const researchList = actionCreator<{ researchList: ResearchListManual[] }>(
  "RESEARCH_LIST"
);

export const researchDetail = actionCreator<{ researchDetail: ResearchListAutomatic | undefined }>(
  "RESEARCH_DETAIL"
);

export const researchManualDetail = actionCreator<{ researchManualDetail: ResearchListManual | undefined }>(
  "RESEARCH_MANUAL_DETAIL"
);
