import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import {
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Pagination,
  Paper,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import { useTranslation } from "react-i18next";
import { practiceAsync, practiceSelectors } from "core/store/modules/practice";
import { Practice } from "core/types/practice.types";
import { Text } from "components/atoms/text";
import { PracticeListTable } from "containers/practice-list-table";
import { InfoModal } from "components/organisms/info-modal";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { Report } from "core/types/report.types";
import usePagination from "core/hooks/usePagination";
import { User } from "core/types/user.types";
import { authAsync, authSelectors } from "core/store/modules/auth";
import { ModalReportListTable } from "containers/modal-report-list-table";

interface OwnProps {}
interface StateProps {
  user?: User;
  isPracticesLoading: boolean;
  practicesList?: Practice[];
  reportList?: Report[];
  isReportLoading: boolean;
}
interface DispatchProps {
  getPracticesList: () => void;
  getReportList: () => void;
  download: (id: number) => void;
  newPractice: (practiceId?: number) => void;
  getUserDetail: () => void;
  getAdminPracticesList: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const Practices: FunctionComponent<Props> = ({
  user,
  isPracticesLoading,
  practicesList,
  isReportLoading,
  reportList,
  getUserDetail,
  getAdminPracticesList,
  getPracticesList,
  getReportList,
  download,
  newPractice,
}) => {
  const { t } = useTranslation();
  const [orderedList, setOrderedList] = useState<Practice[]>();
  const [orderedReportList, setOrderedReportList] = useState<Report[]>();
  const [practiceToGenerate, setPracticeToGenerate] = useState<Report>();
  const [open, setOpen] = useState(false);
  const [step, setStep] = useState(0);
  let [page, setPage] = useState(1);
  const [count, setCount] = useState(0);
  const PER_PAGE = 3;
  const _DATA = usePagination(reportList, PER_PAGE);
  const [order, setOrder] = useState("ASC");

  useEffect(() => {
    getUserDetail();
  }, [getUserDetail]);

  useEffect(() => {
    if (user?.role === "admin" || user?.role === "legals") {
      getAdminPracticesList();
    } else {
      getPracticesList();
    }
  }, [user]);

  useEffect(() => {
    setOrderedList(practicesList);
  }, [practicesList]);

  useEffect(() => {
    if (reportList) {
      setCount(Math.ceil(reportList.length / PER_PAGE));
      setOrderedReportList(
        reportList.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
      );
    }
  }, [reportList]);

  const handleChange = (e: any, p: any) => {
    setPage(p);
    _DATA.jump(p);
  };

  useEffect(() => {
    var orderedPractices: Practice[] | undefined = [];
    if (order === "DESC") {
      orderedPractices = practicesList?.sort(
        (a, b) =>
          new Date(b.updatedAt).getTime() - new Date(a.updatedAt).getTime()
      );
    } else if (order === "ASC") {
      orderedPractices = practicesList?.sort(
        (a, b) =>
          new Date(a.updatedAt).getTime() - new Date(b.updatedAt).getTime()
      );
    }
    setOrderedList(orderedPractices ? [...orderedPractices] : practicesList);
  }, [order]);

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Box
          paddingY={"15px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Text fontSize={"16px"} fontWeight={"700"}>
            {t("practices.title")}
          </Text>
          <FormControl variant="standard" sx={{ width: "30%" }}>
            <InputLabel
              id="select-label"
              sx={{ fontSize: "14px !important", fontWeight: "700" }}
            >
              {t("practices.order.label")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={order}
              onChange={(event: SelectChangeEvent) =>
                setOrder(event.target.value as string)
              }
            >
              <MenuItem value={"DESC"}>{t("practices.order.DESC")}</MenuItem>
              <MenuItem value={"ASC"}>{t("practices.order.ASC")}</MenuItem>
            </Select>
          </FormControl>
          <Button variant="contained" onClick={() => setOpen(true)}>
            {t("practices.button")}
          </Button>
        </Box>
        <Grid container spacing={6}>
          <Grid item xs={12} display={"inline-block"} maxWidth={"100%"}>
            <PracticeListTable
              isPracticeLoading={isPracticesLoading}
              practicesList={orderedList}
            />
          </Grid>
        </Grid>
      </Paper>
      <InfoModal
        open={open}
        setIsVisible={setOpen}
        submitText={
          step === 0
            ? t("practices.modal.button_go")
            : t("practices.modal.button_submit")
        }
        onSubmitClick={() => {
          if (step === 0) {
            setStep(1);
            getReportList();
          } else {
            setStep(0);
            setOpen(false);
            setPracticeToGenerate(undefined);
            newPractice(practiceToGenerate?.id);
          }
        }}
      >
        <>
          <Text fontSize={18} fontWeight={700} textAlign={"center"}>
            {step === 0
              ? t("practices.modal.title")
              : t("practices.modal.title_report")}
          </Text>
          {step === 1 && (
            <Grid>
              <ModalReportListTable
                isReportLoading={isReportLoading}
                reportList={_DATA.currentData()}
                practiceToGenerate={practiceToGenerate}
                setPracticeToGenerate={setPracticeToGenerate}
                onDownload={download}
              />
              <Box
                display={"flex"}
                alignItems={"center"}
                flexDirection={"column"}
              >
                <Pagination
                  count={count}
                  size="small"
                  color="primary"
                  page={page}
                  onChange={handleChange}
                  sx={{
                    marginTop: "10px",
                  }}
                />
              </Box>
            </Grid>
          )}
        </>
      </InfoModal>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    user: authSelectors.getUser(state),
    isPracticesLoading: practiceSelectors.isPracticeLoading(state),
    practicesList: practiceSelectors.getPracticesList(state),
    reportList: reportSelectors.getReportList(state),
    isReportLoading: reportSelectors.isReportLoading(state),
  }),
  (dispatch: Dispatch) => ({
    getUserDetail: () => dispatch(authAsync.getUser()),
    getReportList: () => dispatch(reportAsync.getReportList()),
    getPracticesList: () => dispatch(practiceAsync.getPractices()),
    getAdminPracticesList: () => dispatch(practiceAsync.getAdminPractices()),
    download: (id: number) => dispatch(reportAsync.downloadReport(id)),
    newPractice: (reportId?: number) =>
      dispatch(practiceAsync.newPractice(reportId)),
  })
)(Practices);
