import { actionCreatorFactory } from "typescript-fsa";
import { CheckoutSession, Transaction } from "core/types/transaction.types";

const actionCreator = actionCreatorFactory("auth");

export const resetTransaction = actionCreator<void>("RESET");

export const transaction = actionCreator<{ transaction: Transaction }>(
  "TRANSACTION"
);

export const checkoutSession = actionCreator<{
  checkoutSession: CheckoutSession;
}>("CHECKOUT_SESSION");

export const isTransactionLoading = actionCreator<{
  isTransactionLoading: boolean;
}>("IS_TRANSACTION_LOADING");

export const transactionError = actionCreator<{
  transactionError: string;
}>("TRANSACTION_ERROR");
