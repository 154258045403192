import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Practice } from "core/types/practice.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { parse } from "date-fns";
import i18n from "i18n";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate } from "react-router-dom";

interface Props {
  isPracticeLoading: boolean;
  practicesList?: Practice[];
}

const PracticeListTable: FunctionComponent<Props> = ({
  isPracticeLoading,
  practicesList,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const navigate = useNavigate();

  return (
    <div style={{ overflowX: "auto" }}>
      {isPracticeLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("practices.table.date")}</TableCell>
                <TableCell>{t("practices.table.report")}</TableCell>
                <TableCell>{t("practices.table.status")}</TableCell>
                <TableCell>{t("practices.table.last_update")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {practicesList?.map((practice) => (
                <TableRow key={practice.id}>
                  <TableCell>
                    {parse(
                      practice.createdAt,
                      "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
                      new Date()
                    ).toLocaleDateString(
                      linguaCorrente === "en" ? "en-GB" : "it-IT",
                      { year: "numeric", month: "long", day: "numeric" }
                    )}
                  </TableCell>
                  <TableCell>{practice.report.id}</TableCell>
                  <TableCell>{practice.status}</TableCell>
                  <TableCell>
                    {parse(
                      practice.updatedAt,
                      "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
                      new Date()
                    ).toLocaleDateString(
                      linguaCorrente === "en" ? "en-GB" : "it-IT",
                      { year: "numeric", month: "long", day: "numeric" }
                    )}
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      <IconButton
                        onClick={() =>
                          navigate("/my-practices/" + practice.id)
                        }
                      >
                        <InfoIcon color="info" />
                      </IconButton>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default PracticeListTable;
