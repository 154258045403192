import { withTheme } from "@emotion/react";
import { Grid } from "@mui/material";
import { Text } from "components/atoms/text";
import { customPink } from "core/theme/variants";
import { ThemeProps } from "types/theme";
import ChevronRightRoundedIcon from "@mui/icons-material/ChevronRightRounded";
import CheckRoundedIcon from "@mui/icons-material/CheckRounded";

type Props = {
  label: string;
  index: number;
  activeStep: number;
};

const Step = ({ label, index, activeStep, theme }: Props & ThemeProps) => {
  return (
    <Grid
      container
      alignItems={"center"}
      mb={"26px"}
      sx={{
        opacity: index > activeStep ? 0.5 : 1,
      }}
    >
      <Grid
        item
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        width={"46px"}
        height={"46px"}
        border={index >= activeStep ? "1px solid #4A5676" : ""}
        borderRadius={"50px"}
        marginRight={"5px"}
        sx={{
          background: index >= activeStep ? "#fff" : theme.palette.primary.main,
        }}
      >
        {index >= activeStep ? (
          <Text
            fontSize={"15px"}
            fontWeight={"600"}
            sx={{ color: customPink[600] }}
          >
            {index + 1}
          </Text>
        ) : (
          <CheckRoundedIcon
            sx={{
              color: "#1D2C54",
            }}
          />
        )}
      </Grid>
      <Grid item xs={7}>
        <Text variant="h5" fontWeight={"600"} color={"#1D2C54"}>
          {label}
        </Text>
      </Grid>
      <Grid
        item
        xs={2}
        display={"flex"}
        alignItems={"center"}
        justifyContent={"flex-end"}
      >
        {activeStep === index && (
          <ChevronRightRoundedIcon
            sx={{
              color: "#4A5676",
              width: "15px",
              height: "15px",
            }}
          />
        )}
      </Grid>
    </Grid>
  );
};

export default withTheme(Step);
