import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./domain.types";
import * as actions from "./domain.actions";

export const initialState: Readonly<State> = {
  isDomainLoading: false,
  domainList: [],
  error: undefined,
  success: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetDomain, () => ({ ...initialState }))
  .case(actions.isDomainLoading, (state, { isDomainLoading }) => ({
    ...state,
    isDomainLoading,
  }))
  .case(actions.domainList, (state, { domainList }) => ({
    ...state,
    domainList,
  }))
  .case(actions.error, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(actions.success, (state, { success }) => ({
    ...state,
    success,
  }))
  .build();
