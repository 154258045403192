import { actionCreatorFactory } from "typescript-fsa";
import { Url } from "core/types/url.types";

const actionCreator = actionCreatorFactory("search");

export const resetUrl = actionCreator<void>("RESET");

export const isUrlLoading = actionCreator<{
  isUrlLoading: boolean;
}>("IS_URL_LOADING");

export const urlList = actionCreator<{
  urlList: Url[];
}>("URL_LIST");

export const urlDetail = actionCreator<{
  urlDetail: Url;
}>("URL_DETAIL");

export const success = actionCreator<{ success: string }>("URL_SUCCESS");

export const error = actionCreator<{ error: string }>("URL_ERROR");
