import { Grid, IconButton, Paper as MuiPaper } from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { Text } from "components/atoms/text";
import { useTranslation } from "react-i18next";
import { ResearchListAutomatic, ResearchListManual, ResearchPost, Source } from "core/types/research.types";
import { Report } from "core/types/report.types";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { researchAsync, researchSelectors } from "core/store/modules/research";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { useNavigate } from "react-router-dom";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import { ResearchListTable } from "containers/research-list-table";
import { ReportListTable } from "containers/report-list-table";
import { SourcesTable } from "containers/sources-table";
import { Chart } from "components/molecules/chart";
import { NewResearch } from "pages/new-research";
import { Alert } from "components/molecules/alert";
import { User } from "core/types/user.types";
import { authSelectors } from "core/store/modules/auth";

const Paper = styled(MuiPaper)(spacing);

const Card = styled(Paper)`
  flex: 1;
  background: #fff;
  box-shadow: none;
  border-radius: 10px;
  padding: 20px;
  height: 100%;
`;

interface OwnProps {}
interface StateProps {
  researchList?: ResearchListManual[];
  isResearchLoading: boolean;
  reportList?: Report[];
  isReportLoading: boolean;
  user?: User;
}
interface DispatchProps {
  getResearchList: () => void;
  getReportList: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const Dashboard: FunctionComponent<Props> = ({
  user,
  researchList,
  isResearchLoading,
  reportList,
  isReportLoading,
  getResearchList,
  getReportList,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [orderedReportList, setOrderedReportList] = useState<Report[]>();
  const [orderedResearchList, setOrderedResearchList] =
    useState<ResearchListManual[]>();
  const [sourceList, setSourceList] = useState<Source[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [researchToRedo, setResearchToRedo] = useState<ResearchPost>();

  useEffect(() => {
    if (!openModal) {
      getResearchList();
      getReportList();
    }
  }, [openModal]);

  useEffect(() => {
    if (researchList) {
      setOrderedResearchList(
        researchList
          .sort(
            (a, b) =>
              new Date(b.creationDate).getTime() -
              new Date(a.creationDate).getTime()
          )
          .slice(0, 5)
      );
      setSourceList(getOriginSources());
    }
  }, [researchList]);

  useEffect(() => {
    if (reportList) {
      setOrderedReportList(
        reportList
          .sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
          )
          .slice(0, 3)
      );
    }
  }, [reportList]);

  const getOriginSources = () => {
    const risultatoMap = new Map<string, Source>();

    if (researchList) {
      researchList.forEach((ricerca) => {
        ricerca.payload.originSources?.forEach((origine) => {
          if (!risultatoMap.has(origine.toLowerCase())) {
            risultatoMap.set(origine.toLowerCase(), {
              source: origine.charAt(0).toUpperCase() + origine.slice(1),
              value: 1,
              impact: 0,
            });
          } else {
            const risultato = risultatoMap.get(origine.toLowerCase())!;
            risultato.value++;
          }
        });
      });

      const listaRisultati = Array.from(risultatoMap.values()).map(
        (risultato) => ({
          ...risultato,
        })
      );

      const sommaValori = listaRisultati.reduce(
        (acc, risultato) => acc + risultato.value,
        0
      );
      listaRisultati.forEach((risultato) => {
        risultato.impact = Math.round((risultato.value / sommaValori) * 100);
      });
      return listaRisultati;
    }
    return [];
  };

  return (
    <>
      {user?.billing_info?.searches_number === 0 && (
        <Alert
          title={t("alert.title")}
          subtitle={t("alert.subtitle")}
          buttonTitle={t("alert.button")}
          buttonAction={() => navigate("/my-profile")}
        />
      )}
      <Grid container spacing={3}>
        <Grid container item spacing={3}>
          <Grid item xs={12} sm={8}>
            <Card>
              <Grid
                container
                justifyContent={"space-between"}
                alignItems={"center"}
                spacing={6}
              >
                <Grid item>
                  <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
                    {t("dashboard.last_research")}
                  </Text>
                </Grid>
                <Grid item>
                  <IconButton onClick={() => navigate("/my-research")}>
                    <OpenInNewRoundedIcon color="info" />
                  </IconButton>
                </Grid>
              </Grid>
              <ResearchListTable
                researchList={orderedResearchList}
                isResearchLoading={isResearchLoading}
                onNewResearch={(research) => {
                  setOpenModal(true);
                  setResearchToRedo({
                    dateFromWhichToResearch: research.dateFromWhichToResearch,
                    impact: research.impact,
                    minDuration: research.minDuration,
                    originSources: research.originSources?.map(
                      (or) => or.charAt(0).toUpperCase() + or.slice(1)
                    ),
                    params: research.params,
                    type: research.type,
                  });
                }}
              />
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card>
              <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
                {t("dashboard.sources")}
              </Text>
              <Chart list={sourceList} />
              <SourcesTable
                isLoading={isResearchLoading || sourceList.length === 0}
                list={sourceList}
              />
            </Card>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Card>
            <Grid
              container
              justifyContent={"space-between"}
              alignItems={"center"}
              spacing={6}
            >
              <Grid item>
                <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
                  {t("dashboard.last_report")}
                </Text>
              </Grid>
              <Grid item>
                <IconButton onClick={() => navigate("/my-reports")}>
                  <OpenInNewRoundedIcon color="info" />
                </IconButton>
              </Grid>
            </Grid>
            <ReportListTable
              reportList={orderedReportList}
              isReportLoading={isReportLoading}
            />
          </Card>
        </Grid>
      </Grid>
      <NewResearch
        isVisible={openModal}
        setIsVisible={setOpenModal}
        researchFields={researchToRedo}
      />
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    researchList: researchSelectors.getResearchList(state),
    isResearchLoading: researchSelectors.isResearchLoading(state),
    reportList: reportSelectors.getReportList(state),
    isReportLoading: reportSelectors.isReportLoading(state),
    user: authSelectors.getUser(state),
  }),
  (dispatch: Dispatch) => ({
    getResearchList: () => dispatch(researchAsync.researchList()),
    getReportList: () => dispatch(reportAsync.getReportList()),
  })
)(Dashboard);
