import { HomePage } from "pages/home";
import { MyProfilePage } from "pages/my-profile";
import { MyResearchesPage } from "pages/my-researches";
import { MyPracticesPage } from "pages/my-practices";
import { AdminPage } from "pages/admin";
import { MyReportsPage } from "pages/my-reports";
import { ReportDetail } from "pages/report-detail";
import { DashboardPage } from "pages/dashboard";
import { PracticeDetailPage } from "pages/practice-detail";
import { UrlDetailPage } from "pages/url-detail";
import { DomainsPage } from "pages/domains";
import { FilmPage } from "pages/film";

const routes = [
  {
    path: "/",
    element: <HomePage />,
  },
  {
    path: "/dashboard",
    element: <DashboardPage />,
  },
  { path: "/my-profile", element: <MyProfilePage /> },
  { path: "/my-research", element: <MyResearchesPage /> },
  // { path: "/search", element: <NewResearchPage /> },
  { path: "/my-reports", element: <MyReportsPage /> },
  { path: "/my-practices", element: <MyPracticesPage /> },
  { path: "/admin", element: <AdminPage /> },
  { path: "/my-research/:id", element: <MyResearchesPage /> },
  { path: "/my-reports/:id", element: <ReportDetail /> },
  {
    path: "/my-practices/:id",
    element: <PracticeDetailPage />,
  },
  {
    path: "/my-practices/:id/:url_id",
    element: <UrlDetailPage />,
  },
  {
    path: "/domains",
    element: <DomainsPage />,
  },
  {
    path: "/film",
    element: <FilmPage />,
  },
];

export default routes;
