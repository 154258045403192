import { FunctionComponent } from "react";
import {
  AppBar as MuiAppBar,
  AppBarProps,
  Grid,
  IconButton as MuiIconButton,
  Toolbar,
} from "@mui/material";
import { Menu as MenuIcon } from "@mui/icons-material";
import { HeaderActions } from "components/organisms/header-actions";
import styled from "@emotion/styled";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { DownloadItem } from "core/types/report.types";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { User } from "core/types/user.types";
import { authSelectors } from "core/store/modules/auth";

const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

interface OwnProps {
  onSignOut: () => void;
  onDrawerToggle?: React.MouseEventHandler<HTMLElement>;
  onButtonClick: () => void;
}

interface StateProps {
  downloads?: DownloadItem[];
  user?: User;
}

interface DispatchProps {
  removeDownload: (reportID: number) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const Header: FunctionComponent<AppBarProps & Props> = ({
  onSignOut,
  onDrawerToggle,
  onButtonClick,
  removeDownload,
  user,
  ref,
  downloads,
  ...otherProps
}) => {
  const removeDownloadHandler = (id: number) => {
    removeDownload(id);
  };
  return (
    <AppBar position="sticky" elevation={0} ref={ref} {...otherProps}>
      <Toolbar>
        <Grid container alignItems="center">
          <Grid item sx={{ display: { xs: "block", md: "none" } }}>
            <IconButton
              color="inherit"
              aria-label="Open drawer"
              onClick={onDrawerToggle}
              size="large"
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item></Grid>
          <Grid item xs />
          <Grid item>
            <HeaderActions
              user={user}
              onSignOut={onSignOut}
              onButtonClick={onButtonClick}
              downloads={downloads}
              onRemoveDownloadNotification={(id: number) =>
                removeDownloadHandler(id)
              }
            />
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    downloads: reportSelectors.getDownloadList(state),
    user: authSelectors.getUser(state),
  }),
  (dispatch: Dispatch) => ({
    removeDownload: (reportID: number) =>
      dispatch(reportAsync.removeDownload(reportID)),
  })
)(Header);
