import {
  Research,
  Result,
  Channel,
  ResearchListAutomatic,
  ResearchPost,
  Param,
  ResearchListManual,
} from "../../types/research.types";

export const transformChannel = (data: any): Channel => ({
  name: data?.name,
  url: data?.url,
});

export const transformResult = (data: any): Result => ({
  affinity: data?.affinity,
  channel: transformChannel(data?.channel),
  date: data?.date,
  description: data?.description,
  duration: data?.duration,
  like: data?.like,
  source: data?.source,
  thumbnailUrl: data?.thumbnail_url,
  title: data?.title,
  url: data?.url,
  views: data?.views,
});

export const transformResearch = (data: any): Research => ({
  id: data?.id,
  results: data?.results.map((result: any) => transformResult(result)),
});

export const transformParams = (data: any): Param => ({
  name: data?.name,
  weight: data?.weight,
});

export const transformPayload = (data: any): ResearchPost => {
  let originSources: string[] = [];

  if (data?.origin_sources) {
    if (Array.isArray(data.origin_sources)) {
      originSources = data.origin_sources;
    } else if (typeof data.origin_sources === "string") {
      originSources = [data.origin_sources];
    }
  }

  return {
    dateFromWhichToResearch: data?.date_from_which_to_research,
    minDuration: data?.min_duration,
    originSources: originSources,
    params: data?.params?.map((p: any) => transformParams(p)),
    type: data?.type,
  };
};

export const transformResearchListGet = (data: any): ResearchListManual[] =>
  data.map((research: any) => transformManualResearchGet(research));

export const transformAutomaticResearchGet = (
  data: any
): ResearchListAutomatic => {
  return {
    id: data?.id,
    creationDate: data?.creation_date,
    createdAt: data?.created_at,
    payload: transformPayload(data?.payload),
    reportId: data?.report_id,
    results: data?.results?.map((r: any) => ({
      filmTitle: r?.film_title,
      params: r?.params?.map((p: any) => ({
        name: p.name,
        weight: p.weight,
      })),
      results: r?.results?.map((r: any) => transformResult(r)),
    })),
    subscriptionId: data?.subscription_id,
    userId: data?.user_id,
  };
};

export const transformManualResearchGet = (data: any): ResearchListManual => {
  return {
    id: data?.id,
    creationDate: data?.creation_date,
    payload: transformPayload(data?.payload),
    reportId: data?.report_id,
    user: {
      id: data?.user?.id,
      firstName: data?.user?.firstName,
      lastName: data?.user?.lastName,
      email: data?.user?.email,
      role: data?.user?.role,
    },
    results: data?.results?.map((d: any) => transformResult(d)),
  };
};
