import reducer, {
  initialState as practiceInitialState,
} from "./practice.reducer";
import * as practiceActions from "./practice.actions";
import * as practiceSelectors from "./practice.selectors";
import * as practiceAsync from "./practice.thunks";

export default reducer;
export * from "./practice.types";

export {
  practiceInitialState,
  practiceActions,
  practiceSelectors,
  practiceAsync,
};
