import { Report } from "../../types/report.types";

export const transformReportList = (data: any): Report[] =>
  data.map((report: any) => transformReport(report));

export const transformReport = (data: any): Report => ({
  id: data?.id,
  link: data?.link,
  researchID: data?.research_id,
  sizeInBytes: data?.size_in_bytes,
  timestamp: data?.timestamp,
});
