import { actionCreatorFactory } from "typescript-fsa";
import { User } from "../../../types/user.types";

const actionCreator = actionCreatorFactory("auth");

export const resetAuth = actionCreator<void>("RESET");
export const authSuccess = actionCreator<{ user: User }>("AUTH_SUCCESS");
export const logout = actionCreator<void>("LOGOUT");
export const isAuthLoading = actionCreator<{ isAuthLoading: boolean }>(
  "IS_AUTH_LOADING"
);
