import { ApiFactoryClients } from "../api.types";
import { transformExchangeRate, transformPlanList, transformProductList } from "./product.helpers";
import { ExchangeRate, Plan, Product } from "core/types/product.types";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getProduct: (): Promise<Product[]> =>
    codeHttpClient
      .get("/products?mode=test")
      .then(({ data }) => transformProductList(data)),
  getExchangeRate: (): Promise<ExchangeRate> =>
    codeHttpClient
      .get("/exchangeRate")
      .then(({ data }) => transformExchangeRate(data)),
  getPlans: (): Promise<Plan[]> =>
  codeHttpClient
    .get("/plans?mode=test")
    .then(({ data }) => transformPlanList(data)),
});
