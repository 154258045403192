import { Row } from "components/atoms/row";
import { ReportListTable } from "containers/report-list-table";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Report } from "core/types/report.types";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { Button, Grid, Paper } from "@mui/material";
import { Text } from "components/atoms/text";
import { practiceAsync, practiceSelectors } from "core/store/modules/practice";
import { LoadingOverlay } from "components/atoms/loading-overlay";

interface OwnProps {}
interface StateProps {
  reportList?: Report[];
  isReportLoading: boolean;
  isPracticeLoading: boolean;
}
interface DispatchProps {
  getReportList: () => void;
  newPractice: (practiceId?: number) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const MyReports: FunctionComponent<Props> = ({
  reportList,
  isReportLoading,
  isPracticeLoading,
  getReportList,
  newPractice,
}) => {
  const { t } = useTranslation();
  const [orderedList, setOrderedList] = useState<Report[]>();
  const [practiceToGenerate, setPracticeToGenerate] = useState<Report>();

  useEffect(() => {
    getReportList();
  }, []);

  useEffect(() => {
    if (reportList) {
      setOrderedList(
        reportList.sort(
          (a, b) =>
            new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
        )
      );
    }
  }, [reportList]);

  const handleCreatePractice = () => {
    newPractice(practiceToGenerate?.id);
  };

  return (
    <>
      {isPracticeLoading && <LoadingOverlay open={isPracticeLoading} />}
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
          {t("my_reports.title")}
        </Text>
        <Grid container spacing={6}>
          <Grid item xs={12} display={"inline-block"} maxWidth={"100%"}>
            <ReportListTable
              reportList={orderedList}
              isReportLoading={isReportLoading}
              practiceToGenerate={practiceToGenerate}
              setPracticeToGenerate={setPracticeToGenerate}
            />
          </Grid>
          <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
            <Button
              variant="contained"
              onClick={handleCreatePractice}
              disabled={!practiceToGenerate}
            >
              {t("my_reports.generate_practice")}
            </Button>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    reportList: reportSelectors.getReportList(state),
    isReportLoading: reportSelectors.isReportLoading(state),
    isPracticeLoading: practiceSelectors.isPracticeLoading(state),
  }),
  (dispatch: Dispatch) => ({
    newPractice: (reportId?: number) =>
      dispatch(practiceAsync.newPractice(reportId)),
    getReportList: () => dispatch(reportAsync.getReportList()),
  })
)(MyReports);
