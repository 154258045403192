import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./notify.types";
import * as actions from "./notify.actions";

export const initialState: Readonly<State> = {
  notifyList: [],
};

export default reducerWithInitialState(initialState)
  .case(actions.resetNotify, () => ({ ...initialState }))
  .case(actions.notifyList, (state, { notifyList }) => ({
    ...state,
    notifyList,
  }))
  .build();
