import { CardHeader, Grid } from "@mui/material";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { stringAvatar } from "core/helpers/avatar";

import { KeyValueComponent } from "../key-value-component";
import { Avatar } from "components/atoms/avatar";

interface Props {
  user: User;
}

const ProfileCardHeader: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <Grid container spacing={6}>
      <Grid item xs={12} display={"flex"} justifyContent={"center"}>
        {user && (
          <Avatar {...stringAvatar(user.firstName + " " + user.lastName)} />
        )}
      </Grid>
    </Grid>
    // <CardHeader
    //   avatar={
    //     user && (
    //       <Avatar {...stringAvatar(user.firstName + " " + user.lastName)} />
    //     )
    //   }
    //   title={`${user?.firstName} ${user?.lastName}`}
    //   subheader={
    //     user?.role === "admin"
    //       ? t("profile.admin-user")
    //       : t("profile.generic-user")
    //   }
    //   titleTypographyProps={{
    //     style: {
    //       fontSize: 20,
    //       fontWeight: 500,
    //     },
    //   }}
    //   subheaderTypographyProps={{
    //     style: {
    //       fontSize: 14,
    //       fontWeight: 200,
    //       color: "#fff",
    //     },
    //   }}
    //   sx={{
    //     borderBottom: "2px solid #fff",
    //     marginBottom: "8px",
    //   }}
    // />
  );
};

export default ProfileCardHeader;
