import { GridProps, Grid } from "@mui/material";
import React, { FunctionComponent } from "react";

const Row: FunctionComponent<GridProps> = ({ children, ...otherProps }) => {
  return (
    <Grid flexDirection={"row"} {...otherProps}>
      {children}
    </Grid>
  );
};

export default Row;
