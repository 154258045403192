import React from "react";
import styled from "@emotion/styled";

import { Badge, Grid, Avatar, Typography, Button } from "@mui/material";
import { stringAvatar } from "core/helpers/avatar";

import { User } from "core/types/user.types";
import { useNavigate } from "react-router-dom";

const Footer = styled.div`
  background-color: ${(props) =>
    props.theme.sidebar.footer.background} !important;
  padding: ${(props) => props.theme.spacing(2.75)}
    ${(props) => props.theme.spacing(4)};
  border-right: 1px solid rgba(0, 0, 0, 0.12);
`;

const FooterText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
`;

const FooterSubText = styled(Typography)`
  color: ${(props) => props.theme.sidebar.footer.color};
  font-size: 0.7rem;
  display: block;
  padding: 1px;
`;

const FooterBadge = styled(Badge)`
  margin-right: ${(props) => props.theme.spacing(1)};
  span {
    background-color: ${(props) =>
      props.theme.sidebar.footer.online.background};
    border: 1.5px solid ${(props) => props.theme.palette.common.white};
    height: 12px;
    width: 12px;
    border-radius: 50%;
  }
`;

interface Props {
  user?: User;
}

const SidebarFooter: React.FC<Props> = ({ user, ...rest }) => {
  const navigate = useNavigate();
  return (
    <Footer {...rest}>
      <Button
        variant="text"
        sx={{
          padding: 0,
          fontWeight: "400",
          textAlign: "left",
          width: "100%",
        }}
        onClick={() => navigate("/my-profile")}
      >
        <Grid container spacing={2}>
          <Grid item>
            <FooterBadge
              overlap="circular"
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
              variant="dot"
            >
              {user && (
                <Avatar
                  {...stringAvatar(user.firstName + " " + user.lastName)}
                />
              )}
            </FooterBadge>
          </Grid>
          <Grid item>
            {user && (
              <FooterText variant="body2">
                {user.firstName + " " + user.lastName}
              </FooterText>
            )}
            <FooterSubText variant="caption">
              {user?.role.toUpperCase()}
            </FooterSubText>
          </Grid>
        </Grid>
      </Button>
    </Footer>
  );
};

export default SidebarFooter;
