import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./transaction.types";
import * as actions from "./transaction.actions";

export const initialState: Readonly<State> = {
  transaction: undefined,
  checkoutSession: undefined,
  isTransactionLoading: false,
  transactionError: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetTransaction, () => ({ ...initialState }))
  .case(actions.transaction, (state, { transaction }) => ({
    ...state,
    transaction,
  }))
  .case(actions.checkoutSession, (state, { checkoutSession }) => ({
    ...state,
    checkoutSession,
  }))
  .case(actions.isTransactionLoading, (state, { isTransactionLoading }) => ({
    ...state,
    isTransactionLoading,
  }))
  .case(actions.transactionError, (state, { transactionError }) => ({
    ...state,
    transactionError,
  }))
  .build();
