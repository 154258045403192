import { FunctionComponent } from "react";
import { Backdrop, CircularProgress, Paper } from "@mui/material";
import { Text } from "../text";

interface Props {
  text?: string;
  open: boolean;
}

const LoadingOverlay: FunctionComponent<Props> = ({ text, open }) => {
  return (
    <Backdrop sx={{ zIndex: (theme) => theme.zIndex.drawer + 99 }} open={open}>
      {text ? (
        <Paper
          style={{
            borderRadius: "7px",
            display: "flex",
            flexDirection: "column",
            padding: "20px",
            alignItems: "center",
            maxWidth: "500px",
          }}
        >
          <Text fontWeight={"500"} mb={5} textAlign={"center"}>
            {text}
          </Text>
          <CircularProgress
            sx={{
              color: "#f92096",
            }}
          />
        </Paper>
      ) : (
        <CircularProgress
          sx={{
            color: "#f92096",
          }}
          size={100}
        />
      )}
    </Backdrop>
  );
};

export default LoadingOverlay;
