import styled from "@emotion/styled";
import {
  Box,
  CssBaseline,
  Paper as MuiPaper,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import GlobalStyle from "components/GlobalStyle";
import { Header } from "containers/app/header";
import { Sidebar } from "containers/app/sidebar";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import { spacing } from "@mui/system";
import { Layout as LayoutIcon, Search, List } from "react-feather";
import WorkOutlineRoundedIcon from "@mui/icons-material/WorkOutlineRounded";
import { SidebarItemsType } from "types/sidebar";
import { User } from "core/types/user.types";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { authSelectors } from "core/store/modules/auth";
import { NewResearch } from "pages/new-research";
import AdminPanelSettingsRoundedIcon from "@mui/icons-material/AdminPanelSettingsRounded";
import AlternateEmailRoundedIcon from "@mui/icons-material/AlternateEmailRounded";
import { useTranslation } from "react-i18next";
import MovieIcon from "@mui/icons-material/Movie";

const drawerWidth = 260;

const Root = styled.div`
  display: flex;
  min-height: 100vh;
`;

const Drawer = styled.div`
  ${(props) => props.theme.breakpoints.up("md")} {
    width: ${drawerWidth}px;
    flex-shrink: 0;
  }
`;

const AppContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const Paper = styled(MuiPaper)(spacing);

const MainContent = styled(Paper)`
  flex: 1;
  background: ${(props) => props.theme.palette.background.default};

  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    flex: none;
  }

  .MuiPaper-root .MuiPaper-root {
    box-shadow: none;
  }
`;

interface OwnProps {
  children: React.ReactNode;
  handleLogout: () => void;
}

interface StateProps {
  user?: User;
}

interface DispatchProps {}

export type Props = OwnProps & StateProps & DispatchProps;

const Layout = ({ children, handleLogout, user }: Props) => {
  const [openModal, setOpenModal] = useState(false);
  const [open, setOpen] = useState(false);
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
  const [sidebarItems, setSidebarItems] = useState<SidebarItemsType[]>([]);
  const { t } = useTranslation();

  useEffect(() => {
    if (user?.role === "admin") {
      const items = [
        {
          href: "/dashboard",
          icon: LayoutIcon,
          title: t("drawer.dashboard"),
        },
        {
          href: "/my-research",
          icon: Search,
          title: t("drawer.my-research"),
        },
        {
          href: "/my-reports",
          icon: List,
          title: t("drawer.my-reports"),
        },
        {
          href: "/my-practices",
          icon: WorkOutlineRoundedIcon,
          title: t("drawer.my-practices"),
        },
        {
          href: "/domains",
          icon: AlternateEmailRoundedIcon,
          title: t("drawer.domains"),
        },
        {
          href: "/admin",
          icon: AdminPanelSettingsRoundedIcon,
          title: t("drawer.admin"),
        },
        {
          href: "/film",
          icon: MovieIcon,
          title: t("drawer.film"),
        },
      ];
      setSidebarItems([...items]);
    } else if (user?.role === "user") {
      const items = [
        {
          href: "/dashboard",
          icon: LayoutIcon,
          title: t("drawer.dashboard"),
        },
        {
          href: "/my-research",
          icon: Search,
          title: t("drawer.my-research"),
        },
        {
          href: "/my-reports",
          icon: List,
          title: t("drawer.my-reports"),
        },
        {
          href: "/my-practices",
          icon: WorkOutlineRoundedIcon,
          title: t("drawer.my-practices"),
        },
        {
          href: "/film",
          icon: MovieIcon,
          title: t("drawer.film"),
        },
      ];
      setSidebarItems([...items]);
    } else if (user?.role === "legals") {
      const items = [
        {
          href: "/dashboard",
          icon: LayoutIcon,
          title: t("drawer.dashboard"),
        },
        {
          href: "/my-research",
          icon: Search,
          title: t("drawer.my-research"),
        },
        {
          href: "/my-reports",
          icon: List,
          title: t("drawer.my-reports"),
        },
        {
          href: "/my-practices",
          icon: WorkOutlineRoundedIcon,
          title: t("drawer.my-practices"),
        },
        {
          href: "/domains",
          icon: AlternateEmailRoundedIcon,
          title: t("drawer.domains"),
        },
        {
          href: "/film",
          icon: MovieIcon,
          title: t("drawer.film"),
        },
      ];
      setSidebarItems([...items]);
    }
  }, [user, t]);

  const handleDrawerToggle = () => {
    setOpen(!open);
  };

  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      <Drawer>
        <Box sx={{ display: { xs: "block", lg: "none" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            variant="temporary"
            open={open}
            onClose={handleDrawerToggle}
            items={[{ title: "", pages: sidebarItems }]}
            user={user}
            showFooter={user ? true : false}
          />
        </Box>
        <Box sx={{ display: { xs: "none", md: "block" } }}>
          <Sidebar
            PaperProps={{ style: { width: drawerWidth } }}
            items={[{ title: "", pages: sidebarItems }]}
            user={user}
            showFooter={user ? true : false}
          />
        </Box>
      </Drawer>
      <AppContent
        style={{
          maxWidth:
            isLgUp || isMdUp ? `calc(100vw - ${drawerWidth}px)` : "100%",
        }}
      >
        <Header
          onSignOut={handleLogout}
          onDrawerToggle={handleDrawerToggle}
          onButtonClick={() => setOpenModal(true)}
        />
        <MainContent p={isLgUp ? 12 : 5}>
          {children}
          <Outlet />
        </MainContent>
      </AppContent>
      <NewResearch isVisible={openModal} setIsVisible={setOpenModal} />
    </Root>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    user: authSelectors.getUser(state),
  }),
  (dispatch: Dispatch) => ({})
)(Layout);
