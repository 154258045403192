import { ResearchPost } from "core/types/research.types";
import { researchApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./research.actions";

export const newResearch =
  (payload: ResearchPost) => async (dispatch: Dispatch) => {
    dispatch(actions.isResearchLoading({ isResearchLoading: true }));
    try {
      const research = await researchApi.newResearch(payload);
      dispatch(actions.researchSuccess({ research }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isResearchLoading({ isResearchLoading: false }));
    }
  };

export const researchList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isResearchLoading({ isResearchLoading: true }));
  try {
    const researchList = await researchApi.getResearchList();
    dispatch(actions.researchList({ researchList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isResearchLoading({ isResearchLoading: false }));
  }
};

export const getResearchDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actions.isResearchLoading({ isResearchLoading: true }));
  try {
    const researchDetail = await researchApi.getResearch(id);
    if (researchDetail.payload.type === 300) {
      dispatch(actions.researchDetail({ researchDetail }));
    } else {
      dispatch(
        actions.researchManualDetail({ researchManualDetail: researchDetail })
      );
    }
  } catch (error) {
    console.error(error);
    dispatch(actions.researchDetail({ researchDetail: undefined }));
  } finally {
    dispatch(actions.isResearchLoading({ isResearchLoading: false }));
  }
};

export const resetDetail = () => async (dispatch: Dispatch) => {
  dispatch(actions.isResearchLoading({ isResearchLoading: true }));
  try {
    dispatch(actions.researchDetail({ researchDetail: undefined }));
    dispatch(actions.researchManualDetail({ researchManualDetail: undefined }));
  } catch (error) {
    console.log(
      "ERRORE: Non è stato possibile resettare il dettaglio ricerca."
    );
  } finally {
    dispatch(actions.isResearchLoading({ isResearchLoading: false }));
  }
};
