import { LegalPracticeRequest, Practice } from "core/types/practice.types";
import { actionCreatorFactory } from "typescript-fsa";

const actionCreator = actionCreatorFactory("practice");

export const resetPractice = actionCreator<void>("RESET");

export const isPracticeLoading = actionCreator<{ isPracticeLoading: boolean }>(
  "IS_PRACTICE_LOADING"
);

export const practicesList = actionCreator<{ practiceList: Practice[] }>(
  "PRACTICES_LIST"
);

export const practiceDetail = actionCreator<{ practiceDetail: Practice }>(
  "PRACTICE_DETAIL"
);

export const practiceRequest = actionCreator<{
  practiceRequest: LegalPracticeRequest[];
}>("PRACTICE_REQUEST");

export const success = actionCreator<{ success: string }>("PRACTICE_SUCCESS");

export const error = actionCreator<{ error: string }>("PRACTICE_ERROR");