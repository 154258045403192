import { actionCreatorFactory } from "typescript-fsa";
import { Domain } from "core/types/domain.types";

const actionCreator = actionCreatorFactory("search");

export const resetDomain = actionCreator<void>("RESET");

export const isDomainLoading = actionCreator<{
  isDomainLoading: boolean;
}>("IS_DOMAIN_LOADING");

export const domainList = actionCreator<{
  domainList: Domain[];
}>("DOMAIN_LIST");

export const success = actionCreator<{success: string}>("DOMAIN_SUCCESS")

export const error = actionCreator<{error: string}>("DOMAIN_ERROR")