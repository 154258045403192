import { Button, CardContent, Checkbox, Grid } from "@mui/material";
import { Col } from "components/atoms/col";
import { Row } from "components/atoms/row";
import { Text } from "components/atoms/text";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

import "./index.css";
import { KeyValueComponent } from "components/molecules/key-value-component";

interface Props {
  user: User;
}

const ProfileCardContent: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();

  // console.log(user);

  return (
    <>
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.name")} value={user.firstName} />
        </Grid>
        <Grid item xs={12} sm={6} columnSpacing={4}>
          <KeyValueComponent
            label={t("profile.surname")}
            value={user.lastName}
          />
        </Grid>
      </Grid>

      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.email")} value={user?.email} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.address")} value={"-"} />
        </Grid>
      </Grid>

      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.company")} value={"-"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.city")} value={"-"} />
        </Grid>
      </Grid>

      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.p_iva")} value={"-"} />
        </Grid>
        <Grid item xs={12} sm={6}>
          <KeyValueComponent label={t("profile.phone")} value={"-"} />
        </Grid>
      </Grid>

      <Grid container columnSpacing={4}>
        <Grid item xs={12} display={"flex"} justifyContent={"flex-end"}>
          <Button
            variant="contained"
            sx={{ marginTop: "20px" }}
            onClick={() =>
              window.open(
                "https://auth.codertd.com/auth/realms/demo/account/#/personal-info",
                "_blank",
                "noreferrer"
              )
            }
          >
            {t("profile.edit_button")}
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default ProfileCardContent;
