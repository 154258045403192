import { Button, Grid } from "@mui/material";
import { FunctionComponent } from "react";
import { Avatar as MuiAvatar } from "@mui/material";
import { useTranslation } from "react-i18next";
import CloudUploadRoundedIcon from "@mui/icons-material/CloudUploadRounded";
import { Text } from "../text";

interface Props {}

const Avatar: FunctionComponent<Props> = ({ ...otherProps }) => {
  const { t } = useTranslation();
  return (
    <Grid
      display={"flex"}
      alignItems={"center"}
      flexDirection={"column"}
      sx={{
        background: "#E8EAEE",
        width: "100%",
        paddingTop: "24px",
        paddingBottom: "24px",
        borderRadius: "6px",
        marginBottom: "16px",
      }}
    >
      <MuiAvatar
        {...otherProps}
        style={{ width: "82px", height: "82px", fontSize: "30px" }}
      />
      {false && (
        <>
          <Button
            variant="outlined"
            sx={{ marginTop: "16px", marginBottom: "16px", fontWeight: "600" }}
            startIcon={<CloudUploadRoundedIcon color="primary" />}
          >
            {t("profile.upload")}
          </Button>
          <Text textAlign={"center"} fontWeight={"400"} fontSize={"12px"}>
            {t("profile.upload_subtitle")}
          </Text>
        </>
      )}
    </Grid>
  );
};

export default Avatar;
