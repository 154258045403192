import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { Text } from "components/atoms/text";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Practice, PracticeUrl } from "core/types/practice.types";
import { practiceAsync, practiceSelectors } from "core/store/modules/practice";
import { Alert, Button, Divider, Grid, Paper, Snackbar } from "@mui/material";
import i18n from "i18n";
import { parse } from "date-fns";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import { UrlTable } from "containers/url-table";
import { reportAsync } from "core/store/modules/report";
import { User } from "core/types/user.types";
import { authAsync, authSelectors } from "core/store/modules/auth";
import { urlAsync } from "core/store/modules/url";
import { ListChart } from "components/molecules/list-chart";

interface OwnProps {}
interface StateProps {
  practiceDetail?: Practice;
  isPracticeLoading: boolean;
  user?: User;
}
interface DispatchProps {
  getPracticeDetail: (practiceId: number) => void;
  getUserMe: () => void;
  download: (id: number) => void;
  sendRequest: (requestType: number, urls: number[]) => void;
  changeState: (urlId: number, status: string) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const ReportDetail: FunctionComponent<Props> = ({
  practiceDetail,
  isPracticeLoading,
  user,
  getPracticeDetail,
  download,
  getUserMe,
  sendRequest,
  changeState,
}) => {
  const { id } = useParams();
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const [urlForGraph, setUrlForGraph] = useState<Record<string, number>>({});
  const [openRequestModal, setOpenRequestModal] = useState(false);
  const [urls, setUrls] = useState<number[]>([]);
  const [status, setStatus] = useState("");
  const [selectedUrl, setSelectedUrl] = useState<number>();
  const [practices, setPractices] = useState<Practice>();
  const [open, setOpen] = useState(false);
  const [urlList, setUrlList] = useState<PracticeUrl[]>([]);

  useEffect(() => {
    getUserMe();
  }, []);

  useEffect(() => {
    if (!selectedUrl) {
      getPracticeDetail(Number(id));
    }
  }, [id, selectedUrl]);

  useEffect(() => {
    if (practiceDetail) {
      setPractices({ ...practiceDetail });
      setUrlForGraph(countUrlStatus(practiceDetail.urls));
    }
  }, [practiceDetail]);

  function countUrlStatus(urls: PracticeUrl[]): Record<string, number> {
    let onlineCount = 0;
    let pendingCount = 0;
    let removedCount = 0;

    for (const urlObj of urls) {
      const status = urlObj.status;
      if (status === "online") {
        onlineCount += 1;
      } else if (status === "pending") {
        pendingCount += 1;
      } else if (status === "removed") {
        removedCount += 1;
      }
    }

    const result: Record<string, number> = {
      online: onlineCount,
      pending: pendingCount,
      removed: removedCount,
    };

    return result;
  }

  const sentRequest = () => {
    urls.length > 0 && sendRequest(1, urls);
    setOpenRequestModal(false);
  };

  useEffect(() => {
    if (status && selectedUrl) {
      changeState(selectedUrl, status);
      setSelectedUrl(undefined);
      setStatus("");
    }
  }, [selectedUrl, status]);

  const handleClose = (_?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <>
      {isPracticeLoading && <LoadingOverlay open={isPracticeLoading} />}
      <Text fontSize={"24px"} fontWeight={"700"} textAlign={"center"}>
        {t("practices.detail.title") + " " + id}
      </Text>
      <Grid container spacing={2}>
        <Grid item container xs={12} spacing={2}>
          <Grid item xs={5}>
            <Paper
              sx={{
                borderRadius: "10px",
                padding: "20px",
                height: "100%",
              }}
            >
              <Text fontSize={"16px"} fontWeight={"600"} pb={"15px"}>
                {t("practices.detail.user.title")}
              </Text>
              <Grid container columnSpacing={4}>
                <Grid item>{t("practices.detail.user.name")}</Grid>
                <Grid item>{practices?.user.firstName}</Grid>
              </Grid>
              <Divider />
              <Grid container mt={"18px"} columnSpacing={4}>
                <Grid item>{t("practices.detail.user.surname")}</Grid>
                <Grid item>{practices?.user.lastName}</Grid>
              </Grid>
              <Divider />
              <Grid container mt={"18px"} columnSpacing={4}>
                <Grid item>{t("practices.detail.user.mail")}</Grid>
                <Grid item>{practices?.user.email}</Grid>
              </Grid>
              <Divider />
            </Paper>
          </Grid>
          <Grid item xs={3.5}>
            <Paper
              sx={{
                borderRadius: "10px",
                padding: "20px",
                height: "100%",
              }}
            >
              <Text fontSize={"16px"} fontWeight={"600"} pb={"15px"}>
                {t("practices.detail.report.title")}
              </Text>
              <Text
                fontSize={"16px"}
                fontWeight={"600"}
                pb={"15px"}
                textAlign={"center"}
              >
                {t("practices.detail.report.n") + practices?.report.id}
              </Text>
              <Text textAlign={"center"}>
                {t("practices.detail.report.of")}
              </Text>
              <Text textAlign={"center"}>
                {practices &&
                  parse(
                    practices?.report.createdAt,
                    "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
                    new Date()
                  ).toLocaleDateString(
                    linguaCorrente === "en" ? "en-GB" : "it-IT",
                    { year: "numeric", month: "long", day: "numeric" }
                  )}
              </Text>
              <Button
                variant="outlined"
                sx={{
                  width: "100%",
                  marginTop: "10px",
                }}
                endIcon={<DownloadRoundedIcon color="primary" />}
                onClick={() => practices && download(practices?.report.id)}
              >
                {t("practices.detail.report.download")}
              </Button>
            </Paper>
          </Grid>
          <Grid item xs={3.5}>
            <Paper
              sx={{
                borderRadius: "10px",
                padding: "20px",
                height: "100%",
              }}
            >
              <Text fontSize={"16px"} fontWeight={"600"} pb={"15px"}>
                {t("practices.detail.status.title")}
              </Text>
              <ListChart list={urlForGraph} />
            </Paper>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item xs={12}>
            <Paper
              sx={{
                borderRadius: "10px",
                padding: "20px",
              }}
            >
              <Text fontSize={"16px"} fontWeight={"600"} pb={"15px"}>
                {t("practices.detail.urls.title")}
              </Text>
              <UrlTable
                isLoading={isPracticeLoading}
                list={practices?.urls}
                createdAt={practices?.createdAt}
                updatedAt={practices?.updatedAt}
                user={user}
                urlList={urlList}
                setUrlList={setUrlList}
                openRequestModal={openRequestModal}
                urls={urls}
                setUrls={setUrls}
                setOpenRequestModal={setOpenRequestModal}
                sendRequest={sentRequest}
                status={status}
                setStatus={setStatus}
                setSelectedUrl={setSelectedUrl}
              />
            </Paper>
          </Grid>
        </Grid>
      </Grid>
      <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%", borderRadius: "10px" }}
        >
          {t("social-dashboard.form.error")}
        </Alert>
      </Snackbar>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    practiceDetail: practiceSelectors.getPracticeDetail(state),
    isPracticeLoading: practiceSelectors.isPracticeLoading(state),
    user: authSelectors.getUser(state),
  }),
  (dispatch: Dispatch) => ({
    getPracticeDetail: (practiceId: number) =>
      dispatch(practiceAsync.getPracticeDetail(practiceId)),
    download: (id: number) => dispatch(reportAsync.downloadReport(id)),
    getUserMe: () => dispatch(authAsync.getUser()),
    sendRequest: (requestType: number, urls: number[]) =>
      dispatch(practiceAsync.sendLegalPracticeRequest(requestType, urls)),
    changeState: (urlId: number, status: string) =>
      dispatch(urlAsync.changeState(urlId, status)),
  })
)(ReportDetail);
