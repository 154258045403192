import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Source } from "core/types/research.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";

interface Props {
  list: Source[];
  isLoading: boolean;
}

const SourcesTable: FunctionComponent<Props> = ({ list, isLoading }) => {
  const { t } = useTranslation();

  return (
    <>
      {isLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("dashboard.source")}</TableCell>
                <TableCell>{t("dashboard.value")}</TableCell>
                <TableCell>{t("dashboard.impact")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {list?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.source}</TableCell>
                  <TableCell>{item.value}</TableCell>
                  <TableCell>{item.impact}%</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
};

export default SourcesTable;
