import DownloadRoundedIcon from "@mui/icons-material/DownloadRounded";
import {
  CircularProgress,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Text } from "components/atoms/text";
import { Report } from "core/types/report.types";
import { parse } from "date-fns";
import i18n from "i18next";
import { Dispatch, FunctionComponent, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import InfoRoundedIcon from "@mui/icons-material/InfoRounded";

interface Props {
  reportList?: Report[];
  isReportLoading: boolean;
  isEditableMode?: boolean;
  practiceToGenerate?: Report;
  setPracticeToGenerate?: Dispatch<SetStateAction<Report | undefined>>;
  onDownload?: (id: number) => void;
  onDetailClick?: (id: number) => void;
}

const ModalReportListTable: FunctionComponent<Props> = ({
  reportList,
  isReportLoading,
  practiceToGenerate,
  setPracticeToGenerate,
  isEditableMode = true,
  onDownload,
  onDetailClick,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;

  return (
    <div style={{ overflowX: "auto" }}>
      {isReportLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("my_reports.table.made_on")}</TableCell>
                <TableCell>{t("my_reports.table.report")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {reportList?.map((report) => (
                <TableRow key={report.id}>
                  <TableCell>
                    {isEditableMode && (
                      <Checkbox
                        checked={report.id === practiceToGenerate?.id}
                        onChange={(_) => {
                          if (setPracticeToGenerate) {
                            setPracticeToGenerate(report);
                          }
                        }}
                      />
                    )}
                  </TableCell>
                  <TableCell>
                    {new Date(report.timestamp).toLocaleDateString(
                      linguaCorrente === "en" ? "en-GB" : "it-IT",
                      { year: "numeric", month: "long", day: "numeric" }
                    )}
                  </TableCell>
                  <TableCell>{report.id}</TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-around",
                        alignItems: "center",
                      }}
                    >
                      {onDownload && report.link && (
                        <Tooltip
                          title={
                            <Text style={{ color: "#fff" }}>
                              {t("my_reports.table.download")}
                            </Text>
                          }
                        >
                          <IconButton
                            onClick={() => {
                              onDownload(report.id);
                            }}
                          >
                            <DownloadRoundedIcon color="info" />
                          </IconButton>
                        </Tooltip>
                      )}
                      {onDetailClick && (
                        <Tooltip
                          title={
                            <Text style={{ color: "#fff" }}>
                              {t("my_reports.table.see-detail")}
                            </Text>
                          }
                        >
                          <IconButton
                            onClick={() => {
                              onDetailClick(report.id);
                            }}
                          >
                            <InfoRoundedIcon color="info" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default ModalReportListTable;
