import { RootState } from "../../store.types";
import { State } from "./report.types";

export const getCurrentState = (state: RootState): State => state.report;

export const getReport = (state: RootState) => getCurrentState(state).report;

export const getReportSuccess = (state: RootState) =>
  getCurrentState(state).success;

  export const getReportError = (state: RootState) =>
  getCurrentState(state).error;

export const isReportLoading = (state: RootState) =>
  getCurrentState(state).isReportLoading;

export const isDownloadReportLoading = (state: RootState) =>
  getCurrentState(state).isDownloadReportLoading;

export const getReportList = (state: RootState) =>
  getCurrentState(state).reportList;

export const getDownloadList = (state: RootState) =>
  getCurrentState(state).downloadList;
