import { Text } from "components/atoms/text";
import { PlanCard } from "components/molecules/plan-card";
import { FunctionComponent } from "react";
import { PricingPlanType } from "containers/my-profile/pricing-plan/pricing-plan";
import { Grid } from "@mui/material";

import "./pricing-plan-content.css";

interface Props {
  pricingPlans: PricingPlanType[];
  startTransaction: (id: string) => void;
}

const PricingPlanContent: FunctionComponent<Props> = ({
  pricingPlans,
  startTransaction,
}) => {
  return (
    <Grid
      item
      container
      spacing={5}
      paddingBottom={"50px"}
      justifyContent={"center"}
    >
      {pricingPlans.map((pp, idx) => (
        <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
          <PlanCard
            title={
              <Text
                variant={idx === pricingPlans.length - 1 ? "h3" : "h4"}
                fontWeight={"800"}
              >
                {pp.title}
              </Text>
            }
            price={pp.price}
            description={pp.subtitle}
            list={pp.points}
            currency={pp.currency ? pp.currency : ""}
            isLast={idx === pricingPlans.length - 1}
            daysDurate={pp.daysDurate}
            onButtonPress={() => {
              if (idx === pricingPlans.length - 1) {
                window.open(
                  "mailto:l.dedamiani@codertd.com,p.costanzo@codertd.com?subject=Richiesta%20informazioni%20piano%20%20Enterprise&body=",
                  "_self"
                );
              } else {
                startTransaction(pp.id);
              }
            }}
          />
        </Grid>
      ))}
    </Grid>
  );
};

export default PricingPlanContent;
