import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./product.types";
import * as actions from "./product.actions";

export const initialState: Readonly<State> = {
  productList: [],
  planList: [],
  isProductLoading: false,
  exchangeRate: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetProduct, () => ({ ...initialState }))
  .case(actions.productList, (state, { productList }) => ({
    ...state,
    productList,
  }))
  .case(actions.planList, (state, { planList }) => ({
    ...state,
    planList,
  }))
  .case(actions.isProductLoading, (state, { isProductLoading }) => ({
    ...state,
    isProductLoading,
  }))
  .case(actions.exchangeRate, (state, { exchangeRate }) => ({
    ...state,
    exchangeRate,
  }))
  .build();
