import { billing_info, User } from "../../types/user.types";

export const transformbilling_info = (data: any): billing_info => ({
  blockchain_sign: data?.blockchain_sign,
  expert_consultancy_number: data?.expert_consultancy_number,
  legal_support_consultancy_number: data?.legal_support_consultancy_number,
  report_generation_number: data?.report_generation_number,
  report_maintenance_months: data?.report_maintenance_months,
  searches_number: data?.searches_number,
  stripe_customer_id: data?.stripe_customer_id,
});

export const transformUser = (data: any): User => ({
  billing_info: transformbilling_info(data?.billing_info),
  confirmed_user: data?.confirmed_user,
  email: data?.email,
  firstName: data?.firstName,
  id: data?.id,
  lastName: data?.lastName,
  role: data?.role,
  subscriptions: data?.subscriptions,
});
