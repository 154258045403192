import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import DailyMotion from "assets/svg/dailymotion.svg";
import Twitter from "assets/svg/twitter.svg";
import Vimeo from "assets/svg/vimeo.svg";
import Youtube from "assets/svg/youtube.svg";
import { ResearchListAutomatic, ResearchListManual, ResearchPost } from "core/types/research.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import OpenInNewRoundedIcon from "@mui/icons-material/OpenInNewRounded";
import YoutubeSearchedForRoundedIcon from "@mui/icons-material/YoutubeSearchedForRounded";
import i18n from "i18next";
import "dayjs/locale/it";
import "dayjs/locale/en";
import { useNavigate } from "react-router-dom";

interface Props {
  researchList?: ResearchListManual[];
  isResearchLoading: boolean;
  onNewResearch?: (r: ResearchPost) => void;
}

const ResearchListTable: FunctionComponent<Props> = ({
  researchList,
  isResearchLoading,
  onNewResearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linguaCorrente = i18n.language;
  dayjs.locale(linguaCorrente);

  const setBanner = (tag?: string): string => {
    switch (tag) {
      case "youtube":
        return Youtube;
      case "vimeo":
        return Vimeo;
      case "twitter":
        return Twitter;
      case "dailymotion":
        return DailyMotion;
    }
    return "#1f2945";
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {isResearchLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("my_research.table.made_on")}</TableCell>
                <TableCell>{t("my_research.table.keyword")}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {t("my_research.table.minimum_length")}
                </TableCell>
                <TableCell>{t("my_research.table.sources")}</TableCell>
                <TableCell style={{ whiteSpace: "nowrap" }}>
                  {t("my_research.table.min_impact")}
                </TableCell>
                <TableCell>%</TableCell>
                <TableCell></TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {researchList?.map((research) => (
                <TableRow key={research.id}>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {dayjs(research.creationDate).format("DD MMMM YYYY")}
                    {/* <Tooltip
                        title={
                          t("my_research.at_hour") +
                          dayjs(research.creationDate).format("HH:mm:ss")
                        }
                        sx={{
                          marginLeft: "5px",
                        }}
                      >
                        <AccessTimeRoundedIcon color="info" />
                      </Tooltip> */}
                  </TableCell>
                  <TableCell>
                    <Grid container>
                      {research.payload.params
                        ?.sort((a, b) => b.weight - a.weight)
                        .map((p, index) => (
                          <Tooltip
                            key={index}
                            title={t("my_research.impact") + p.weight + "/100"}
                          >
                            <Grid item sx={{ marginRight: "5px" }}>
                              {p.name}
                              {research.payload.params?.length &&
                              research.payload.params?.length - 1 !== index
                                ? ", "
                                : ""}
                            </Grid>
                          </Tooltip>
                        ))}
                    </Grid>
                  </TableCell>
                  <TableCell>
                    {research.payload.minDuration +
                      " " +
                      t("results.seconds").charAt(0).toUpperCase() +
                      t("results.seconds").slice(1)}
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    <Grid
                      container
                      display={"flex"}
                      alignItems={"center"}
                      width={"max-content"}
                    >
                      {research.payload.originSources?.map((o, index) => (
                        <Grid key={index} item sx={{ marginRight: "5px" }}>
                          <img src={setBanner(o.toLowerCase())} />
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  {/* <TableCell>
                    <Checkbox
                      checked={research.payload.type}
                      disabled
                      sx={{
                        "&.Mui-disabled": {
                          color: "#fff",
                        },
                      }}
                    />
                  </TableCell> */}
                  <TableCell>
                    {research.payload.impact
                      ? research.payload.impact + "%"
                      : "50%"}
                  </TableCell>
                  <TableCell sx={{ minWidth: "160px", width: "160px" }}>
                    <LinearProgress
                      value={
                        research.payload.impact ? research.payload.impact : 50
                      }
                      variant="determinate"
                    />
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {research.reportId ? (
                        <Tooltip title={t("my_research.report")}>
                          <IconButton
                            onClick={() =>
                              navigate("/my-reports/" + research.reportId)
                            }
                          >
                            <OpenInNewRoundedIcon color="info" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        <></>
                      )}
                    </div>
                  </TableCell>
                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Tooltip title={t("my_research.search")}>
                        <IconButton
                          onClick={() =>
                            onNewResearch && onNewResearch(research.payload)
                          }
                        >
                          <YoutubeSearchedForRoundedIcon color="info" />
                        </IconButton>
                      </Tooltip>
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          {/* <div
            style={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#1F2945",
              width: "100%",
              height: "70px",
            }}
          >
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                float: "right",
                paddingRight: "20px",
              }}
            >
              <Text fontSize={20} fontWeight={"500"}>
                {t("my_research.total") + researchList?.length}
              </Text>
            </div>
          </div> */}
        </TableContainer>
      )}
    </div>
  );
};

export default ResearchListTable;
