import { RootState } from "../../store.types";
import { State } from "./domain.types";

export const getCurrentState = (state: RootState): State => state.domain;

export const isDomainLoading = (state: RootState) =>
  getCurrentState(state).isDomainLoading;

export const getDomainList = (state: RootState) =>
  getCurrentState(state).domainList;

export const getDomainSuccess = (state: RootState) =>
  getCurrentState(state).success;

export const getDomainError = (state: RootState) =>
  getCurrentState(state).error;
