import { urlApi } from "core/api";
import { Dispatch } from "../../store.types";
import * as actions from "./url.actions";

export const getAllPersonalUrls = () => async (dispatch: Dispatch) => {
  dispatch(actions.isUrlLoading({ isUrlLoading: true }));
  try {
    const urlList = await urlApi.getUrls();
    dispatch(actions.urlList({ urlList: urlList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isUrlLoading({ isUrlLoading: false }));
  }
};

export const getAllUrls = () => async (dispatch: Dispatch) => {
  dispatch(actions.isUrlLoading({ isUrlLoading: true }));
  try {
    const urlList = await urlApi.getAdminUrls();
    dispatch(actions.urlList({ urlList: urlList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isUrlLoading({ isUrlLoading: false }));
  }
};

export const changeState =
  (urlId: number, status: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isUrlLoading({ isUrlLoading: true }));
    try {
      if (status !== "pending" && status !== "online" && status !== "removed") {
        dispatch(actions.error({ error: "Errore generico" }));
      } else {
        const putResponse = await urlApi.putAdminUrls(urlId, status);
        dispatch(actions.success({ success: putResponse.data.message }));
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isUrlLoading({ isUrlLoading: false }));
    }
  };

export const getUrlDetail = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actions.isUrlLoading({ isUrlLoading: true }));
  try {
    const urlDetail = await urlApi.getDetail(id);
    dispatch(actions.urlDetail({ urlDetail: urlDetail }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isUrlLoading({ isUrlLoading: false }));
  }
};
