import { FunctionComponent, useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "@emotion/styled";

import {
  Avatar as MuiAvatar,
  Badge,
  Box,
  IconButton,
  ListItem,
  ListItemText,
  Popover as MuiPopover,
  Tooltip,
  List,
  Button,
} from "@mui/material";
import { Bell } from "react-feather";
import { useTranslation } from "react-i18next";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { DownloadItem } from "core/types/report.types";

const Popover = styled(MuiPopover)`
  .MuiPaper-root {
    width: 300px;
    ${(props) => props.theme.shadows[1]};
    border: 1px solid ${(props) => props.theme.palette.divider};
  }
`;

const Indicator = styled(Badge)`
  .MuiBadge-badge {
    background: ${(props) => props.theme.header.indicator.background};
    color: ${(props) => props.theme.palette.common.white};
  }
`;

function Notification({
  title,
  onCloseNotification,
  onClickItem,
}: {
  title: string;
  onCloseNotification: () => void;
  onClickItem: () => void;
}) {
  return (
    <Button
      sx={{ padding: 0 }}
      onClick={() => {
        onClickItem();
        onCloseNotification();
      }}
    >
      <ListItem divider component={Link} to="#">
        <ListItemText
          primary={title}
          primaryTypographyProps={{
            variant: "subtitle2",
            color: "textPrimary",
          }}
        />
        <IconButton
          sx={{ padding: 0, width: "20px", height: "20px" }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onCloseNotification();
          }}
        >
          <CloseRoundedIcon sx={{ width: "15px" }} />
        </IconButton>
      </ListItem>
    </Button>
  );
}

interface Props {
  downloads?: DownloadItem[];
  onRemoveDownloadNotification: (reportID: number) => void;
}

const NavbarNotificationsDropdown: FunctionComponent<Props> = ({
  downloads,
  onRemoveDownloadNotification,
}) => {
  const { t } = useTranslation();
  const ref = useRef(null);
  const [isOpen, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t("header.notifications")}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen} size="large">
          <Indicator badgeContent={downloads?.length}>
            <Bell color="#1D2C54" />
          </Indicator>
        </IconButton>
      </Tooltip>
      {downloads && downloads.length > 0 && (
        <Popover
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          anchorEl={ref.current}
          onClose={handleClose}
          open={isOpen}
        >
          <List disablePadding>
            {downloads?.map((item) => (
              <Notification
                key={item.reportID}
                title={
                  t("notify.download_1") +
                  item.reportID +
                  t("notify.download_2")
                }
                onCloseNotification={() =>
                  onRemoveDownloadNotification(item.reportID)
                }
                onClickItem={() => window.open(item.blobUrl, "_blank")}
              />
            ))}
          </List>
        </Popover>
      )}
    </>
  );
};

export default NavbarNotificationsDropdown;
