import { PricingPlanHeader } from "components/molecules/pricing-plan-header";
import { PricingPlanContent } from "components/organisms/pricing-plan-content";
import { Dispatch, RootState } from "core/store/store.types";
import { ExchangeRate, Product } from "core/types/product.types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { productSelectors } from "core/store/modules/product";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import { CheckoutSession } from "core/types/transaction.types";
import {
  transactionAsync,
  transactionSelectors,
} from "core/store/modules/transaction";
import { CircularProgress } from "@mui/material";

export type PricingPlanType = {
  id: string;
  title: string;
  price: number | string;
  annuallyBilled?: number;
  subtitle: string;
  points: string[];
  currency?: string;
  daysDurate: number;
};

interface OwnProps {}

interface StateProps {
  productList?: Product[];
  isProductLoading: boolean;
  isTransactionLoading: boolean;
  exchangeRate?: ExchangeRate;
  checkoutSession?: CheckoutSession;
}

interface DispatchProps {
  createTransaction: (product: string) => void;
  resetCheckoutSession: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const PricingPlan: FunctionComponent<Props> = ({
  productList,
  isProductLoading,
  isTransactionLoading,
  checkoutSession,
  createTransaction,
  resetCheckoutSession,
}) => {
  const { t } = useTranslation();
  const [plan, setPlan] = useState("monthly");
  const [pricingPlansContent, setPricingPlansContent] = useState<
    PricingPlanType[]
  >([]);

  useEffect(() => {
    if (productList && productList?.length > 0) {
      const pricingPlanList: PricingPlanType[] = [];
      productList?.map((product) =>
        pricingPlanList.push({
          id: product.id,
          title: product.title,
          price: product.price,
          subtitle: product.description ?? "",
          points: product.features,
          currency: product.currency,
          daysDurate: product.daysDurate,
        })
      );

      pricingPlanList.sort((a, b) => Number(a.price) - Number(b.price));

      setPricingPlansContent([
        ...pricingPlanList,
        {
          id: "",
          title: "Let's talk",
          price: t("pricing.pricing_enterprise"),
          subtitle: t("pricing.subtitle4"),
          daysDurate: 0,
          points: [
            // t("pricing.points4.1"),
            // t("pricing.points4.2"),
            // t("pricing.points4.3"),
            // t("pricing.points4.4"),
            // t("pricing.points4.5"),
            // t("pricing.points4.6"),
          ],
        },
      ]);
    }
  }, [plan, productList]);

  const startTransaction = (id: string) => {
    createTransaction(id);
  };

  useEffect(() => {
    if (checkoutSession && checkoutSession.url) {
      window.open(checkoutSession.url, "_self");
      resetCheckoutSession();
    }
  }, [checkoutSession]);

  return (
    <>
      {isTransactionLoading && <LoadingOverlay open={isTransactionLoading} />}
      <PricingPlanHeader />
      {isProductLoading && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <CircularProgress color="primary" size={"50px"} />
        </div>
      )}
      <PricingPlanContent
        pricingPlans={pricingPlansContent}
        startTransaction={startTransaction}
      />
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    productList: productSelectors.getProductList(state),
    isProductLoading: productSelectors.isProductLoading(state),
    isTransactionLoading: transactionSelectors.isTransactionLoading(state),
    exchangeRate: productSelectors.getExchangeRate(state),
    checkoutSession: transactionSelectors.getCheckoutSession(state),
  }),
  (dispatch: Dispatch) => ({
    createTransaction: (product: string) =>
      dispatch(transactionAsync.createTransaction(product)),
    resetCheckoutSession: () =>
      dispatch(transactionAsync.resetCheckoutSession()),
  })
)(PricingPlan);
