import { filmApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./film.actions";

export const getFilmList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isFilmLoading({ isFilmLoading: true }));
  try {
    const filmList = await filmApi.getFilm();
    dispatch(actions.filmList({ filmList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isFilmLoading({ isFilmLoading: false }));
  }
};

export const createNewFilm = (title: string) => async (dispatch: Dispatch) => {
  dispatch(actions.isFilmLoading({ isFilmLoading: true }));
  try {
    const resp = await filmApi.postFilm(title);
    dispatch(actions.success({ success: "Movie added correctly" }));
  } catch (error) {
    console.error(error);
    dispatch(actions.error({ error: "Error in movie creation" }));
  } finally {
    dispatch(actions.isFilmLoading({ isFilmLoading: false }));
  }
};

export const editFilm =
  (id: string, newTitle: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isFilmLoading({ isFilmLoading: true }));
    try {
      const resp = await filmApi.putFilm(id, newTitle);
      dispatch(actions.success({ success: "Movie edited correctly" }));
    } catch (error) {
      console.error(error);
      dispatch(actions.error({ error: "Error in movie update" }));
    } finally {
      dispatch(actions.isFilmLoading({ isFilmLoading: false }));
    }
  };

export const removeFilm = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actions.isFilmLoading({ isFilmLoading: true }));
  try {
    const resp = await filmApi.deleteFilm(id);
    dispatch(actions.success({ success: "Movie deleted correctly" }));
  } catch (error) {
    console.error(error);
    dispatch(actions.error({ error: "Error in movie delete" }));
  } finally {
    dispatch(actions.isFilmLoading({ isFilmLoading: false }));
  }
};
