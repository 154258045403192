import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ReactKeycloakProvider } from "@react-keycloak/web";
import keycloak from "./keycloak";
import { Provider } from "react-redux";
import store from "./core/store";
import { ThemeProvider } from "./contexts/ThemeContext";
import { BrowserRouter } from "react-router-dom";

import "material-icons/iconfont/material-icons.css";
import "./i18n";
import { LoadingOverlay } from "components/atoms/loading-overlay";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ReactKeycloakProvider
    authClient={keycloak}
    initOptions={{
      // redirectUri: KEYCLOAK_REDIRECT_URI,
      onLoad: "check-sso",
      checkLoginIframe: true,
      enableLogging: true,
    }}
    LoadingComponent={<LoadingOverlay open />}
  >
    <React.StrictMode>
      <BrowserRouter>
        <ThemeProvider>
          <Provider store={store}>
            <App />
          </Provider>
        </ThemeProvider>
      </BrowserRouter>
    </React.StrictMode>
  </ReactKeycloakProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
