import { Practice } from "core/types/practice.types";
import { ApiFactoryClients } from "../api.types";
import { Url } from "core/types/url.types";
import { transformUrl } from "./url.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getUrls: (): Promise<Url[]> =>
    codeHttpClient
      .get("/urls")
      .then(({ data }) => data.map((d: any) => transformUrl(d))),
  getAdminUrls: (): Promise<Url[]> =>
    codeHttpClient
      .get("/admin/urls")
      .then(({ data }) => data.map((d: any) => transformUrl(d))),
  putAdminUrls: (urlId: number, status: string) =>
    codeHttpClient.put("/admin/urls", { url_id: urlId, status: status }),
  getDetail: (urlId: string) =>
    codeHttpClient
      .get("/urls?url_id=" + urlId)
      .then(({ data }) => transformUrl(data)),
});
