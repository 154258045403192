import { Film } from "core/types/film.types";
import { ApiFactoryClients } from "../api.types";
import { transformFilmList } from "./film.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getFilm: (): Promise<Film[]> =>
    codeHttpClient.get("/films").then(({ data }) => transformFilmList(data)),
  postFilm: (title: string): Promise<any> =>
    codeHttpClient.post("/films", { title: title }).then((resp) => resp),
  deleteFilm: (id: string) =>
    codeHttpClient.delete("/films?film_id=" + id).then((resp) => resp),
  putFilm: (id: string, newTitle: string) =>
    codeHttpClient
      .put("/films", { film_id: id, new_title: newTitle })
      .then((resp) => resp),
});
