import { notifyApi } from "core/api";
import { Dispatch } from "../../store.types";
import * as actions from "./notify.actions";

export const getNotifyList = () => async (dispatch: Dispatch) => {
  try {
    const notifyList = await notifyApi.getNotification();
    console.log("API CALL");
    console.log(notifyList);
    // dispatch(actions.notifyList({ notifyList }));
  } catch (error) {
    console.error(error);
  }
};
