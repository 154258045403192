import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import { PracticeUrl } from "core/types/practice.types";
import i18n from "i18n";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/text";

interface Props {
  list?: PracticeUrl[];
  urls: number[];
  setUrls: (n: number[]) => void;
}

const UrlTable: FunctionComponent<Props> = ({ list, urls, setUrls }) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;

  const handleAddToList = (urlId: number, checked: boolean) => {
    if (checked) {
      setUrls([...urls, urlId]);
    } else {
      const newReportToGenerate = urls.filter((url) => url !== urlId);
      setUrls([...newReportToGenerate]);
    }
  };

  return (
    <>
      <TableContainer
        component={Paper}
        style={{
          position: "relative",
          borderRadius: "8px",
          maxHeight: "300px",
        }}
      >
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell>{t("practices.detail.modal.table.url")}</TableCell>
              <TableCell>{t("practices.detail.modal.table.status")}</TableCell>
              <TableCell>
                {t("practices.detail.modal.table.last_request")}
              </TableCell>
              <TableCell>
                {t("practices.detail.modal.table.type_last_request")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                padding: "10px",
                fontSize: "14px",
              },
            }}
          >
            {list?.map((item, index) => (
              <TableRow key={index}>
                <TableCell>
                  <Checkbox
                    checked={urls.some((u) => u === item.id)}
                    onChange={(event) =>
                      handleAddToList(item.id, event.target.checked)
                    }
                  />
                </TableCell>
                <TableCell
                  sx={{
                    maxWidth: "200px",
                  }}
                >
                  <Tooltip
                    title={<Text style={{ color: "#fff" }}>{item.url}</Text>}
                  >
                    <Text
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "-webkit-box",
                        WebkitLineClamp: 1,
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.url}
                    </Text>
                  </Tooltip>
                </TableCell>
                <TableCell>
                  {t("practices.detail.modal." + item.status)}
                </TableCell>
                <TableCell>
                  {new Date(item.lastRequest.createdAt).toLocaleDateString(
                    linguaCorrente === "en" ? "en-GB" : "it-IT",
                    { year: "numeric", month: "long", day: "numeric" }
                  )}
                </TableCell>
                <TableCell>{item.lastRequest.type}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default UrlTable;
