import { RootState } from "../../store.types";
import { State } from "./research.types";

export const getCurrentState = (state: RootState): State => state.research;

export const getResearch = (state: RootState) =>
  getCurrentState(state).research;

export const isResearchLoading = (state: RootState) =>
  getCurrentState(state).isResearchLoading;

export const getResearchList = (state: RootState) =>
  getCurrentState(state).researchList;

export const getResearchDetail = (state: RootState) =>
  getCurrentState(state).researchDetail;

export const getResearchManualDetail = (state: RootState) =>
  getCurrentState(state).researchManualDetail;
