import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./auth.types";
import * as actions from "./auth.actions";

export const initialState: Readonly<State> = {
  user: undefined,
  isAuthLoading: false,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetAuth, () => ({ ...initialState }))
  .case(actions.authSuccess, (state, { user }) => ({
    ...state,
    user,
  }))
  .case(actions.isAuthLoading, (state, { isAuthLoading }) => ({
    ...state,
    isAuthLoading,
  }))
  .case(actions.logout, () => ({ ...initialState }))
  .build();
