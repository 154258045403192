import { Research, Result } from "core/types/research.types";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import {
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Paper,
  Tooltip,
  IconButton,
  Checkbox,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Box,
  TableHead,
  Button,
} from "@mui/material";
import { Text } from "components/atoms/text";
import dayjs from "dayjs";
import InsertLinkRoundedIcon from "@mui/icons-material/InsertLinkRounded";

interface Props {
  research: Research;
  selectedVideos: string[];
  setSelectedVideos?: (videos: string[]) => void;
  onGenerateReportButton: () => void;
  style?: React.CSSProperties;
  detailMode?: boolean;
}

const ResearchTable: FunctionComponent<Props> = ({
  research,
  selectedVideos,
  setSelectedVideos,
  onGenerateReportButton,
  style,
  detailMode = false,
}) => {
  const { t } = useTranslation();
  const [sortBy, setSortBy] = useState("views");

  const sortResults = (a: Result, b: Result) => {
    switch (sortBy) {
      case "views":
        return b.views - a.views;
      case "affinity":
        return b.affinity - a.affinity;
      case "duration":
        return b.duration - a.duration;
      case "date":
        return new Date(b.date).getTime() - new Date(a.date).getTime();
      default:
        return 0;
    }
  };

  const handleSortChange = (event: any) => {
    setSortBy(event.target.value);
  };

  function secondsToHHMMSS(seconds: number): string {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    const formattedHours = hours.toString().padStart(2, "0");
    const formattedMinutes = minutes.toString().padStart(2, "0");
    const formattedSeconds = remainingSeconds.toString().padStart(2, "0");

    return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
  }

  const handleCheckboxChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    url: string
  ) => {
    if (setSelectedVideos) {
      if (event.target.checked) {
        setSelectedVideos([...selectedVideos, url]);
      } else {
        const videos = [...selectedVideos];
        setSelectedVideos(videos.filter((v) => v !== url));
      }
    }
  };

  return (
    <>
      {!detailMode && (
        <Grid container display={"flex"} alignItems={"flex-end"}>
          <Grid item xs={6}>
            <Text fontSize={"20px"} fontWeight={"700"}>
              {t("results.title")}
            </Text>
            <Text fontSize={"14px"} fontWeight={"400"}>
              {t("results.subtitle")}
            </Text>
          </Grid>
          <Grid item xs={3}></Grid>
          <Grid item xs={3}>
            <FormControl fullWidth>
              <InputLabel id="select-sort-label">
                {t("research-filter.label")}
              </InputLabel>
              <Select
                id="select-sort-label"
                value={sortBy}
                onChange={handleSortChange}
                size="small"
                label={t("research-filter.label")}
              >
                <MenuItem value="views">{t("research-filter.views")}</MenuItem>
                <MenuItem value="affinity">
                  {t("research-filter.impact")}
                </MenuItem>
                <MenuItem value="duration">
                  {t("research-filter.duration")}
                </MenuItem>
                <MenuItem value="date">{t("research-filter.date")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      )}
      {detailMode && (
        <Grid container display={"flex"} alignItems={"center"} spacing={3}>
          <Grid item xs={6}></Grid>
          <Grid item xs={12} sm={3}>
            <FormControl fullWidth>
              <InputLabel id="select-sort-label">
                {t("research-filter.label")}
              </InputLabel>
              <Select
                id="select-sort-label"
                value={sortBy}
                onChange={handleSortChange}
                size="small"
                label={t("research-filter.label")}
              >
                <MenuItem value="views">{t("research-filter.views")}</MenuItem>
                <MenuItem value="affinity">
                  {t("research-filter.impact")}
                </MenuItem>
                <MenuItem value="duration">
                  {t("research-filter.duration")}
                </MenuItem>
                <MenuItem value="date">{t("research-filter.date")}</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <Button
              fullWidth
              variant="contained"
              color="primary"
              size="small"
              onClick={() => onGenerateReportButton()}
              disabled={
                research.results.length === 0 || selectedVideos.length === 0
              }
            >
              {t("results.generate_report")}
            </Button>
          </Grid>
        </Grid>
      )}
      <TableContainer
        component={Paper}
        style={{
          position: "relative",
          boxShadow: "unset",
          marginTop: "30px",
          ...style,
        }}
      >
        <Table>
          <TableHead>
            <TableCell></TableCell>
            <TableCell></TableCell>
            <TableCell>{t("results.source")}</TableCell>
            <TableCell>{t("results.date")}</TableCell>
            <TableCell>{t("results.duration")}</TableCell>
            <TableCell>{t("results.title_table")}</TableCell>
            <TableCell>
              {t("results.views")[0].toUpperCase() +
                t("results.views").slice(1)}
            </TableCell>
            <TableCell>{t("results.affinity")}</TableCell>
            <TableCell>{t("results.channel")}</TableCell>
            <TableCell></TableCell>
          </TableHead>
          <TableBody
            sx={{
              "& .MuiTableCell-root": {
                padding: "10px",
                fontSize: "14px",
                borderColor: "transparent",
              },
            }}
          >
            {research.results.sort(sortResults).map((result, index) => (
              <TableRow
                key={result.url}
                sx={{
                  marginBottom:
                    index === research.results.length - 1 ? "70px" : "0px",
                }}
              >
                {setSelectedVideos && (
                  <TableCell>
                    <Checkbox
                      checked={selectedVideos.some((url) => url === result.url)}
                      color="info"
                      onChange={(event) =>
                        handleCheckboxChange(event, result.url)
                      }
                    />
                  </TableCell>
                )}

                <TableCell>
                  <div
                    style={{
                      width: "92px",
                      height: "88px",
                    }}
                  >
                    <img
                      src={result.thumbnailUrl}
                      alt={result.title}
                      style={{
                        borderRadius: "5px",
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                      }}
                    />
                  </div>
                </TableCell>

                <TableCell>
                  {result.source.charAt(0).toUpperCase() +
                    result.source.slice(1)}
                </TableCell>

                <TableCell>{dayjs(result.date).format("DD/MM/YYYY")}</TableCell>

                <TableCell>{secondsToHHMMSS(result.duration)}</TableCell>

                <TableCell
                  sx={{
                    width: "250px !important",
                    maxWidth: "250px !important",
                  }}
                >
                  <Text
                    fontSize={"14px"}
                    sx={{
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                    }}
                  >
                    {result.title}
                  </Text>
                </TableCell>

                <TableCell
                  sx={{
                    width: "250px !important",
                    maxWidth: "250px !important",
                  }}
                >
                  <Text fontSize={"14px"}>
                    {result.views + " " + t("results.views")}
                  </Text>
                </TableCell>

                <TableCell>
                  <Text fontSize={"14px"} fontWeight={"700"}>
                    {Math.floor(result.affinity)}%
                  </Text>
                </TableCell>

                <TableCell>{result.channel.name}</TableCell>

                <TableCell>
                  <Tooltip title={t("results.open_link")}>
                    <IconButton href={result.url} target="_blank">
                      <InsertLinkRoundedIcon color="info" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        {!detailMode && (
          <Paper
            style={{
              position: "sticky",
              bottom: 0,
              left: 0,
              right: 0,
              backgroundColor: "#fff",
              width: "100%",
              height: "54px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Text fontSize={"18px"} fontWeight={"700"}>
              {t("results.total") + research.results.length}
            </Text>
          </Paper>
        )}
      </TableContainer>
    </>
  );
};

export default ResearchTable;
