import { Dispatch, RootState } from "core/store/store.types";
import { Plan } from "core/types/product.types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { productSelectors } from "core/store/modules/product";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import { CheckoutSession } from "core/types/transaction.types";
import {
  transactionAsync,
  transactionSelectors,
} from "core/store/modules/transaction";
import { CircularProgress, Grid, Grow } from "@mui/material";
import { Text } from "components/atoms/text";
import { PlanCard } from "components/molecules/plan-card";
import { PlanButtonGroup } from "components/atoms/plan-button-group";

interface OwnProps {}

interface StateProps {
  planList?: Plan[];
  isProductLoading: boolean;
  isTransactionLoading: boolean;
  checkoutSession?: CheckoutSession;
}

interface DispatchProps {
  createTransaction: (product: string) => void;
  resetCheckoutSession: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const SubscriptionPlan: FunctionComponent<Props> = ({
  planList,
  isProductLoading,
  isTransactionLoading,
  checkoutSession,
  createTransaction,
  resetCheckoutSession,
}) => {
  const { t } = useTranslation();
  const [planTab, setPlanTab] = useState<number>();
  const [inFlag, setInFlag] = useState(true);
  const [plansToShow, setPlansToShow] = useState<Plan[]>();

  useEffect(() => {
    if (planList) {
      const sortedData = planList.map((plan) => ({
        ...plan,
        prices: [...plan.prices].sort((a, b) => a.duration - b.duration),
      }));
      setPlansToShow(sortedData);
    }
  }, [planList]);

  const startTransaction = (id: string) => {
    createTransaction(id);
  };

  useEffect(() => {
    if (checkoutSession && checkoutSession.url) {
      window.open(checkoutSession.url, "_self");
      resetCheckoutSession();
    }
  }, [checkoutSession]);

  useEffect(() => {
    if (planList && !inFlag) {
      setTimeout(() => {
        setInFlag(true);
      }, 270);
    }
  }, [inFlag, planList]);

  useEffect(() => {
    setPlanTab(0);
  }, []);

  return (
    <>
      {isTransactionLoading && <LoadingOverlay open={isTransactionLoading} />}
      <Grid item xs={12} p={"50px 0px 20px 0px"}>
        <Text variant="h3" fontWeight={"700"}>
          {t("profile.sub-plan-title")}
        </Text>
        <Text variant="body2" fontWeight={"500"}>
          {t("profile.sub-plan-subtitle")}
        </Text>
      </Grid>
      <Grid item container spacing={5} justifyContent={"center"} mt={"5px"}>
        <PlanButtonGroup
          actualPlan={planTab ?? 0}
          setActualPlan={setPlanTab}
          onClick={() => setInFlag(false)}
        />
      </Grid>
      <Grid
        item
        container
        spacing={5}
        paddingBottom={"50px"}
        justifyContent={"center"}
        mt={"5px"}
      >
        {isProductLoading && (
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <CircularProgress color="primary" size={"50px"} />
          </div>
        )}
        {plansToShow &&
          plansToShow.map((pl, idx) => (
            <Grow in={inFlag} {...(inFlag ? { timeout: (idx + 1) * 500 } : {})}>
              <Grid item xs={12} sm={6} md={4} lg={3} key={idx}>
                <PlanCard
                  title={
                    <Text variant={"h4"} fontWeight={"800"}>
                      {pl.name}
                    </Text>
                  }
                  price={pl.prices[planTab ?? 0].price}
                  description={pl.description ?? ""}
                  list={pl.features.map((f) => f.name)}
                  currency={pl.prices[planTab ?? 0].currency}
                  daysDurate={0}
                  onButtonPress={() => {
                    startTransaction(pl.prices[planTab ?? 0].id);
                  }}
                />
              </Grid>
            </Grow>
          ))}
      </Grid>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    planList: productSelectors.getPlanList(state),
    isProductLoading: productSelectors.isProductLoading(state),
    isTransactionLoading: transactionSelectors.isTransactionLoading(state),
    exchangeRate: productSelectors.getExchangeRate(state),
    checkoutSession: transactionSelectors.getCheckoutSession(state),
  }),
  (dispatch: Dispatch) => ({
    createTransaction: (product: string) =>
      dispatch(transactionAsync.createPlanTransaction(product)),
    resetCheckoutSession: () =>
      dispatch(transactionAsync.resetCheckoutSession()),
  })
)(SubscriptionPlan);
