import { Text } from "../../atoms/text";
import { FunctionComponent, ReactNode } from "react";
import { useTranslation } from "react-i18next";
import { Button, List, ListItem, useTheme } from "@mui/material";

import "./plan-card.css";
import { grey } from "@mui/material/colors";

interface Props {
  title: ReactNode;
  price?: number | string;
  priceForMonth?: number | string;
  description: string;
  list: string[];
  currency: string;
  daysDurate: number;
  isLast?: boolean;
  isSub?: boolean;
  onButtonPress: () => void;
}

const PlanCard: FunctionComponent<Props> = ({
  title,
  price,
  priceForMonth,
  description,
  list,
  currency,
  daysDurate,
  isLast = false,
  isSub = false,
  onButtonPress,
}) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <div className="card">
      <>{title}</>
      <div>
        {!isLast ? (
          <span style={{ display: "flex", alignItems: "baseline" }}>
            <Text
              variant="h4"
              fontWeight={"800"}
              sx={{ color: theme.palette.primary.main }}
            >
              {(isSub ? priceForMonth : price) +
                (currency === "eur" ? "€" : currency === "gbp" ? "£" : "$")}
            </Text>
            {isSub && (
              <Text variant="body1" fontWeight={"600"} ml={"5px"}>
                {t("pricing.price-month")}
              </Text>
            )}
          </span>
        ) : (
          <Text
            variant="h4"
            fontWeight={"800"}
            sx={{ color: theme.palette.primary.main }}
          >
            {price?.toString()}
          </Text>
        )}
        {isSub && !isLast && price && (
          <Text variant="body2" fontWeight={"600"}>
            {t("pricing.price-total") +
              " " +
              price +
              (currency === "eur" ? "€" : currency === "gbp" ? "£" : "$")}
          </Text>
        )}
      </div>
      {description && (
        <div style={{ marginBottom: "10px", marginTop: "10px" }}>
          <Text
            variant="body2"
            fontWeight={"500"}
            sx={{
              color: grey[700],
            }}
          >
            {description}
          </Text>
        </div>
      )}
      <List
        sx={{
          listStyleType: "disc",
          paddingBottom: "30px",
          backgroundColor: "transparent !important",
          boxShadow: "unset",
          color: grey[700],
          pl: 2,
          "& .MuiListItem-root": {
            display: "list-item",
            padding: 0,
          },
        }}
      >
        {list.map((item, index) => (
          <ListItem key={index}>
            <Text
              variant="body2"
              fontWeight={"500"}
              sx={{
                color: grey[700],
              }}
            >
              {item}
            </Text>
          </ListItem>
        ))}
      </List>
      <div style={{ flexGrow: 2 }}></div>
      <Button
        fullWidth
        sx={{
          color: "#000 !important",
          borderRadius: "8px !important",
          borderColor: theme.palette.primary.main,
          borderWidth: "1px",
          borderStyle: "solid",
          backgroundColor: "white",
          transition: "background-color 0.3s ease !important",
          "&:hover": {
            backgroundColor: theme.palette.primary.main,
            color: "#fff !important",
          },
        }}
        onClick={onButtonPress}
      >
        {!isLast ? t("pricing.button") : t("pricing.contact-us-button")}
      </Button>
    </div>
  );
};

export default PlanCard;
