import {
  Autocomplete,
  CircularProgress,
  FormControl,
  Grid,
  Snackbar,
  TextField,
  Alert as MuiAlert,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Dispatch, RootState } from "core/store/store.types";
import { researchAsync, researchSelectors } from "core/store/modules/research";
import { connect } from "react-redux";
import {
  ResearchListAutomatic,
  ResearchListManual,
} from "core/types/research.types";
import { Text } from "components/atoms/text";
import { NewResearch } from "pages/new-research";
import { User } from "core/types/user.types";
import { authSelectors } from "core/store/modules/auth";
import { Alert } from "components/molecules/alert";
import { useNavigate, useParams } from "react-router-dom";
import { format } from "date-fns";
import { enGB, it } from "date-fns/locale";
import i18n from "i18n";
import { ResearchDetail } from "containers/research-detail";
import { ResearchManualDetail } from "containers/research-manual-detail";
import { ReportPost } from "core/types/report.types";
import {
  reportActions,
  reportAsync,
  reportSelectors,
} from "core/store/modules/report";
import { LoadingOverlay } from "components/atoms/loading-overlay";

interface OwnProps {}
interface StateProps {
  user?: User;
  researchList?: ResearchListManual[];
  researchDetail?: ResearchListAutomatic;
  researchManualDetail?: ResearchListManual;
  isResearchLoading: boolean;
  isReportLoading: boolean;
  success?: string;
  error?: string;
}
interface DispatchProps {
  getResearchList: () => void;
  getResearchDetail: (id: string) => void;
  resetDetail: () => void;
  newReportPost: (report: ReportPost) => void;
  resetSuccess: () => void;
  resetError: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const MyResearches: FunctionComponent<Props> = ({
  user,
  researchList,
  researchDetail,
  researchManualDetail,
  isResearchLoading,
  isReportLoading,
  success,
  error,
  getResearchList,
  getResearchDetail,
  resetDetail,
  newReportPost,
  resetSuccess,
  resetError,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const { id } = useParams();
  const [orderedList, setOrderedList] = useState<ResearchListManual[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const navigate = useNavigate();
  const [selectedResearch, setSelectedResearch] = useState<string>("");
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    if (id && id !== "") {
      setSelectedResearch(id);
    }
  }, [id]);

  useEffect(() => {
    if (!openModal) {
      getResearchList();
    }
  }, [openModal]);

  useEffect(() => {
    if (researchList) {
      setOrderedList(
        researchList.sort(
          (a, b) =>
            new Date(b.creationDate).getTime() -
            new Date(a.creationDate).getTime()
        )
      );
    }
  }, [researchList]);

  useEffect(() => {
    if (selectedResearch) {
      resetDetail();
      getResearchDetail(selectedResearch);
    }
  }, [selectedResearch]);

  const onGenerateReportButton = () => {
    newReportPost({
      researchId: Number(selectedResearch),
      urls: selectedVideos,
    });
  };

  useEffect(() => {
    if (success && success !== "") {
      setOpenSnackbar(true);
      setSelectedVideos([]);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  return (
    <>
      {isReportLoading && <LoadingOverlay open={isReportLoading} />}
      {user?.billing_info?.searches_number === 0 && (
        <Alert
          title={t("alert.title")}
          subtitle={t("alert.subtitle")}
          buttonTitle={t("alert.button")}
          buttonAction={() => navigate("/my-profile")}
        />
      )}
      <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
        {t("my_research.title")}
      </Text>

      <Grid container spacing={6}>
        <Grid item xs={12} sm={5}>
          <FormControl variant="standard" fullWidth>
            <Autocomplete
              id="autocomplete-research"
              value={
                orderedList.find(
                  (ol) => ol.id.toString() === selectedResearch
                ) || null
              }
              onChange={(event, newValue) => {
                setSelectedResearch(newValue ? newValue.id.toString() : "");
              }}
              options={orderedList}
              getOptionLabel={(option) =>
                format(new Date(option.creationDate), "dd MMM yyyy HH:mm", {
                  locale: linguaCorrente === "it" ? it : enGB,
                })
              }
              renderOption={(props, option) => (
                <li {...props} key={option.id}>
                  {format(new Date(option.creationDate), "dd MMM yyyy HH:mm", {
                    locale: linguaCorrente === "it" ? it : enGB,
                  })}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t("my_research.title")}
                  InputLabelProps={{
                    sx: { fontSize: "14px !important", fontWeight: "700" },
                  }}
                  variant="standard"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
              loading={isResearchLoading}
              noOptionsText={t("empty_states.loading")}
            />
          </FormControl>
        </Grid>
        {isResearchLoading && (
          <Grid item xs={12} display={"inline-block"} maxWidth={"100%"}>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <CircularProgress color="secondary" size={"100px"} />
            </div>
          </Grid>
        )}
        <Grid item xs={12} display={"inline-block"} maxWidth={"100%"}>
          {researchDetail?.payload?.type === 300 && (
            <ResearchDetail
              selectedResearch={selectedResearch}
              isResearchLoading={isResearchLoading}
              researchDetail={researchDetail}
              onGenerateReportButton={onGenerateReportButton}
              selectedVideos={selectedVideos}
              setSelectedVideos={setSelectedVideos}
            />
          )}
          {researchManualDetail?.id && (
            <ResearchManualDetail
              selectedResearch={selectedResearch}
              isResearchLoading={isResearchLoading}
              researchDetail={researchManualDetail}
              onGenerateReportButton={onGenerateReportButton}
              selectedVideos={selectedVideos}
              setSelectedVideos={setSelectedVideos}
            />
          )}
        </Grid>
      </Grid>
      <NewResearch isVisible={openModal} setIsVisible={setOpenModal} />
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={() => {
          setOpenSnackbar(false);
          resetError();
          resetSuccess();
        }}
      >
        <MuiAlert
          onClose={() => {
            setOpenSnackbar(false);
            resetError();
            resetSuccess();
          }}
          severity={error ? "error" : "success"}
          sx={{ width: "100%", borderRadius: "10px" }}
        >
          {error ? error : success}
        </MuiAlert>
      </Snackbar>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    researchList: researchSelectors.getResearchList(state),
    isResearchLoading: researchSelectors.isResearchLoading(state),
    user: authSelectors.getUser(state),
    researchDetail: researchSelectors.getResearchDetail(state),
    researchManualDetail: researchSelectors.getResearchManualDetail(state),
    isReportLoading: reportSelectors.isReportLoading(state),
    error: reportSelectors.getReportError(state),
    success: reportSelectors.getReportSuccess(state),
  }),
  (dispatch: Dispatch) => ({
    getResearchList: () => dispatch(researchAsync.researchList()),
    getResearchDetail: (id: string) =>
      dispatch(researchAsync.getResearchDetail(id)),
    resetDetail: () => dispatch(researchAsync.resetDetail()),
    newReportPost: (report: ReportPost) =>
      dispatch(reportAsync.newReport(report)),
    resetSuccess: () => dispatch(reportActions.reportSuccess({ success: "" })),
    resetError: () => dispatch(reportActions.reporError({ error: "" })),
  })
)(MyResearches);
