import {
  Checkbox,
  CircularProgress,
  IconButton,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from "@mui/material";
import {
  Dispatch as ReactDispatch,
  FunctionComponent,
  SetStateAction,
} from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { Report } from "core/types/report.types";
import ArrowDownwardRoundedIcon from "@mui/icons-material/ArrowDownwardRounded";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import i18n from "i18next";
import "dayjs/locale/it";
import "dayjs/locale/en";
import { useNavigate } from "react-router-dom";
import { customPink } from "core/theme/variants";

interface OwnProps {
  reportList?: Report[];
  isReportLoading: boolean;
  practiceToGenerate?: Report;
  setPracticeToGenerate?: ReactDispatch<SetStateAction<Report | undefined>>;
}

interface StateProps {
  isDownloadReportLoading: boolean;
}

interface DispatchProps {
  download: (id: number) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const ReportListTable: FunctionComponent<Props> = ({
  reportList,
  isReportLoading,
  isDownloadReportLoading,
  practiceToGenerate,
  setPracticeToGenerate,
  download,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linguaCorrente = i18n.language;
  dayjs.locale(linguaCorrente);

  return (
    <div>
      {isDownloadReportLoading && (
        <LoadingOverlay
          text={t("my_reports.download")}
          open={isDownloadReportLoading}
        />
      )}
      {isReportLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                <TableCell>{t("my_reports.table.made_on")}</TableCell>
                <TableCell sx={{ textAlign: "left !important" }}>
                  {t("my_reports.table.id")}
                </TableCell>
                <TableCell>{t("my_reports.table.research")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "13px",
                },
              }}
            >
              {reportList?.map((report, index) => (
                <TableRow
                  key={report.id}
                  sx={{
                    marginBottom:
                      index === reportList.length - 1 ? "70px" : "0px",
                  }}
                >
                  <TableCell>
                    <Checkbox
                      checked={report.id === practiceToGenerate?.id}
                      onChange={(_) => {
                        if (setPracticeToGenerate) {
                          setPracticeToGenerate(report);
                        }
                      }}
                    />
                  </TableCell>
                  <TableCell style={{ whiteSpace: "nowrap" }}>
                    {dayjs(report.timestamp).format("DD MMMM YYYY")}
                  </TableCell>

                  <TableCell>{report.id}</TableCell>

                  <TableCell>
                    <Tooltip title={t("my_reports.generate_tooltip_cell")}>
                      <Link
                        onClick={() =>
                          navigate("/my-research/" + report.researchID)
                        }
                        color={"primary"}
                        sx={{
                          "&:hover": {
                            color: customPink[400],
                          },
                        }}
                      >
                        n° {report.researchID}
                      </Link>
                    </Tooltip>
                  </TableCell>

                  <TableCell>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {report.link && (
                        <Tooltip title={t("my_reports.download_tooltip")}>
                          <IconButton onClick={() => download(report.id)}>
                            <ArrowDownwardRoundedIcon color="info" />
                          </IconButton>
                        </Tooltip>
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    isDownloadReportLoading: reportSelectors.isDownloadReportLoading(state),
  }),
  (dispatch: Dispatch) => ({
    download: (id: number) => dispatch(reportAsync.downloadReport(id)),
  })
)(ReportListTable);
