import { FunctionComponent, useState } from "react";
import { Button, Collapse, IconButton, Alert as MuiAlert } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Text } from "components/atoms/text";
import ErrorRoundedIcon from "@mui/icons-material/ErrorRounded";

interface Props {
  title: string;
  subtitle: string;
  buttonTitle: string;
  buttonAction: () => void;
}

const Alert: FunctionComponent<Props> = ({
  title,
  subtitle,
  buttonTitle,
  buttonAction,
}) => {
  const [open, setOpen] = useState(true);
  return (
    <Collapse in={open}>
      <MuiAlert
        variant="outlined"
        severity="error"
        icon={<ErrorRoundedIcon />}
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={() => {
              setOpen(false);
            }}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2, borderRadius: "10px", background: "#F2618826" }}
      >
        <Text fontSize={"25px"} fontWeight={"900"}>
          {title}
        </Text>
        <Text fontSize={"16px"} fontWeight={"400"} mb={"15px"}>
          {subtitle}
        </Text>
        <Button variant="contained" onClick={buttonAction}>
          {buttonTitle}
        </Button>
      </MuiAlert>
    </Collapse>
  );
};

export default Alert;
