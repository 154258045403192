import React, { FunctionComponent } from "react";
import { Typography, TypographyProps } from "@mui/material";

interface Props {
  secondary?: boolean;
}

const Text: FunctionComponent<TypographyProps & Props> = ({
  children,
  secondary,
  ...otherProps
}) => {
  return (
    <Typography
      {...otherProps}
      color={secondary ? "text.secondary" : "text.primary"}
    >
      {children}
    </Typography>
  );
};

export default Text;
