import { actionCreatorFactory } from "typescript-fsa";
import { DownloadItem, Report } from "core/types/report.types";

const actionCreator = actionCreatorFactory("report");

export const resetReport = actionCreator<void>("RESET");

export const reportSuccess = actionCreator<{ success: string }>(
  "REPORT_SUCCESS"
);

export const reporError = actionCreator<{ error: string }>("REPORT_ERROR");

export const isReportLoading = actionCreator<{ isReportLoading: boolean }>(
  "IS_REPORT_LOADING"
);

export const isDownloadReportLoading = actionCreator<{
  isDownloadReportLoading: boolean;
}>("IS_DOWNLOAD_REPORT_LOADING");

export const reportList = actionCreator<{ reportList: Report[] }>(
  "REPORT_LIST"
);

export const report = actionCreator<{ report: Report }>("REPORT");

export const incrementDownloadList = actionCreator<{
  downloadItem: DownloadItem;
}>("INC_DOWNLOAD_LIST");

export const decrementDownloadList = actionCreator<{ reportId: number }>(
  "DEC_DOWNLOAD_LIST"
);
