import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./film.types";
import * as actions from "./film.actions";

export const initialState: Readonly<State> = {
  filmList: [],
  isFilmLoading: false,
  error: "",
  success: "",
};

export default reducerWithInitialState(initialState)
  .case(actions.resetFilm, () => ({ ...initialState }))
  .case(actions.filmList, (state, { filmList }) => ({
    ...state,
    filmList,
  }))
  .case(actions.isFilmLoading, (state, { isFilmLoading }) => ({
    ...state,
    isFilmLoading,
  }))
  .case(actions.error, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(actions.success, (state, { success }) => ({
    ...state,
    success,
  }))
  .build();
