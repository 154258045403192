import { User } from "core/types/user.types";
import { ApiFactoryClients } from "../api.types";
import { transformUser } from "./code.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getUser: () =>
    codeHttpClient.get("/user").then(({ data }) => transformUser(data)),
  getUsers: (): Promise<User[]> =>
    codeHttpClient
      .get("/admin")
      .then(({ data }) => data?.map((u: any) => transformUser(u))),
  updateUser: (user: User): Promise<void> => codeHttpClient.put("/admin", user),
  activateUser: (id: string): Promise<void> =>
    codeHttpClient.post("/admin?user_id=" + id),
});
