import { NewResearchStepper } from "containers/new-research/new-research-stepper";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { Box, Fade, Modal, Paper as MuiPaper } from "@mui/material";
import { useTheme } from "@emotion/react";
import Backdrop from "@mui/material/Backdrop";
import { customPink } from "core/theme/variants";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import { ResearchPost } from "core/types/research.types";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "100vw",
  height: "100%",
  bgcolor: customPink[700],
  p: 5,
};

const Paper = styled(MuiPaper)(spacing);

const Content = styled(Paper)`
  flex: 1;
  background: #fff;
  height: 100%;
  padding: 30px;
  border-radius: 15px;
  position: relative;
  overflow: auto;
`;

type Props = {
  researchFields?: ResearchPost;
  isVisible: boolean;
  setIsVisible: (b: boolean) => void;
};

const NewResearch: FunctionComponent<Props> = ({
  isVisible,
  researchFields,
  setIsVisible,
}) => {
  return (
    <>
      <Modal
        open={isVisible}
        onClose={() => setIsVisible(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={isVisible}>
          <Box sx={style}>
            <Content>
              <NewResearchStepper
                researchFields={researchFields}
                onClose={() => setIsVisible(false)}
              />
            </Content>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default NewResearch;
