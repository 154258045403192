import { FunctionComponent, useEffect, useState } from "react";
import { User } from "core/types/user.types";
import { connect } from "react-redux";
import { RootState } from "core/store/store.types";
import { authSelectors } from "core/store/modules/auth";
import {
  Box,
  Button,
  Grid,
  Link,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import cardResearch from "../../assets/images/onboarding/card_ricerca.png";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/text";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import { NewResearch } from "pages/new-research";
import PricingPlan from "containers/my-profile/pricing-plan/pricing-plan";
import { SubscriptionPlan } from "containers/my-profile/subscription-plan";

interface OwnProps {}
interface StateProps {
  user?: User;
  isAuthLoading: boolean;
}
interface DispatchProps {}

export type Props = OwnProps & StateProps & DispatchProps;

const Home: FunctionComponent<Props> = ({ user, isAuthLoading }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isLgUp = useMediaQuery(theme.breakpoints.up("lg"));
  const isSmUp = useMediaQuery(theme.breakpoints.up("sm"));
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <Grid container spacing={6} sx={{ marginTop: "40px" }}>
        <div
          style={{
            position: "absolute",
            right: 0,
            zIndex: 0,
          }}
          >
          <img src={cardResearch} style={{ height: "400px" }} />
        </div>
        {user ? (
          <>
            <Grid item xs={12} sm={12} md={8} lg={6} xl sx={{ zIndex: 2 }}>
              <Text variant="body2" fontWeight={"900"}>
                {t("home.onboarding")}
              </Text>
              <Text variant="h1" fontWeight={"700"} fontSize={"39px"}>
                {t("home.welcome") + " " + user?.firstName}
              </Text>
              <Text variant="body1" fontSize={"16px"}>
                {t("home.welcome_subtitle_1")}
              </Text>
              <Text
                variant="body1"
                fontSize={"16px"}
                style={{ whiteSpace: isLgUp || isSmUp ? "pre-wrap" : "normal" }}
              >
                <Link onClick={() => setOpenModal(true)}>
                  {t("home.welcome_link")}
                </Link>{" "}
                {t("home.welcome_subtitle_2")}
              </Text>
              <Button
                variant="contained"
                sx={{ marginTop: "42px" }}
                onClick={() => setOpenModal(true)}
              >
                {t("header.search")}
              </Button>
            </Grid>
            <Grid item container xs={12} sx={{ zIndex: 2 }}>
              <PricingPlan />
            </Grid>
            <Grid item container xs={12} sx={{ zIndex: 2 }}>
              <SubscriptionPlan />
            </Grid>
          </>
        ) : (
          isAuthLoading && <LoadingOverlay open={isAuthLoading} />
        )}
        {/* {user && <HomeStepper user={user} />}
        <PricingPlan />
        <Footer /> */}
      </Grid>
      <NewResearch isVisible={openModal} setIsVisible={setOpenModal} />
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    user: authSelectors.getUser(state),
    isAuthLoading: authSelectors.isAuthLoading(state),
  }),
  () => ({})
)(Home);
