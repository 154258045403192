import codeApiFactory from "./code/code.api";
import researchApiFactory from "./research/research.api";
import reportApiFactory from "./report/report.api";
import productApiFactory from "./product/product.api";
import transactionApiFactory from "./transaction/transaction.api";
import notifyApiFactory from "./notify/notify.api";
import practiceApiFactory from "./practice/practice.api";
import urlApiFactory from "./url/url.api";
import domainApiFactory from "./domain/domain.api";
import filmApiFactory from "./film/film.api";
import { codeHttpClient } from "../http/clients/code/code.http";

export const codeApi = codeApiFactory({
  codeHttpClient,
});

export const researchApi = researchApiFactory({
  codeHttpClient,
});

export const reportApi = reportApiFactory({
  codeHttpClient,
});

export const productApi = productApiFactory({
  codeHttpClient,
});

export const transactionApi = transactionApiFactory({
  codeHttpClient,
});

export const notifyApi = notifyApiFactory({
  codeHttpClient,
});

export const practiceApi = practiceApiFactory({
  codeHttpClient,
});

export const urlApi = urlApiFactory({
  codeHttpClient,
});

export const domainApi = domainApiFactory({
  codeHttpClient,
});

export const filmApi = filmApiFactory({
  codeHttpClient,
});
