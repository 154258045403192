import { FunctionComponent, useEffect, useState } from "react";
import { Box, Button, Grid, IconButton, Link } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { ConfigureResearchForm } from "components/organisms/configure-research-form";
import { AddParamsForm } from "components/organisms/add-params-form";
import { Research, ResearchPost } from "core/types/research.types";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import {
  researchActions,
  researchAsync,
  researchSelectors,
} from "core/store/modules/research";
import dayjs from "dayjs";
import { LinearProgress } from "@mui/material";
import { Text } from "components/atoms/text";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import AnalyticsRoundedIcon from "@mui/icons-material/AnalyticsRounded";
import { ResearchTable } from "components/organisms/research-table";
import { EmptyState } from "components/atoms/empty-state";
import { ReportPost } from "core/types/report.types";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import { Step } from "components/molecules/step";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { useNavigate } from "react-router-dom";

import "./new-research-stepper.css";

interface OwnProps {
  researchFields?: ResearchPost;
  onClose: () => void;
}
interface StateProps {
  research?: Research;
  isResearchLoading: boolean;
  isReportLoading: boolean;
  reportSuccess?: string;
}
interface DispatchProps {
  newResearchPost: (researchPayload: ResearchPost) => void;
  newReportPost: (reportPayload: ReportPost) => void;
  resetReportSuccess: () => void;
  resetResearch: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const NewResearchStepper: FunctionComponent<Props> = ({
  research,
  isResearchLoading,
  isReportLoading,
  reportSuccess,
  researchFields,
  newResearchPost,
  newReportPost,
  resetReportSuccess,
  onClose,
  resetResearch,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = React.useState(0);
  const steps = [
    { label: t("new-research.stepper1.title") },
    { label: t("new-research.stepper2.title") },
    { label: t("new-research.stepper3.title") },
  ];
  const [newResearch, setNewResearch] = useState<ResearchPost>();
  const [dateToResearch, setDateToResearch] = useState<Date | undefined>(
    researchFields?.dateFromWhichToResearch
      ? new Date(researchFields?.dateFromWhichToResearch)
      : new Date("1/1/2006")
  );
  const [impact, setImpact] = useState(
    researchFields?.impact ? researchFields.impact : 50
  );
  const [minDuration, setMinDuration] = useState(
    researchFields?.minDuration ? researchFields.minDuration : 10
  );
  const [originSources, setOriginSources] = useState<string[]>(
    researchFields?.originSources ? researchFields.originSources : []
  );
  const [params, setParams] = useState<
    { id: string; name: string; weight: number }[]
  >(
    researchFields?.params
      ? researchFields.params.map((p, idx) => ({
          id: idx.toString(),
          name: p.name,
          weight: p.weight,
        }))
      : []
  );
  const [type, setType] = useState(researchFields?.type);
  const [selectedVideos, setSelectedVideos] = useState<string[]>([]);

  const handleNext = () => {
    if (activeStep < 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    } else {
      if (newResearch) {
        newResearchPost(newResearch);
        setActiveStep(2);
      }
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const newResearchValue = {
      dateFromWhichToResearch: dateToResearch
        ? dayjs(dateToResearch).format("YYYY-MM-DD HH:mm:ss")
        : "",
      impact: impact,
      minDuration: minDuration,
      originSources: originSources,
      params: params.map((p) => {
        return { name: p.name, weight: p.weight };
      }),
      type: type ? type : 0,
    };
    setNewResearch({ ...newResearchValue });
  }, [dateToResearch, impact, minDuration, originSources, params, type]);

  const StepIcon = (index: number) => {
    if ((index === 0 && activeStep > 0) || (index === 1 && activeStep > 1)) {
      return (
        <div className="new-research-dot">
          <SearchRoundedIcon color="info" sx={{ width: "16px" }} />
        </div>
      );
    } else if (index === steps.length - 1 && research) {
      return (
        <div className="new-research-dot">
          <AnalyticsRoundedIcon color="info" sx={{ width: "16px" }} />
        </div>
      );
    }
    return (
      <div className="new-research-dot">
        <Text fontWeight={"600"}>{index + 1}</Text>
      </div>
    );
  };

  const onGenerateReportButton = () => {
    if (research) {
      newReportPost({
        researchId: research.id,
        urls: selectedVideos,
      });
    }
  };

  const resetFields = () => {
    setActiveStep(0);
    setNewResearch({});
    setDateToResearch(undefined);
    setImpact(50);
    setMinDuration(10);
    setOriginSources(["Youtube", "Vimeo", "Twitter", "Dailymotion"]);
    setParams([]);
    setType(0);
    resetReportSuccess();
    resetResearch();
  };

  // useEffect(() => {
  //   if (reportSuccess) {
  //     console.log(reportSuccess)
  //     resetFields();
  //     onClose();
  //     console.log('ehi')
  //   }
  // }, [reportSuccess]);

  return (
    <>
      <IconButton
        onClick={() => {
          onClose();
          resetFields();
        }}
        sx={{
          position: "absolute",
          right: 5,
          top: 5,
        }}
      >
        <CloseRoundedIcon color="info" />
      </IconButton>
      {isReportLoading && <LoadingOverlay open={isReportLoading} />}
      <Grid container spacing={3} height={"100%"}>
        {(!research || research.results.length === 0) && (
          <Grid
            item
            width={"100%"}
            display="flex"
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Text variant="h3" fontWeight={"900"} mt={"10px"}>
              {t("new-research.title")}
            </Text>
            <Text variant="body1">
              {t("new-research.help")}
              <Link
                onClick={() =>
                  window.open("https://t.me/codertd", "_blank", "noreferrer")
                }
                sx={{ marginLeft: "5px" }}
              >
                {t("new-research.contact")}
              </Link>
            </Text>
          </Grid>
        )}
        <Grid container item spacing={4} sx={{ marginTop: "10px" }}>
          {(!research || research.results.length === 0) && (
            <Grid item xs={12} sm={4}>
              <Text
                variant="body1"
                sx={{
                  marginBottom: "20px",
                }}
              >
                {t("new-research.description1")}
              </Text>
              <Text
                variant="body1"
                sx={{
                  marginBottom: "36px",
                }}
              >
                {t("new-research.description2")}
              </Text>
              {steps.map((s, index) => (
                <Step
                  key={index}
                  label={s.label}
                  activeStep={activeStep}
                  index={index}
                />
              ))}
            </Grid>
          )}
          <Grid
            item
            xs={12}
            sm={!research || research.results.length === 0 ? 8 : 12}
            display={"flex"}
            justifyContent={
              isResearchLoading || reportSuccess ? "center" : "flex-start"
            }
            flexDirection={"column"}
          >
            {activeStep === 0 && (
              <ConfigureResearchForm
                dateToResearch={dateToResearch}
                setDateToResearch={setDateToResearch}
                impact={impact}
                setImpact={setImpact}
                minDuration={minDuration}
                setMinDuration={setMinDuration}
                originSources={originSources}
                setOriginSources={setOriginSources}
                type={type}
                setType={setType}
                redoResearchField={researchFields ? true : false}
              />
            )}

            {activeStep === 1 && (
              <AddParamsForm params={params} setParams={setParams} />
            )}

            {activeStep === 2 &&
              (isResearchLoading ? (
                <Grid
                  container
                  display={"flex"}
                  alignItems={"center"}
                  direction={"column"}
                >
                  <Text variant="h2" fontSize={"30px"} fontWeight={"700"}>
                    {t("new-research.stepper3.loader_title")}
                  </Text>
                  <Text fontSize={"16px"} fontWeight={"600"}>
                    {t("new-research.stepper3.loader_subtitle")}
                  </Text>
                  <LinearProgress
                    color="primary"
                    variant="indeterminate"
                    sx={{
                      height: "5px",
                      width: "100%",
                      background: "transparent",
                      marginTop: "10px",
                    }}
                  />
                </Grid>
              ) : (
                <>
                  {research && research.results.length > 0 ? (
                    !reportSuccess ? (
                      <ResearchTable
                        research={research}
                        selectedVideos={selectedVideos}
                        setSelectedVideos={setSelectedVideos}
                        onGenerateReportButton={onGenerateReportButton}
                        style={{ maxHeight: "400px" }}
                      />
                    ) : (
                      <Grid
                        container
                        alignItems={"center"}
                        direction={"column"}
                      >
                        <EmptyState
                          title={t("empty_states.successful_report.title")}
                          description={t(
                            "empty_states.successful_report.subtitle"
                          )}
                          styleTitle={{ fontWeight: "700" }}
                        />
                      </Grid>
                    )
                  ) : (
                    <div
                      style={{
                        marginRight: "25px",
                      }}
                    >
                      <EmptyState
                        title={t("empty_states.no_results.title")}
                        description={t("empty_states.no_results.subtitle")}
                      />
                    </div>
                  )}
                  {/* <Box
                    sx={{ mt: 5 }}
                    justifyContent={"center"}
                    display={"flex"}
                  >
                    <SecondaryButton onClick={() => resetFields()}>
                      {t("new-research.stepper3.new")}
                    </SecondaryButton>
                  </Box> */}
                </>
              ))}

            <Box
              width={"100%"}
              display={"flex"}
              justifyContent={"flex-end"}
              marginTop={"26px"}
            >
              {activeStep !== 0 && !reportSuccess && (
                <Button
                  onClick={() => {
                    handleBack();
                    if (activeStep === 2) {
                      resetResearch();
                    }
                  }}
                  sx={{ mt: 1, mr: 1, padding: "10px 24px" }}
                  color="primary"
                  variant="outlined"
                >
                  <Text
                    sx={{ color: "#1D2C54" }}
                    fontWeight={"700"}
                    fontSize={"14px"}
                  >
                    {t("new-research.back_button")}
                  </Text>
                </Button>
              )}
              {reportSuccess ? (
                <Button
                  onClick={() => {
                    onClose();
                    resetFields();
                    navigate("/dashboard");
                  }}
                  sx={{ mt: 1, mr: 1, padding: "10px 24px" }}
                  color="primary"
                  variant="outlined"
                >
                  <Text
                    sx={{ color: "#1D2C54" }}
                    fontWeight={"700"}
                    fontSize={"14px"}
                  >
                    {t("empty_states.successful_report.button")}
                  </Text>
                </Button>
              ) : activeStep !== 2 ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  sx={{ mt: 1, mr: 1, padding: "10px 24px" }}
                  disabled={
                    activeStep === steps.length - 2 &&
                    (!newResearch?.dateFromWhichToResearch ||
                      !newResearch.impact ||
                      !newResearch.minDuration ||
                      !newResearch.originSources?.length ||
                      !newResearch.params?.length)
                  }
                >
                  {activeStep === steps.length - 2
                    ? t("new-research.research_button")
                    : t("new-research.go_button")}
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => onGenerateReportButton()}
                  sx={{ mt: 1, mr: 1, padding: "10px 24px" }}
                  disabled={selectedVideos.length === 0}
                >
                  {t("results.generate_report")}
                </Button>
              )}
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    research: researchSelectors.getResearch(state),
    isResearchLoading: researchSelectors.isResearchLoading(state),
    isReportLoading: reportSelectors.isReportLoading(state),
    reportSuccess: reportSelectors.getReportSuccess(state),
  }),
  (dispatch: Dispatch) => ({
    newResearchPost: (research: ResearchPost) =>
      dispatch(researchAsync.newResearch(research)),
    newReportPost: (report: ReportPost) =>
      dispatch(reportAsync.newReport(report)),
    resetReportSuccess: () => dispatch(reportAsync.resetSuccess()),
    resetResearch: () => dispatch(researchActions.resetResearch(undefined)),
  })
)(NewResearchStepper);
