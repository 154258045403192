import {
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  SelectChangeEvent,
  Slider,
  TextField,
  Tooltip,
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { Col } from "components/atoms/col";
import { Row } from "components/atoms/row";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import InfoIcon from "@mui/icons-material/Info";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import { ResearchPost } from "core/types/research.types";
import { makeStyles } from "@mui/styles";
import { Text } from "components/atoms/text";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const sources = ["Youtube", "Vimeo", "Twitter", "Dailymotion"];

interface Props {
  dateToResearch: Date | undefined;
  setDateToResearch: (d: Date) => void;
  impact: number;
  setImpact: (n: number) => void;
  minDuration: number;
  setMinDuration: (m: number) => void;
  originSources: string[];
  setOriginSources: (s: string[]) => void;
  type?: number;
  setType: (t: number) => void;
  redoResearchField: boolean;
}

const ConfigureResearchForm: FunctionComponent<Props> = ({
  dateToResearch,
  setDateToResearch,
  impact,
  setImpact,
  minDuration,
  setMinDuration,
  originSources,
  setOriginSources,
  type,
  setType,
  redoResearchField,
}) => {
  const { t } = useTranslation();
  const [allSelected, setAllSelected] = useState(true);
  const [redoResearch, setRedoResearch] = useState(redoResearchField);

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setType(event.target.checked ? 1 : 0);
  };

  const handleDateChange = (date: Date | null) => {
    if (date) setDateToResearch(date);
  };

  const handleChangeSelect = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const values = (typeof value === "string" ? [value] : value).filter(
      (v) => v !== undefined
    );
    if (values.length !== 4) {
      setAllSelected(false);
    }
    setOriginSources(values);
  };

  useEffect(() => {
    if (allSelected && !redoResearch) {
      setOriginSources([...sources]);
    } else if (!allSelected && originSources.length === 4) {
      setOriginSources([]);
    }
    setRedoResearch(false);
  }, [allSelected]);

  const handleChangeSlider = (event: Event, newValue: number | number[]) => {
    if (typeof newValue === "number") {
      setImpact(newValue);
    }
  };

  // const convertTime = (value: number, toSeconds: boolean): number => {
  //   if (toSeconds) {
  //     return value * 60;
  //   } else {
  //     return Math.floor(value / 60);
  //   }
  // };

  return (
    <>
      <FormControl fullWidth variant="standard" sx={{ marginBottom: "26px" }}>
        <InputLabel
          id="mutiple-sources-label"
          sx={{ fontSize: "14px !important", fontWeight: "700" }}
        >
          {t("new-research.stepper1.select_label")}
        </InputLabel>
        <Select
          labelId="mutiple-sources-label"
          id="mutiple-sources"
          multiple
          value={originSources}
          onChange={handleChangeSelect}
          renderValue={(selected) => selected.join(", ")}
          MenuProps={MenuProps}
          color="secondary"
          // style={{ minWidth: "200px" }}
        >
          <MenuItem>
            <Checkbox
              checked={allSelected}
              onChange={() => {
                setAllSelected(!allSelected);
              }}
            />
            <ListItemText primary={t("new-research.stepper1.select_all")} />
          </MenuItem>
          {sources.map((source) => {
            return (
              <MenuItem key={source} value={source}>
                <Checkbox checked={originSources.indexOf(source) > -1} />
                <ListItemText primary={source} />
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: "26px" }}>
        <TextField
          type="number"
          variant="standard"
          label={t("new-research.stepper1.seconds_label")}
          value={minDuration}
          onChange={(e) => {
            setMinDuration(Number(e.target.value));
          }}
          InputLabelProps={{
            style: {
              width: "inherit",
              fontSize: "14px !important",
              fontWeight: "700",
            },
          }}
          InputProps={{
            endAdornment: <Text>{t("new-research.stepper1.seconds")}</Text>,
          }}
          fullWidth
          error={minDuration < 10}
        />
      </FormControl>

      <FormControl fullWidth sx={{ marginBottom: "26px" }}>
        <DatePicker
          value={dateToResearch}
          onChange={handleDateChange}
          slotProps={{
            textField: {
              variant: "standard",
              label: t("new-research.stepper1.date_picker_label"),
              fullWidth: true,
              InputLabelProps: {
                style: {
                  width: "80%",
                  fontSize: "14px !important",
                  fontWeight: "700",
                },
              },
              style: {
                minWidth: "200px",
              },
            },
          }}
          sx={{
            svg: { color: "rgba(0, 0, 0, 0.87)" },
            "&.MuiPickersToolbar": {
              backgroundColor: "#424242",
            },
          }}
        />
      </FormControl>

      <>
        <InputLabel
          htmlFor="slider"
          sx={{
            display: "flex",
            alignItems: "center",
            fontSize: "11px",
            fontWeight: "700",
          }}
        >
          {t("new-research.stepper1.slider_label")}
          {/* <Tooltip title={t("new-research.stepper1.slider_info")}>
          <IconButton size="small">
            <InfoIcon color="info" />
          </IconButton>
        </Tooltip> */}
        </InputLabel>
        <Slider
          id="slider"
          value={impact}
          min={0}
          max={100}
          step={1}
          onChange={handleChangeSlider}
          valueLabelDisplay="auto"
          color="primary"
          size="small"
        />
      </>

      <FormControlLabel
        control={
          <Checkbox checked={type === 1} onChange={handleCheckboxChange} />
        }
        label={t("new-research.stepper1.checkbox_label")}
      />
    </>
  );
};

export default ConfigureResearchForm;
