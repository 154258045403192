import Keycloak from "keycloak-js";
import {
  KEYCLOAK_CLIENT_ID,
  KEYCLOAK_REALM,
  KEYCLOAK_URI,
} from "./core/config/environment";

const keycloak = new Keycloak({
  realm: KEYCLOAK_REALM,
  clientId: KEYCLOAK_CLIENT_ID,
  url: KEYCLOAK_URI,
});

export default keycloak;
