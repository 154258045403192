import { Col } from "components/atoms/col";
import { Text } from "../../atoms/text";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import {
  Grid,
} from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  customSelect: {
    "& .MuiSelect-icon": {
      color: "#ffffffb3",
    },
    "& .MuiSelect-select": {
      color: "#fff",
      backgroundColor: "#495769c7",
      borderRadius: "30px",
      boxShadow: "0 0 10px 5px #0006",
    },
  },
  customInputBase: {
    "&.MuiInputBase-root": {
      borderRadius: "30px !important",
    },
  },
  customList: {
    "&.MuiList-root": {
      boxShadow:
        "0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f",
      backgroundColor: "#424242",
      padding: 0,
    },
  },
}));

interface Props {}

const PricingPlanHeader: FunctionComponent<Props> = ({}) => {
  const { t } = useTranslation();

  return (
    <Grid item xs={12} p={"50px 0px 20px 0px"}>
      <Text variant="h3" fontWeight={"700"}>
        {t("profile.pricing-plan-title")}
      </Text>
      <Text variant="body2" fontWeight={"500"}>
        {t("profile.pricing-plan-subtitle")}
      </Text>
    </Grid>
  );
};

export default PricingPlanHeader;
