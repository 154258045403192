import { Report, ReportPost } from "core/types/report.types";
import { ApiFactoryClients } from "../api.types";
import { transformReport, transformReportList } from "./report.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  newReport: (payload: ReportPost): Promise<string> =>
    codeHttpClient
      .post("/report", {
        researchID: payload.researchId,
        urls: payload.urls,
      })
      .then(({ data }) => data.message),
  reports: (): Promise<Report[]> =>
    codeHttpClient.get("/report").then(({ data }) => transformReportList(data)),
  report: (id: string): Promise<Report> =>
    codeHttpClient
      .get("/report?id=" + id)
      .then(({ data }) => transformReport(data)),
  download: (id: number): Promise<any> =>
    codeHttpClient
      .get("/download?id=" + id, { responseType: "arraybuffer" })
      .then(({ data }) => data),
});
