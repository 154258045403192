import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./url.types";
import * as actions from "./url.actions";

export const initialState: Readonly<State> = {
  isUrlLoading: false,
  urlList: [],
  urlDetail: undefined,
  error: undefined,
  success: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetUrl, () => ({ ...initialState }))
  .case(actions.isUrlLoading, (state, { isUrlLoading }) => ({
    ...state,
    isUrlLoading,
  }))
  .case(actions.urlList, (state, { urlList }) => ({
    ...state,
    urlList,
  }))
  .case(actions.urlDetail, (state, { urlDetail }) => ({
    ...state,
    urlDetail,
  }))
  .case(actions.error, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(actions.success, (state, { success }) => ({
    ...state,
    success,
  }))
  .build();
