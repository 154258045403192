import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./report.types";
import * as actions from "./report.actions";

export const initialState: Readonly<State> = {
  report: undefined,
  isReportLoading: false,
  isDownloadReportLoading: false,
  success: undefined,
  error: undefined,
  reportList: [],
  downloadList: [],
};

export default reducerWithInitialState(initialState)
  .case(actions.resetReport, () => ({ ...initialState }))
  .case(actions.reportSuccess, (state, { success }) => ({
    ...state,
    success,
  }))
  .case(actions.reporError, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(actions.isReportLoading, (state, { isReportLoading }) => ({
    ...state,
    isReportLoading,
  }))
  .case(
    actions.isDownloadReportLoading,
    (state, { isDownloadReportLoading }) => ({
      ...state,
      isDownloadReportLoading,
    })
  )
  .case(actions.reportList, (state, { reportList }) => ({
    ...state,
    reportList,
  }))
  .case(actions.report, (state, { report }) => ({
    ...state,
    report,
  }))
  .case(actions.incrementDownloadList, (state, { downloadItem }) => ({
    ...state,
    downloadList: [...(state.downloadList ?? []), downloadItem],
  }))
  .case(actions.decrementDownloadList, (state, { reportId }) => ({
    ...state,
    downloadList: state.downloadList?.filter(
      (item) => item.reportID !== reportId
    ),
  }))
  .build();
