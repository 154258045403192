import {
  LegalPracticeRequest,
  Practice,
  PracticeReport,
  PracticeUrl,
  PracticeUser,
} from "core/types/practice.types";

export const transformPracticeReport = (data: any): PracticeReport => ({
  id: data?.id,
  createdAt: data?.created_at,
});

export const transformPractice = (data: any): Practice => ({
  createdAt: data?.created_at,
  id: data?.id,
  report: transformPracticeReport(data?.report),
  status: data?.status,
  updatedAt: data?.updated_at,
  urls: data?.urls.map((url: any) => transformPracticeUrl(url)),
  user: transformPracticeUser(data?.user),
});

export const transformPracticeRequest = (data: any): LegalPracticeRequest => ({
  urlId: data?.url_id,
  userId: data?.user_id,
  requestType: data?.request_type,
  requestId: data?.request_id,
  createdAt: data?.created_at,
});

export const transformPracticeUser = (data: any): PracticeUser => ({
  id: data?.id,
  firstName: data?.firstName,
  lastName: data?.lastName,
  role: data?.role,
  email: data?.email,
  verified: data?.verified,
});

export const transformPracticeUrl = (data: any): PracticeUrl => ({
  domain: data?.domain,
  id: data?.id,
  status: data?.status,
  url: data?.url,
  lastRequest: {
    createdAt: data?.last_request?.created_at,
    type: data?.last_request?.type,
  },
});
