import { Row } from "components/atoms/row";
import React from "react";
import { FunctionComponent } from "react";
import { Text } from "components/atoms/text";

interface Props {
  label: string;
  value: string;
}

const KeyValueComponent: FunctionComponent<Props> = ({ label, value }) => {
  return (
    <Row style={{ marginBottom: "8px", borderBottom: "0.5px solid #68728C" }}>
      <Text fontSize={"13px"} fontWeight={"700"} mb={"16px"}>
        {label}
      </Text>
      <Text pb={"14px"} fontSize={"16px"} fontWeight={"400"}>
        {value}
      </Text>
    </Row>
  );
};

export default KeyValueComponent;
