import { actionCreatorFactory } from "typescript-fsa";
import { Notify } from "core/types/notify.types";

const actionCreator = actionCreatorFactory("auth");

export const resetNotify = actionCreator<void>("RESET");

export const notifyList = actionCreator<{ notifyList: Notify[] }>(
  "NOTIFY_LIST"
);
