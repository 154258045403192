import axios, { CreateAxiosDefaults } from "axios";
import { CODE_API_URI } from "../../../config/environment";

const axiosConfig: CreateAxiosDefaults<any> = {
  baseURL: CODE_API_URI,
};

const codeHttpClient = axios.create(axiosConfig);

export { codeHttpClient };
