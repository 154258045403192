import {
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { PracticeUrl } from "core/types/practice.types";
import i18n from "i18n";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { parse } from "date-fns";
import { User } from "core/types/user.types";
import InfoIcon from "@mui/icons-material/Info";
import MailRoundedIcon from "@mui/icons-material/MailRounded";
import { InfoModal } from "components/organisms/info-modal";
import { Text } from "components/atoms/text";
import { UrlReportingTable } from "components/organisms/url-reporting-table";
import { useNavigate } from "react-router-dom";

interface Props {
  list?: PracticeUrl[];
  isLoading: boolean;
  createdAt?: string;
  updatedAt?: string;
  user?: User;
  urlList?: PracticeUrl[];
  setUrlList: (list: PracticeUrl[]) => void;
  urls: number[];
  setUrls: (u: number[]) => void;
  status: string;
  setStatus: (s: string) => void;
  setSelectedUrl: (n: number) => void;
  openRequestModal: boolean;
  setOpenRequestModal: (b: boolean) => void;
  sendRequest: () => void;
}

const UrlTable: FunctionComponent<Props> = ({
  list,
  isLoading,
  createdAt,
  updatedAt,
  user,
  urlList,
  setUrlList,
  openRequestModal,
  setOpenRequestModal,
  urls,
  setUrls,
  status,
  setStatus,
  setSelectedUrl,
  sendRequest,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const navigate = useNavigate();
  const [typeOfRequest, setTypeOfRequest] = useState("email");

  return (
    <>
      {isLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "250px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("practices.detail.urls.table.url")}</TableCell>
                <TableCell>{t("practices.detail.urls.table.status")}</TableCell>
                <TableCell>{t("practices.detail.urls.table.date")}</TableCell>
                <TableCell>
                  {t("practices.detail.urls.table.last_update")}
                </TableCell>
                {(user?.role === "admin" || user?.role === "legals") && (
                  <TableCell></TableCell>
                )}
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {list?.map((item, index) => (
                <TableRow key={index}>
                  <TableCell>{item.url}</TableCell>
                  <TableCell sx={{ minWidth: "150px" }}>
                    {user?.role === "admin" || user?.role === "legals" ? (
                      <FormControl fullWidth variant="standard">
                        <Select
                          labelId="type-label"
                          id="type-label"
                          value={item.status}
                          onChange={(event) => {
                            setStatus(event.target.value);
                            setSelectedUrl(item.id);
                          }}
                          color="secondary"
                        >
                          <MenuItem value={"online"}>
                            {t("practices.detail.modal.online")}
                          </MenuItem>
                          <MenuItem value={"pending"}>
                            {t("practices.detail.modal.pending")}
                          </MenuItem>
                          <MenuItem value={"removed"}>
                            {t("practices.detail.modal.removed")}
                          </MenuItem>
                        </Select>
                      </FormControl>
                    ) : (
                      t("practices.detail.status." + item.status)
                    )}
                  </TableCell>
                  <TableCell>
                    {createdAt &&
                      parse(
                        createdAt,
                        "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
                        new Date()
                      ).toLocaleDateString(
                        linguaCorrente === "en" ? "en-GB" : "it-IT",
                        { year: "numeric", month: "long", day: "numeric" }
                      )}
                  </TableCell>
                  <TableCell>
                    {updatedAt &&
                      parse(
                        updatedAt,
                        "EEE, dd MMM yyyy HH:mm:ss 'GMT'",
                        new Date()
                      ).toLocaleDateString(
                        linguaCorrente === "en" ? "en-GB" : "it-IT",
                        { year: "numeric", month: "long", day: "numeric" }
                      )}
                  </TableCell>
                  {(user?.role === "admin" || user?.role === "legals") && (
                    <TableCell>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                        }}
                      >
                        <IconButton>
                          <MailRoundedIcon
                            color="info"
                            onClick={() => {
                              setOpenRequestModal(true);
                              setUrls([item.id]);
                              setUrlList(list);
                            }}
                          />
                        </IconButton>
                        <IconButton
                          onClick={() =>
                            navigate(item.id.toString(), { relative: "path" })
                          }
                        >
                          <InfoIcon color="info" />
                        </IconButton>
                      </div>
                    </TableCell>
                  )}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      <InfoModal
        open={openRequestModal}
        setIsVisible={setOpenRequestModal}
        submitText={t("practices.detail.modal.button")}
        onSubmitClick={() => sendRequest()}
      >
        <>
          <FormControl
            variant="standard"
            sx={{ width: "50%", marginBottom: "30px" }}
          >
            <InputLabel
              id="select-label"
              sx={{ fontSize: "14px !important", fontWeight: "700" }}
            >
              {t("practices.detail.modal.form_label")}
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={typeOfRequest}
              onChange={(event: SelectChangeEvent) =>
                setTypeOfRequest(event.target.value as string)
              }
            >
              <MenuItem value={"email"}>
                {t("practices.detail.modal.form_option")}
              </MenuItem>
            </Select>
          </FormControl>
          <Text fontSize={18} fontWeight={700} textAlign={"center"}>
            {t("practices.detail.modal.title")}
          </Text>
          <Grid minHeight={"300px"}>
            <UrlReportingTable list={urlList} urls={urls} setUrls={setUrls} />
          </Grid>
        </>
      </InfoModal>
    </>
  );
};

export default UrlTable;
