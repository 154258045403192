import { PageTitle } from "components/atoms/page-title";
import { Row } from "components/atoms/row";
import PricingPlan from "containers/my-profile/pricing-plan/pricing-plan";
import { ProfileCard } from "containers/my-profile/profile-card";
import { authSelectors } from "core/store/modules/auth";
import { RootState } from "core/store/store.types";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Text } from "components/atoms/text";
import { Col } from "components/atoms/col";
import { UserStats } from "containers/my-profile/user-stats";
import { Grid } from "@mui/material";
import { SubscriptionPlan } from "containers/my-profile/subscription-plan";
import { UserSubStats } from "containers/my-profile/user-sub-stats";

interface OwnProps {}
interface StateProps {
  user?: User;
}
interface DispatchProps {}
export type Props = OwnProps & StateProps & DispatchProps;

const MyProfile: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();

  return (
    <div style={{}}>
      <Text fontSize={"25px"} fontWeight={"700"} paddingBottom={"20px"}>
        {t("profile.title")}
      </Text>
      <Row container spacing={2} justifyContent={"center"}>
        <Col item xs={12} sm={4}>
          <Row>{user && user?.billing_info && <UserStats user={user} />}</Row>
          <Row mt={user?.billing_info ? "10px" : "0px"}>
            {user && user.subscriptions && <UserSubStats user={user} />}
          </Row>
        </Col>
        <Col item xs={12} sm={8}>
          {user && <ProfileCard user={user} />}
        </Col>
      </Row>
      <Grid container>
        <PricingPlan />
      </Grid>
      <Grid container>
        <SubscriptionPlan />
      </Grid>
    </div>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    user: authSelectors.getUser(state),
  }),
  () => ({})
)(MyProfile);
