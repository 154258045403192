import "@mui/lab/themeAugmentation";

import { createTheme as createMuiTheme } from "@mui/material/styles";
import variants from "./variants";
import typography from "./typography";
import breakpoints from "./breakpoints";
import components from "./components";
import shadows from "./shadows";

const createTheme = (name: string) => {
  let themeConfig = variants.find((variant) => variant.name === name);

  if (!themeConfig) {
    console.warn(new Error(`The theme ${name} is not valid`));
    themeConfig = variants[0];
  }

  return createMuiTheme(
    {
      spacing: 4,
      breakpoints: breakpoints,
      // @ts-ignore
      components: components,
      typography: typography,
      shadows: shadows,
      palette: themeConfig.palette,
    },
    {
      name: themeConfig.name,
      header: themeConfig.header,
      footer: themeConfig.footer,
      sidebar: themeConfig.sidebar,
    }
  );
};

export default createTheme;


// import { createTheme } from "@mui/material";

// export const theme = createTheme({
//   palette: {
//     primary: {
//       main: "#fff",
//     },
//     text: {
//       primary: "#fff",
//       secondary: "#42E3D3",
//     },
//     info: {
//       main: "#fff",
//     },
//     secondary: {
//       main: "#42E3D3",
//     },
//   },
//   typography: {
//     fontFamily: "Montserrat",
//   },
//   components: {
//     MuiButton: {
//       styleOverrides: {
//         root: {
//           textTransform: "none",
//           fontSize: 15,
//           fontFamily: "Montserrat",
//           fontWeight: "bold",
//           paddingTop: 0,
//           paddingBottom: 0,
//           paddingLeft: 2,
//           paddingRight: 2,
//         },
//         outlined: {
//           borderWidth: 2,
//           borderColor: "#fff",
//         },
//       },
//     },
//     MuiMenu: {
//       styleOverrides: {
//         list: {
//           '&[role="menu"]': {
//             backgroundColor: "#495769c7",
//           },
//         },
//       },
//     },
//     MuiList: {
//       styleOverrides: {
//         root: {
//           boxShadow:
//             "0 2px 4px -1px #0003, 0 4px 5px #00000024, 0 1px 10px #0000001f",
//           backgroundColor: "#424242",
//           padding: 0,
//         },
//       },
//     },
//     MuiMenuItem: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "transparent",
//           "&:hover": {
//             backgroundColor: "#fffc7",
//           },
//           "&.Mui-selected": {
//             backgroundColor: "rgba(255,255,255,.12)",
//             color: "#42E3D3",
//             "&.Mui-focusVisible": {
//               background: "rgba(255,255,255,.12)",
//               color: "#42E3D3",
//             },
//           },
//           "&.Mui-selected:hover": {
//             backgroundColor: "#fffc7",
//           },
//         },
//       },
//     },
//     MuiStepConnector: {
//       styleOverrides: {
//         line: {
//           marginLeft: "24px",
//         },
//       },
//     },
//     MuiStepContent: {
//       styleOverrides: { root: { marginLeft: "36px" } },
//     },
//     MuiStepIcon: {
//       styleOverrides: {
//         root: {
//           color: "#42E3D3",
//           text: {
//             color: "#fff",
//             fill: "unset",
//           },
//           "&.Mui-active": {
//             color: "#42E3D3",
//           },
//           "&.Mui-completed": {
//             color: "#42E3D3",
//           },
//         },
//       },
//     },
//     // MuiSvgIcon: {
//     //   defaultProps: {
//     //     htmlColor: "#ffffff",
//     //   },
//     // },
//     MuiCheckbox: {
//       styleOverrides: {
//         root: {
//           color: "#fff",
//           "&.Mui-checked": {
//             color: "#42E3D3",
//           },
//         },
//       },
//     },
//     MuiSelect: {
//       styleOverrides: {
//         select: {
//           color: "#fff",
//           "&.MuiSelect-iconFilled": {
//             color: "#fff",
//           },
//           "&.MuiSelect-filled": {
//             color: "white",
//           },
//         },
//       },
//     },
//     MuiInputLabel: {
//       styleOverrides: {
//         root: {
//           color: "#fff",
//         },
//       },
//     },
//     MuiPaper: {
//       styleOverrides: {
//         root: {
//           backgroundColor: "#424242",
//           color: "#fff",
//           "&.MuiSvgIcon": {
//             fill: "#ffffff",
//           },
//         },
//       },
//     },
//     MuiTooltip: {
//       styleOverrides: {
//         tooltip: {
//           fontSize: "14px",
//           color: "white",
//           fontWeight: "500",
//           backgroundColor: "#8d8c8c",
//           maxWidth: "500px",
//         },
//       },
//     },
//   },
// });
