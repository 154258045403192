import React, { FunctionComponent } from "react";
import { Grid, GridProps } from "@mui/material";

const Col: FunctionComponent<GridProps> = ({ children, ...otherProps }) => {
  return (
    <Grid flexDirection={"column"} {...otherProps}>
      {children}
    </Grid>
  );
};

export default Col;
