import { Film } from "core/types/film.types";

export const transformFilmList = (data: any): Film[] => {
  return data.map((film: any) => transformFilm(film));
};

export const transformFilm = (data: any): Film => ({
  title: data?.title,
  id: data?.id,
  userId: data?.user_id,
});
