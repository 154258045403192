import { ReportPost } from "core/types/report.types";
import { reportApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./report.actions";

export const newReport =
  (payload: ReportPost) => async (dispatch: Dispatch) => {
    dispatch(actions.isReportLoading({ isReportLoading: true }));
    try {
      const report = await reportApi.newReport(payload);
      dispatch(actions.reportSuccess({ success: report }));
    } catch (error) {
      console.error(error);
      dispatch(actions.reporError({ error: "Error" }));
    } finally {
      dispatch(actions.isReportLoading({ isReportLoading: false }));
    }
  };

export const getReportList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isReportLoading({ isReportLoading: true }));
  try {
    const reportList = await reportApi.reports();
    dispatch(actions.reportList({ reportList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isReportLoading({ isReportLoading: false }));
  }
};

export const getReport = (id: string) => async (dispatch: Dispatch) => {
  dispatch(actions.isReportLoading({ isReportLoading: true }));
  try {
    const report = await reportApi.report(id);
    dispatch(actions.report({ report }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isReportLoading({ isReportLoading: false }));
  }
};

export const resetSuccess = () => (dispatch: Dispatch) => {
  try {
    dispatch(actions.reportSuccess({ success: "" }));
  } catch (error) {
    console.log(error);
  }
};

export const downloadReport = (id: number) => async (dispatch: Dispatch) => {
  dispatch(actions.isDownloadReportLoading({ isDownloadReportLoading: true }));
  try {
    const response = await reportApi.download(id);
    let blob = new Blob([response], { type: "application/zip" });
    let url = window.URL.createObjectURL(blob);
    dispatch(
      actions.incrementDownloadList({
        downloadItem: {
          reportID: id,
          blobUrl: url,
        },
      })
    );
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(
      actions.isDownloadReportLoading({ isDownloadReportLoading: false })
    );
  }
};

export const removeDownload = (id: number) => async (dispatch: Dispatch) => {
  dispatch(actions.isDownloadReportLoading({ isDownloadReportLoading: true }));
  try {
    dispatch(actions.decrementDownloadList({ reportId: id }));
  } catch (error) {
    console.log(error);
  } finally {
    dispatch(
      actions.isDownloadReportLoading({ isDownloadReportLoading: false })
    );
  }
};
