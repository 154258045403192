import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "../../atoms/text";
import {
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Chip,
  Slider,
  Box,
  Grid,
  FormControl,
} from "@mui/material";
import { Col } from "components/atoms/col";
import AddIcon from "@mui/icons-material/Add";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

interface Props {
  params: { id: string; name: string; weight: number }[];
  setParams: (p: { id: string; name: string; weight: number }[]) => void;
}

const AddParamsForm: FunctionComponent<Props> = ({ params, setParams }) => {
  const { t } = useTranslation();
  const [param, setParam] = useState("");
  const [manualResearchParamsMode, setManualResearchParamsMode] =
    useState(false);

  const handleAddParam = () => {
    if (param.length > 0) {
      const newParam = {
        id: param + "-" + params?.length ? params?.length.toString() : "0",
        name: param,
        weight: params?.length
          ? params.length < 4
            ? 100 - params.length * 25
            : 25
          : 100,
      };
      setParams([...params, newParam]);
      setParam("");
    }
  };

  const removeParam = (param: {
    id: string;
    name: string;
    weight?: number;
  }) => {
    const updatedParams = params.filter((p) => p !== param);
    setParams(updatedParams);
  };

  const updateParamWeight = (
    event: Event,
    param: { id: string; name: string; weight: number },
    newWeight: number | number[]
  ) => {
    if (typeof newWeight === "number") {
      const updatedParams = params.map((p) =>
        p.id === param.id ? { ...p, weight: newWeight } : p
      );
      setParams(updatedParams);
    }
  };

  return (
    <>
      <Text fontSize="14px" fontWeight="700">
        {t("new-research.stepper2.input_label")}
      </Text>
      <FormControl fullWidth>
        <TextField
          label={t("new-research.stepper2.input_placeholder")}
          value={param}
          onChange={(e) => setParam(e.target.value)}
          fullWidth
          variant="standard"
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleAddParam();
            }
          }}
          InputLabelProps={{
            style: {
              width: "80%",
            },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={handleAddParam}
                  onDoubleClick={handleAddParam}
                  aria-label="Add"
                >
                  <AddIcon color="info" />
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
      </FormControl>

      <Grid container mt={"20px"}>
        {params.map((param, index) => (
          <Grid
            key={index}
            item
            sx={{
              borderStyle: "solid",
              borderColor: `rgba(227, 29, 137, ${param.weight / 100})`,
              borderWidth: "1px",
              borderRadius: "5px",
              margin: "8px",
            }}
            width={manualResearchParamsMode ? "100%" : "unset"}
            padding={2}
          >
            <Grid
              container
              display={"flex"}
              alignItems={"center"}
              justifyContent={"space-between"}
            >
              <Grid item xs={manualResearchParamsMode ? 1 : 9}>
                <Text
                  fontSize={"13px"}
                  fontWeight={"700"}
                  sx={{
                    color: "#1D2C54",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {param.name}
                </Text>
              </Grid>
              {manualResearchParamsMode && (
                <Grid
                  item
                  xs={9}
                  display={"flex"}
                  flexDirection={"column"}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Slider
                    size="small"
                    color="primary"
                    value={param.weight}
                    onChange={(event, newValue) =>
                      updateParamWeight(event, param, newValue)
                    }
                    min={1}
                    max={100}
                    step={1}
                    style={{ width: "100%", paddingBottom: "2px" }}
                  />
                  {param.weight}/100
                </Grid>
              )}
              <Grid
                item
                xs={manualResearchParamsMode ? 1 : 3}
                display={"flex"}
                alignItems={"center"}
                justifyContent={"flex-end"}
              >
                <IconButton
                  onClick={() => removeParam(param)}
                  style={{ padding: 0 }}
                >
                  <CloseRoundedIcon sx={{ color: "#1D2C54", width: "16px" }} />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        ))}
      </Grid>

      <FormControlLabel
        sx={{ marginTop: "20px" }}
        control={
          <Checkbox
            checked={manualResearchParamsMode}
            onChange={() =>
              setManualResearchParamsMode(!manualResearchParamsMode)
            }
          />
        }
        label={t("new-research.stepper2.checkbox_label")}
      />
    </>
  );
};

export default AddParamsForm;
