import { FunctionComponent, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  Alert,
  FormControl,
  Grid,
  Link,
  MenuItem,
  Paper,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Text } from "components/atoms/text";
import { Url } from "core/types/url.types";
import { urlAsync, urlSelectors } from "core/store/modules/url";
import { LoadingOverlay } from "components/atoms/loading-overlay";
import {
  practiceActions,
  practiceAsync,
  practiceSelectors,
} from "core/store/modules/practice";
import { LegalPracticeRequest } from "core/types/practice.types";
import i18n from "i18n";
import { customPink } from "core/theme/variants";
import { UploadDomain } from "core/types/domain.types";
import {
  domainActions,
  domainAsync,
  domainSelectors,
} from "core/store/modules/domain";
import LoadingButton from "@mui/lab/LoadingButton";

interface OwnProps {}
interface StateProps {
  isDomainLoading: boolean;
  isPracticeLoading: boolean;
  isUrlLoading: boolean;
  urlDetail?: Url;
  practiceRequest?: LegalPracticeRequest[];
  practiceError?: string;
  practiceSuccess?: string;
  domainSuccess?: string;
  domainError?: string;
}
interface DispatchProps {
  getUrlDetail: (url_id: string) => void;
  getPracticeRequestsByUrlId: (url_id: string) => void;
  sendRequest: (requestType: number, urls: number[]) => void;
  resetPracticeError: () => void;
  resetPracticeSuccess: () => void;
  updateDomain: (data: UploadDomain) => void;
  resetDomainSuccess: () => void;
  resetDomainError: () => void;
  changeState: (urlId: number, status: string) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const UrlDetail: FunctionComponent<Props> = ({
  isDomainLoading,
  isPracticeLoading,
  isUrlLoading,
  urlDetail,
  practiceRequest,
  practiceError,
  practiceSuccess,
  domainError,
  domainSuccess,
  getUrlDetail,
  getPracticeRequestsByUrlId,
  sendRequest,
  resetPracticeError,
  resetPracticeSuccess,
  updateDomain,
  resetDomainSuccess,
  resetDomainError,
  changeState,
}) => {
  const linguaCorrente = i18n.language;
  const { url_id } = useParams();
  const { t } = useTranslation();
  const [orderedPracticesRequest, setOrderedPracticesRequest] =
    useState<LegalPracticeRequest[]>();
  const [status, setStatus] = useState("online");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [pec, setPec] = useState("");
  const [domain, setDomain] = useState("");

  const [openError, setOpenError] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [messageError, setMessageError] = useState("");
  const [messageSuccess, setMessageSuccess] = useState("");

  useEffect(() => {
    if (url_id) {
      getUrlDetail(url_id);
      getPracticeRequestsByUrlId(url_id);
    }
  }, [url_id]);

  useEffect(() => {
    if (urlDetail) {
      setName(urlDetail.domain.name);
      setEmail(urlDetail.domain.email ?? "");
      setPec(urlDetail.domain.pec ?? "");
      setDomain(urlDetail.domain.domain);
      setStatus(urlDetail.status);
    }
  }, [urlDetail]);

  useEffect(() => {
    if (practiceRequest) {
      setOrderedPracticesRequest(
        practiceRequest?.sort(
          (a, b) =>
            new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime()
        )
      );
    }
  }, [practiceRequest]);

  const handleSendAgain = (requestType: number, urls: number[]) => {
    sendRequest(requestType, urls);
  };

  useEffect(() => {
    if (practiceError) {
      setOpenError(true);
      setMessageError(practiceError);
      resetPracticeError();
    }
  }, [practiceError]);

  useEffect(() => {
    if (practiceSuccess) {
      setOpenSuccess(true);
      setMessageSuccess(practiceSuccess);
      resetPracticeSuccess();
    }
  }, [practiceSuccess]);

  useEffect(() => {
    if (domainError) {
      setOpenError(true);
      setMessageError(domainError);
      resetDomainError();
    }
  }, [domainError]);

  useEffect(() => {
    if (domainSuccess) {
      setOpenSuccess(true);
      setMessageSuccess(domainSuccess);
      resetDomainSuccess();
      url_id && getUrlDetail(url_id);
    }
  }, [domainSuccess]);

  const handleCloseError = (
    _?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenError(false);
  };

  const handleCloseSuccess = (
    _?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setOpenSuccess(false);
  };

  const handleUpdate = () => {
    if (urlDetail) {
      if (status !== urlDetail.status) {
        changeState(urlDetail.id, status);
      }
      if (
        (email && email !== urlDetail.domain.email) ||
        (pec && pec !== urlDetail.domain.pec) ||
        (name && name !== urlDetail.domain.name) ||
        domain !== urlDetail.domain.domain
      ) {
        updateDomain({
          id: urlDetail?.domain.id,
          domain: domain ?? "",
          name: name ?? "",
          email: email ?? "",
          pec: pec ?? "",
        });
      }
    }
  };

  const handleDisabled = () => {
    if (urlDetail) {
      if (status === urlDetail.status) {
        if (!email || email === urlDetail.domain.email) {
          return true;
        }
        if (!pec || pec === urlDetail.domain.pec) {
          return true;
        }
        return false;
      } else {
        return false;
      }
    } else {
      return true;
    }
  };

  return (
    <>
      {(isUrlLoading || isPracticeLoading) && (
        <LoadingOverlay open={isUrlLoading || isPracticeLoading} />
      )}
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper
            sx={{
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Text fontSize={"24px"} fontWeight={"700"} mb={"20px"}>
              {t("url-detail.form.title")}
            </Text>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <FormControl fullWidth sx={{ marginBottom: "26px" }}>
                  <TextField
                    variant="standard"
                    size="small"
                    value={urlDetail?.url}
                    placeholder={t("url-detail.form.url")}
                    InputLabelProps={{
                      style: {
                        width: "inherit",
                        fontSize: "14px !important",
                        fontWeight: "700",
                      },
                    }}
                    fullWidth
                    disabled
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "26px" }}>
                  <TextField
                    label={t("url-detail.form.name")}
                    variant="standard"
                    size="small"
                    value={name}
                    onChange={(e) => {
                      setName(e.target.value);
                    }}
                    InputLabelProps={{
                      style: {
                        width: "inherit",
                        fontSize: "14px !important",
                        fontWeight: "700",
                      },
                    }}
                    fullWidth
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "26px" }}>
                  <TextField
                    label={t("url-detail.form.domain")}
                    variant="standard"
                    size="small"
                    value={domain}
                    InputLabelProps={{
                      style: {
                        width: "inherit",
                        fontSize: "14px !important",
                        fontWeight: "700",
                      },
                    }}
                    onChange={(e) => {
                      setDomain(e.target.value);
                    }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <Grid item xs={6}>
                <FormControl
                  fullWidth
                  sx={{ marginBottom: "26px" }}
                  size="small"
                >
                  <Select
                    placeholder={t("url-detail.form.status")}
                    variant="standard"
                    value={status}
                    onChange={(event) => {
                      setStatus(event.target.value);
                    }}
                  >
                    <MenuItem value={"online"}>
                      {t("practices.detail.modal.online")}
                    </MenuItem>
                    <MenuItem value={"pending"}>
                      {t("practices.detail.modal.pending")}
                    </MenuItem>
                    <MenuItem value={"removed"}>
                      {t("practices.detail.modal.removed")}
                    </MenuItem>
                  </Select>
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "26px" }}>
                  <TextField
                    label={t("url-detail.form.email")}
                    variant="standard"
                    size="small"
                    value={email}
                    InputLabelProps={{
                      style: {
                        width: "inherit",
                        fontSize: "14px !important",
                        fontWeight: "700",
                      },
                    }}
                    onChange={(e) => {
                      setEmail(e.target.value);
                    }}
                    fullWidth
                  />
                </FormControl>

                <FormControl fullWidth sx={{ marginBottom: "26px" }}>
                  <TextField
                    label={t("url-detail.form.pec")}
                    variant="standard"
                    size="small"
                    value={pec}
                    InputLabelProps={{
                      style: {
                        width: "inherit",
                        fontSize: "14px !important",
                        fontWeight: "700",
                      },
                    }}
                    onChange={(e) => {
                      setPec(e.target.value);
                    }}
                    fullWidth
                  />
                </FormControl>
              </Grid>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  width: "100%",
                }}
              >
                <LoadingButton
                  variant="contained"
                  loading={isDomainLoading}
                  onClick={() => handleUpdate()}
                  disabled={handleDisabled()}
                >
                  {t("url-detail.form.button")}
                </LoadingButton>
              </div>
            </Grid>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Paper
            sx={{
              borderRadius: "10px",
              padding: "20px",
            }}
          >
            <Text fontSize={"24px"} fontWeight={"700"}>
              {t("url-detail.table.title")}
            </Text>
            <TableContainer
              component={Paper}
              style={{
                position: "relative",
                borderRadius: "8px",
                maxHeight: "500px",
              }}
            >
              <Table stickyHeader>
                <TableHead>
                  <TableRow>
                    <TableCell>{t("url-detail.table.date")}</TableCell>
                    <TableCell>{t("url-detail.table.type")}</TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody
                  sx={{
                    "& .MuiTableCell-root": {
                      padding: "10px",
                      fontSize: "14px",
                    },
                  }}
                >
                  {orderedPracticesRequest?.map((item, idx) => (
                    <TableRow key={idx}>
                      <TableCell>
                        {new Date(item.createdAt).toLocaleDateString(
                          linguaCorrente === "en" ? "en-GB" : "it-IT",
                          { year: "numeric", month: "long", day: "numeric" }
                        )}
                      </TableCell>
                      <TableCell>{item.requestType}</TableCell>
                      <TableCell>
                        <Link
                          color={"primary"}
                          sx={{
                            "&:hover": {
                              color: customPink[400],
                            },
                          }}
                          onClick={() =>
                            handleSendAgain(item.requestType, [item.urlId])
                          }
                        >
                          {t("url-detail.table.send-again")}
                        </Link>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>
        </Grid>
      </Grid>
      <Snackbar
        open={openError}
        autoHideDuration={6000}
        onClose={handleCloseError}
      >
        <Alert
          onClose={handleCloseError}
          severity="error"
          sx={{ width: "100%", borderRadius: "10px" }}
        >
          {messageError}
        </Alert>
      </Snackbar>

      <Snackbar
        open={openSuccess}
        autoHideDuration={6000}
        onClose={handleCloseSuccess}
      >
        <Alert
          onClose={handleCloseSuccess}
          severity="success"
          sx={{ width: "100%", borderRadius: "10px" }}
        >
          {messageSuccess}
        </Alert>
      </Snackbar>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    isDomainLoading: domainSelectors.isDomainLoading(state),
    isPracticeLoading: practiceSelectors.isPracticeLoading(state),
    isUrlLoading: urlSelectors.isUrlLoading(state),
    urlDetail: urlSelectors.getUrlDetail(state),
    practiceRequest: practiceSelectors.getPracticeRequest(state),
    practiceError: practiceSelectors.getPracticeError(state),
    practiceSuccess: practiceSelectors.getPracticeSuccess(state),
    domainError: domainSelectors.getDomainError(state),
    domainSuccess: domainSelectors.getDomainSuccess(state),
  }),
  (dispatch: Dispatch) => ({
    getUrlDetail: (urlId: string) => dispatch(urlAsync.getUrlDetail(urlId)),
    getPracticeRequestsByUrlId: (urlId: string) =>
      dispatch(practiceAsync.getPracticeRequestsByUrlId(urlId)),
    sendRequest: (requestType: number, urls: number[]) =>
      dispatch(practiceAsync.sendLegalPracticeRequest(requestType, urls)),
    resetPracticeError: () => dispatch(practiceActions.error({ error: "" })),
    resetPracticeSuccess: () =>
      dispatch(practiceActions.success({ success: "" })),
    updateDomain: (data: UploadDomain) =>
      dispatch(domainAsync.updateDomain(data)),
    resetDomainSuccess: () => dispatch(domainActions.success({ success: "" })),
    resetDomainError: () => dispatch(domainActions.error({ error: "" })),
    changeState: (urlId: number, status: string) =>
      dispatch(urlAsync.changeState(urlId, status)),
  })
)(UrlDetail);
