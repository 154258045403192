import {
  Box,
  CircularProgress,
  Grid,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18next";
import "dayjs/locale/it";
import "dayjs/locale/en";
import { ResearchListManual, Source } from "core/types/research.types";
import { Text } from "components/atoms/text";
import { format, parseISO } from "date-fns";
import { enGB, it } from "date-fns/locale";
import { Chart } from "components/molecules/chart";
import { ResearchTable } from "components/organisms/research-table";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  Title,
} from "chart.js";

ChartJS.register(LinearScale, CategoryScale, BarElement, Title);

interface OwnProps {
  selectedResearch: string;
  isResearchLoading: boolean;
  researchDetail: ResearchListManual;
  onGenerateReportButton: () => void;
  selectedVideos: string[];
  setSelectedVideos?: (videos: string[]) => void;
}
export type Props = OwnProps;

const ResearchManualDetail: FunctionComponent<Props> = ({
  selectedResearch,
  isResearchLoading,
  researchDetail,
  onGenerateReportButton,
  selectedVideos,
  setSelectedVideos,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const [sourceList, setSourceList] = useState<Source[]>([]);

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setSourceList(getOriginSources());
  }, [researchDetail]);

  const getOriginSources = () => {
    const sourceMap = new Map<string, Source>();

    if (researchDetail.results) {
      researchDetail.results?.forEach((ricerca) => {
        const sourceKey = ricerca.source.toLowerCase();
        if (!sourceMap.has(sourceKey)) {
          sourceMap.set(sourceKey, {
            source:
              ricerca.source.charAt(0).toUpperCase() + ricerca.source.slice(1), // Capitalize the source name
            value: 1,
            impact: 0,
          });
        } else {
          const sourceData = sourceMap.get(sourceKey);
          sourceData && sourceData.value++;
        }
      });

      const resultList = Array.from(sourceMap.values());

      const totalValues = resultList.reduce((acc, item) => acc + item.value, 0);

      resultList.forEach((item) => {
        item.impact = Math.round((item.value / totalValues) * 100);
      });

      return resultList;
    }
    return [];
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {isResearchLoading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress color="secondary" size={"100px"} />
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              flexDirection={"column"}
              justifyContent={"space-between"}
            >
              <Box>
                <Text>
                  {t("research_detail.code")} <b>{selectedResearch}</b>
                </Text>
                <Text>
                  {t("research_detail.date_hour")}{" "}
                  <b>
                    {format(
                      parseISO(
                        researchDetail.creationDate?.replace(" ", "T") + "Z"
                      ),
                      "dd/MM/yyyy  HH:mm",
                      {
                        locale: linguaCorrente === "it" ? it : enGB,
                      }
                    )}
                  </b>
                </Text>
              </Box>

              <Box>
                <Typography variant="body1">
                  <b>{t("my_research.table.published_from")}:</b>{" "}
                  {researchDetail.payload.dateFromWhichToResearch
                    ? format(
                        new Date(
                          researchDetail.payload.dateFromWhichToResearch
                        ),
                        "dd MMM yyyy",
                        {
                          locale: linguaCorrente === "it" ? it : enGB,
                        }
                      )
                    : "-"}
                </Typography>
                <Typography variant="body1">
                  <b>{t("my_research.table.min_length")}:</b>{" "}
                  {researchDetail.payload.minDuration}
                </Typography>
                <Typography variant="body1" display={"flex"}>
                  <b style={{ marginRight: "5px" }}>
                    {t("my_research.table.params")}:
                  </b>{" "}
                  {researchDetail.payload.params
                    ?.sort((a, b) => b.weight - a.weight)
                    .map((p, index) => (
                      <Tooltip
                        key={index}
                        title={t("my_research.impact") + p.weight + "/100"}
                      >
                        <Typography>
                          {p.name}
                          {researchDetail.payload.params?.length &&
                          researchDetail.payload.params?.length - 1 !== index
                            ? ", "
                            : ""}
                        </Typography>
                      </Tooltip>
                    ))}
                </Typography>
              </Box>

              <Box>
                <Typography variant="body1">
                  <b>{t("my_research.total")}</b>{" "}
                  {researchDetail.results?.length}
                </Typography>
              </Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Chart list={sourceList} showLegend />
            </Grid>
          </Grid>
        )}
      </Paper>
      {researchDetail.results && (
        <Paper
          sx={{
            borderRadius: "10px",
            mt: "20px",
            padding: "20px",
          }}
        >
          <ResearchTable
            research={{
              id: Number(selectedResearch),
              results: researchDetail.results,
            }}
            selectedVideos={selectedVideos}
            setSelectedVideos={setSelectedVideos}
            onGenerateReportButton={onGenerateReportButton}
            detailMode
            style={{
              maxHeight: "460px",
            }}
          />
        </Paper>
      )}
    </>
  );
};

export default ResearchManualDetail;
