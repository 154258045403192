import { RootState } from "../../store.types";
import { State } from "./url.types";

export const getCurrentState = (state: RootState): State => state.url;

export const isUrlLoading = (state: RootState) =>
  getCurrentState(state).isUrlLoading;

export const getUrlList = (state: RootState) => getCurrentState(state).urlList;

export const getUrlSuccess = (state: RootState) =>
  getCurrentState(state).success;

export const getUrlError = (state: RootState) => getCurrentState(state).error;

export const getUrlDetail = (state: RootState) =>
  getCurrentState(state).urlDetail;
