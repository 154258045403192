import { actionCreatorFactory } from "typescript-fsa";
import { ExchangeRate, Plan, Product } from "../../../types/product.types";

const actionCreator = actionCreatorFactory("auth");

export const resetProduct = actionCreator<void>("RESET");

export const productList = actionCreator<{ productList: Product[] }>(
  "PRODUCT_LIST"
);

export const planList = actionCreator<{ planList: Plan[] }>(
  "PLAN_LIST"
);

export const isProductLoading = actionCreator<{ isProductLoading: boolean }>(
  "IS_PRODUCT_LOADING"
);

export const exchangeRate = actionCreator<{ exchangeRate: ExchangeRate }>(
  "EXCHANGE_RATE"
);
