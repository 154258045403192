import { reducerWithInitialState } from "typescript-fsa-reducers";
import { State } from "./research.types";
import * as actions from "./research.actions";

export const initialState: Readonly<State> = {
  research: undefined,
  isResearchLoading: false,
  researchList: [],
  researchDetail: undefined,
  researchManualDetail: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetResearch, () => ({ ...initialState }))
  .case(actions.researchSuccess, (state, { research }) => ({
    ...state,
    research,
  }))
  .case(actions.isResearchLoading, (state, { isResearchLoading }) => ({
    ...state,
    isResearchLoading,
  }))
  .case(actions.researchList, (state, { researchList }) => ({
    ...state,
    researchList,
  }))
  .case(actions.researchDetail, (state, { researchDetail }) => ({
    ...state,
    researchDetail,
  }))
  .case(actions.researchManualDetail, (state, { researchManualDetail }) => ({
    ...state,
    researchManualDetail,
  }))
  .build();
