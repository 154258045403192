import { practiceApi } from "core/api";
import { Dispatch } from "../../store.types";
import * as actions from "./practice.actions";

export const newPractice =
  (reportId?: number) => async (dispatch: Dispatch) => {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
    try {
      if (reportId) {
        await practiceApi.newPractice(reportId);
      }
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
    }
  };

export const getPractices = () => async (dispatch: Dispatch) => {
  dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
  try {
    const practiceList = await practiceApi.getPractices();
    dispatch(actions.practicesList({ practiceList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
  }
};

export const getAdminPractices = () => async (dispatch: Dispatch) => {
  dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
  try {
    const practiceList = await practiceApi.getAdminPractices();
    dispatch(actions.practicesList({ practiceList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
  }
};

export const getPracticeDetail = (id: number) => async (dispatch: Dispatch) => {
  dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
  try {
    const practiceDetail = await practiceApi.getPracticeDetail(id.toString());
    if (practiceDetail) {
      dispatch(actions.practiceDetail({ practiceDetail: practiceDetail }));
    }
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
  }
};

export const sendLegalPracticeRequest =
  (requestType: number, urls: number[]) => async (dispatch: Dispatch) => {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
    try {
      const response = await practiceApi.sendRequests({
        request_type: requestType,
        urls,
      });
      if (response[0]?.errors) {
        dispatch(actions.error({ error: response[0].message }));
      } else {
        dispatch(actions.success({ success: "Request successfully sent" }));
      }
    } catch (error: any) {
      console.log(error);
    } finally {
      dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
    }
  };

export const getPracticeRequestsByUrlId =
  (urlId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isPracticeLoading({ isPracticeLoading: true }));
    try {
      const practiceRequest = await practiceApi.getRequestsByUrlId(urlId);
      dispatch(actions.practiceRequest({ practiceRequest }));
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(actions.isPracticeLoading({ isPracticeLoading: false }));
    }
  };
