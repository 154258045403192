import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "i18n";
import { useNavigate } from "react-router-dom";
import { Domain } from "core/types/domain.types";

export type TableData = Domain & { editMode: boolean };

interface Props {
  isDomainLoading: boolean;
  domainList?: Domain[];
  onUpdateDomain: (domain: Domain) => void;
}

const DomainsListTable: FunctionComponent<Props> = ({
  isDomainLoading,
  domainList,
  onUpdateDomain,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const [editedDomains, setEditedDomains] = useState<TableData[]>([]);

  useEffect(() => {
    if (domainList && domainList.length > 0) {
      const data: TableData[] = domainList?.map((r: Domain) => ({
        ...r,
        editMode: false,
      }));
      setEditedDomains(data);
    }
  }, [domainList]);

  const handleFieldChange = (id: number, field: string, value: string) => {
    const updatedDomainList = editedDomains.map((domain) => {
      if (domain.id === id) {
        return {
          ...domain,
          [field]: value,
        };
      }
      return domain;
    });

    setEditedDomains(updatedDomainList);
  };

  const handleEditClick = (id: number) => {
    const updatedDomainList = editedDomains.map((domain) =>
      domain.id === id ? { ...domain, editMode: true } : domain
    );
    setEditedDomains(updatedDomainList);
  };

  const handleSaveClick = (id: number) => {
    const updatedDomainList = editedDomains.map((domain) =>
      domain.id === id ? { ...domain, editMode: false } : domain
    );
    setEditedDomains(updatedDomainList);
    const updatedDomain = editedDomains.find((domain) => domain.id === id);
    if (updatedDomain) {
      onUpdateDomain(updatedDomain);
    }
  };

  const handleCancelClick = (id: number) => {
    const domainToCancel = domainList?.find((domain) => domain.id === id);

    if (domainToCancel) {
      const updatedEditedDomains = editedDomains.map((domain) =>
        domain.id === id ? { ...domainToCancel, editMode: false } : domain
      );
      setEditedDomains(updatedEditedDomains);
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      {isDomainLoading ? (
        <div
          style={{ width: "100%", display: "flex", justifyContent: "center" }}
        >
          <CircularProgress color="secondary" size={"100px"} />
        </div>
      ) : (
        <TableContainer
          component={Paper}
          style={{
            position: "relative",
            borderRadius: "8px",
            maxHeight: "500px",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>{t("domains.table.domain")}</TableCell>
                <TableCell>{t("domains.table.name")}</TableCell>
                <TableCell>{t("domains.table.email")}</TableCell>
                <TableCell>{t("domains.table.pec")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {editedDomains?.map((domain) => (
                <TableRow key={domain.id}>
                  <TableCell>{domain.domain}</TableCell>
                  <TableCell>
                    {domain.editMode ? (
                      <TextField
                        type="text"
                        size="small"
                        variant="standard"
                        value={domain.name}
                        onChange={(e) =>
                          handleFieldChange(domain.id, "name", e.target.value)
                        }
                      />
                    ) : (
                      domain.name
                    )}
                  </TableCell>
                  <TableCell>
                    {domain.editMode ? (
                      <TextField
                        type="text"
                        size="small"
                        variant="standard"
                        value={domain.email}
                        onChange={(e) =>
                          handleFieldChange(domain.id, "email", e.target.value)
                        }
                      />
                    ) : (
                      domain.email
                    )}
                  </TableCell>
                  <TableCell>
                    {domain.editMode ? (
                      <TextField
                        type="text"
                        size="small"
                        variant="standard"
                        value={domain.pec}
                        onChange={(e) =>
                          handleFieldChange(domain.id, "pec", e.target.value)
                        }
                      />
                    ) : (
                      domain.pec
                    )}
                  </TableCell>
                  <TableCell sx={{ minWidth: "200px" }}>
                    {domain.editMode ? (
                      <Grid container spacing={3}>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={() => handleSaveClick(domain.id)}
                          >
                            {t("domains.table.save_button")}
                          </Button>
                        </Grid>
                        <Grid item xs={6}>
                          <Button
                            variant="contained"
                            onClick={() => handleCancelClick(domain.id)}
                          >
                            {t("domains.table.undo_button")}
                          </Button>
                        </Grid>
                      </Grid>
                    ) : (
                      <Button
                        variant="contained"
                        onClick={() => handleEditClick(domain.id)}
                      >
                        {t("domains.table.edit_button")}
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </div>
  );
};

export default DomainsListTable;
