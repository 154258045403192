import { ApiFactoryClients } from "../api.types";
import { Domain, UploadDomain } from "core/types/domain.types";
import { transformDomain } from "./domain.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getDomains: (): Promise<Domain[]> =>
    codeHttpClient
      .get("/admin/domains")
      .then(({ data }) => data.map((d: any) => transformDomain(d))),
  updateDomain: (body: UploadDomain) =>
    codeHttpClient.put("/admin/domains", body),
});
