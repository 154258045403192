import { Button, ButtonGroup, useTheme } from "@mui/material";
import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";

interface PlanButtonGroupProps {
  actualPlan: number;
  setActualPlan: (n: number) => void;
  onClick: () => void;
}

const PlanButtonGroup = ({
  actualPlan,
  setActualPlan,
  onClick,
}: PlanButtonGroupProps) => {
  const { t } = useTranslation();
  const buttonRefs = useRef([
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
    useRef<HTMLButtonElement>(null),
  ]);
  const [width, setWidth] = useState<number>(0);
  const [sliderLeft, setSliderLeft] = useState<number>(0);
  const theme = useTheme();

  const sliderStyle: CSSProperties = {
    backgroundColor: theme.palette.primary.main, // Colore di sfondo per l'effetto slider
    borderRadius:
      actualPlan === 0
        ? "8px 0px 0px 8px"
        : actualPlan === 2
        ? "0px 8px 8px 0px"
        : "0px",
    position: "absolute",
    transition: "all 0.3s ease",
    // width: "33.3333%",
    height: "100%",
    left: `${sliderLeft}px`,
  };

  const handleSelect = (index: number) => {
    if (actualPlan !== index) {
      setTimeout(() => {
        setActualPlan(index);
      }, 270);
      onClick();
    }
  };

  const updateSlider = () => {
    const currentButtonRef = buttonRefs.current[actualPlan].current;
    if (currentButtonRef) {
      setWidth(currentButtonRef.clientWidth);
      setSliderLeft(currentButtonRef.offsetLeft);
    }
  };

  useEffect(() => {
    updateSlider();
    window.addEventListener("resize", updateSlider);
    return () => window.removeEventListener("resize", updateSlider);
  }, [actualPlan]);

  return (
    <ButtonGroup
      variant="outlined"
      color="primary"
      sx={{
        position: "relative",
        "& .MuiButtonGroup-grouped:not(:first-of-type):not(:last-of-type)": {
          borderRight: "0px",
          borderLeft: "0px",
          borderLeftColor: "transparent",
          borderRightColor: "transparent",
        },
        "& .MuiButtonGroup-grouped:not(:first-of-type)": {
          borderLeft: "0px",
          borderLeftColor: "transparent",
        },
        "& .MuiButtonGroup-grouped:not(:last-of-type)": {
          borderRight: "0px",
          borderRightColor: "transparent",
        },
      }}
    >
      <div style={{ ...sliderStyle, width: `${width + 1}px` }} />
      {[0, 1, 2].map((plan, index) => (
        <Button
          ref={buttonRefs.current[index]}
          key={plan}
          variant="outlined"
          onClick={() => handleSelect(index)}
          sx={{
            zIndex: 1,
            color: actualPlan === plan ? "white" : theme.palette.primary.main,
            borderColor: theme.palette.primary.main,
            "&:hover": {
              background: "#ffffff33",
            },
          }}
        >
          {t("profile.plan-" + plan)}
        </Button>
      ))}
    </ButtonGroup>
  );
};

export default PlanButtonGroup;
