import merge from "deepmerge";
import { green, grey } from "@mui/material/colors";
import { THEMES } from "core/constants/theme";

export const customPink = {
  50: "#fee9f5",
  100: "#fdbade",
  200: "#fc98cf",
  300: "#fb6ab9",
  400: "#fa4dab",
  500: "#f92096",
  600: "#e31d89",
  700: "#b1176b",
  800: "#891253",
  900: "#690d3f",
};

const defaultVariant = {
  name: THEMES.DEFAULT,
  palette: {
    mode: "light",
    primary: {
      main: customPink[500],
      contrastText: "#FFF",
    },
    secondary: {
      main: customPink[100],
      contrastText: "#FFF",
    },
    info: {
      main: "#000",
    },
    background: {
      default: "#E8EAEE",
      paper: "#FFF",
    },
    success: {
      main: "#99F279",
    },
    error: {
      main: "#F26188",
    },
    warning: {
      main: "#F2CB61",
    },
  },
  header: {
    color: grey[500],
    background: "#E8EAEE",
    search: {
      color: grey[800],
    },
    indicator: {
      background: customPink[600],
    },
  },
  footer: {
    color: grey[500],
    background: "#FFF",
  },
  sidebar: {
    color: grey[200],
    background: "#1B2430",
    header: {
      color: grey[200],
      background: "#232F3E",
      brand: {
        color: customPink[500],
      },
    },
    footer: {
      color: grey[200],
      background: "#232F3E",
      online: {
        background: green[500],
      },
    },
    badge: {
      color: "#FFF",
      background: customPink[500],
    },
  },
};

const variants: Array<VariantType> = [defaultVariant];

export default variants;

export type VariantType = {
  name: string;
  palette: {
    primary: MainContrastTextType;
    secondary: MainContrastTextType;
  };
  header: ColorBgType & {
    search: {
      color: string;
    };
    indicator: {
      background: string;
    };
  };
  footer: ColorBgType;
  sidebar: ColorBgType & {
    header: ColorBgType & {
      brand: {
        color: string;
      };
    };
    footer: ColorBgType & {
      online: {
        background: string;
      };
    };
    badge: ColorBgType;
  };
};

type MainContrastTextType = {
  main: string;
  contrastText: string;
};
type ColorBgType = {
  color: string;
  background: string;
};
