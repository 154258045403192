import { Button as MuiButton, IconButton, Tooltip } from "@mui/material";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { spacing } from "@mui/system";
import styled from "@emotion/styled";
import { NavbarNotificationsDropdown } from "components/atoms/navbar-notifications-dropdown";
import { NavbarLanguagesDropdown } from "components/atoms/navbar-languages-dropdown";
import { Info } from "react-feather";
import { NavbarUserDropdown } from "components/atoms/navbar-user-dropdown";
import { useLocation, useNavigate } from "react-router-dom";
import { DownloadItem } from "core/types/report.types";
import { User } from "core/types/user.types";

const Button = styled(MuiButton)(spacing);

interface Props {
  user?: User;
  downloads?: DownloadItem[];
  onButtonClick: () => void;
  onSignOut: () => void;
  onRemoveDownloadNotification: (reportID: number) => void;
}

const HeaderActions: FunctionComponent<Props> = ({
  user,
  downloads,
  onSignOut,
  onButtonClick,
  onRemoveDownloadNotification,
}) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <>
      {user && location.pathname !== "/my-profile" && (
        <Button
          style={{ marginRight: 4 }}
          variant="outlined"
          color="info"
          onClick={() => navigate("/my-profile")}
        >
          {user.billing_info?.searches_number + t("header.remaining")}
        </Button>
      )}
      {location.pathname !== "/" && (
        <Button
          style={{ marginRight: 4 }}
          variant="contained"
          color="primary"
          onClick={onButtonClick}
          disabled={user?.billing_info?.searches_number === 0}
        >
          {t("header.search")}
        </Button>
      )}
      <NavbarNotificationsDropdown
        downloads={downloads}
        onRemoveDownloadNotification={onRemoveDownloadNotification}
      />
      <NavbarLanguagesDropdown />
      <Tooltip title={t("header.help")}>
        <IconButton
          onClick={() =>
            window.open("https://t.me/codertd", "_blank", "noreferrer")
          }
        >
          <Info color="#1D2C54" />
        </IconButton>
      </Tooltip>
      <NavbarUserDropdown onSignOut={onSignOut} />
    </>
  );
};

export default HeaderActions;
