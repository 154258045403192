import { codeApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./auth.actions";

export const getUser = () => async (dispatch: Dispatch) => {
  dispatch(actions.isAuthLoading({ isAuthLoading: true }));
  try {
    const user = await codeApi.getUser();
    dispatch(actions.authSuccess({ user }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isAuthLoading({ isAuthLoading: false }));
  }
};

export const logout = () => async (dispatch: Dispatch) => {
  dispatch(actions.logout());
};
