import {
  CircularProgress,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { AdminTableRow } from "components/organisms/admin-table-row";
import { codeApi } from "core/api";
import { User } from "core/types/user.types";
import { FunctionComponent, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Text } from "components/atoms/text";

import "./admin.css";

export type TableData = User & { editMode: boolean };

const Admin: FunctionComponent = () => {
  const { t } = useTranslation();

  const [users, setUsers] = useState<TableData[]>();
  const [loading, setLoading] = useState(false);

  const getUsers = useCallback(async () => {
    try {
      const response = await codeApi.getUsers();
      const data: TableData[] = response.map((r) => ({
        ...r,
        editMode: false,
      }));
      setUsers(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    getUsers();
    setLoading(false);
  }, [getUsers]);

  if (loading) {
    return (
      <div
        style={{
          display: "flex",
          paddingTop: "200px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <CircularProgress thickness={8} size={"8rem"} color="secondary" />
      </div>
    );
  }

  return (
    <div style={{}}>
      <Text fontSize={"24px"} fontWeight={"700"} textAlign={"center"}>
        {t("admin.title")}
      </Text>
      <Paper
        sx={{
          width: "100%",
          overflow: "hidden",
          borderRadius: "10px",
        }}
      >
        <TableContainer>
          <Table sx={{ width: "100%" }} size="small">
            <TableHead>
              <TableRow>
                <TableCell>{t("admin.firstName")}</TableCell>
                <TableCell>{t("admin.lastName")}</TableCell>
                <TableCell>{t("admin.email")}</TableCell>
                <TableCell>{t("admin.state")}</TableCell>
                <TableCell>{t("admin.available-searches")}</TableCell>
                <TableCell>{t("admin.remaining-reports")}</TableCell>
                <TableCell>{t("admin.mantainance-months")}</TableCell>
                <TableCell>{t("admin.blockchain-enabled")}</TableCell>
                <TableCell>{t("admin.consultations-numbers")}</TableCell>
                <TableCell>{t("admin.remaining-support")}</TableCell>
                <TableCell></TableCell>
              </TableRow>
            </TableHead>
            <TableBody
              sx={{
                "& .MuiTableCell-root": {
                  padding: "10px",
                  fontSize: "14px",
                },
              }}
            >
              {users?.map((u) => (
                <AdminTableRow data={u} />
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
};

export default Admin;
