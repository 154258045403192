import { AxiosError } from "axios";
import { transactionApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./transaction.actions";

export const getTransaction =
  (sessionId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
    try {
      const transaction = await transactionApi.getTransaction(sessionId);
      dispatch(actions.transaction({ transaction }));
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
    }
  };

export const getTransactionList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
  try {
    const transactionList = await transactionApi.getTransactions();
    console.log(transactionList);
    // dispatch(actions.transaction({ transaction }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
  }
};

export const createTransaction =
  (product: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
    try {
      const checkoutSession = await transactionApi.createTransaction(product);
      dispatch(actions.checkoutSession({ checkoutSession }));
    } catch (error: any) {
      console.error(error);
      dispatch(actions.transactionError({ transactionError: error?.msg }));
    } finally {
      dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
    }
  };

export const createPlanTransaction =
  (product: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
    try {
      const checkoutSession = await transactionApi.createPlanTransaction(
        product
      );
      dispatch(actions.checkoutSession({ checkoutSession }));
    } catch (error: any) {
      console.error(error);
      dispatch(actions.transactionError({ transactionError: error?.msg }));
    } finally {
      dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
    }
  };

export const resetCheckoutSession = () => async (dispatch: Dispatch) => {
  dispatch(actions.checkoutSession({ checkoutSession: { url: "" } }));
};

export const confirmTransaction =
  (sessionId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
    try {
      await transactionApi.updateUserTransaction(sessionId);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
    }
  };

export const cancelTransaction =
  (sessionId: string) => async (dispatch: Dispatch) => {
    dispatch(actions.isTransactionLoading({ isTransactionLoading: true }));
    try {
      await transactionApi.cancelUserTransaction(sessionId);
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(actions.isTransactionLoading({ isTransactionLoading: false }));
    }
  };

export const resetError = () => async (dispatch: Dispatch) => {
  dispatch(actions.transactionError({ transactionError: "" }));
};
