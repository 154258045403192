import { useKeycloak } from "@react-keycloak/web";
import { FunctionComponent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useRoutes } from "react-router-dom";
import { codeHttpClient } from "core/http/clients/code/code.http";
import { authAsync } from "core/store/modules/auth";
import { Dispatch, RootState } from "core/store/store.types";
import { LoginPage } from "pages/login";
import routes from "routes";
import { productAsync } from "core/store/modules/product";
import { ThemeProvider as MuiThemeProvider } from "@mui/material/styles";
import useTheme from "core/hooks/useTheme";
import createTheme from "core/theme";
import { Layout } from "containers/layout";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import it from "date-fns/locale/it";
import en from "date-fns/locale/en-GB";
import { useTranslation } from "react-i18next";
import { Locale } from "date-fns";
import { Notify } from "core/types/notify.types";
import { notifyAsync, notifySelectors } from "core/store/modules/notify";
import { transactionAsync } from "core/store/modules/transaction";

type languageOptionsType = {
  [key: string]: {
    locale: Locale;
  };
};

interface OwnProps {}
interface StateProps {
  notifyList?: Notify[];
}
interface DispatchProps {
  getUser: () => void;
  getProductList: () => void;
  getPlanList: () => void;
  getExchangeRate: () => void;
  logout: () => void;
  getNotification: () => void;
  getTransactionList: () => void;
}
export type Props = OwnProps & StateProps & DispatchProps;

const App: FunctionComponent<Props> = ({
  getUser,
  getProductList,
  getPlanList,
  logout,
  notifyList,
  getNotification,
  getTransactionList,
}) => {
  const { keycloak } = useKeycloak();
  const { i18n } = useTranslation();
  const { theme } = useTheme();
  const content = useRoutes(routes);
  const languageOptions: languageOptionsType = {
    it: { locale: it },
    en: { locale: en },
  };
  const selectedLanguage = languageOptions[i18n.language] ?? {
    it: { locale: it },
  };
  const seconds = 20;

  const headerRef = useRef<HTMLDivElement>(null);

  const [height, setHeight] = useState(0);
  const [headerHeight, setHeaderHeight] = useState(
    headerRef?.current?.clientHeight
  );

  setInterval(() => {
    // getNotification();
  }, seconds * 1000);

  useEffect(() => {
    setHeaderHeight(headerRef?.current?.clientHeight);
  }, [headerRef?.current?.clientHeight]);

  useEffect(() => {
    if (keycloak.authenticated) {
      // console.log(keycloak.token);
      codeHttpClient.interceptors.request.use(
        (config) => {
          config.headers.Authorization = `Bearer ${keycloak.token}`;
          return config;
        },
        (error) => {
          return Promise.reject(error);
        }
      );
    }
  }, [keycloak.authenticated, keycloak.token]);

  useEffect(() => {
    if (keycloak.authenticated) {
      getUser();
      getProductList();
      getPlanList();
    }
  }, [keycloak.authenticated, getUser]);

  const handleLogout = () => {
    logout();
    keycloak.logout();
  };

  if (keycloak.authenticated) {
    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        {/* @ts-ignore */}
        <LocalizationProvider
          dateAdapter={AdapterDateFns}
          adapterLocale={selectedLanguage.locale}
        >
          <Layout handleLogout={handleLogout}>{content}</Layout>
        </LocalizationProvider>
      </MuiThemeProvider>
    );
  } else {
    return (
      <MuiThemeProvider theme={createTheme(theme)}>
        <LoginPage />
      </MuiThemeProvider>
    );
  }
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    notifyList: notifySelectors.getNotifyList(state),
  }),
  (dispatch: Dispatch) => ({
    getUser: () => dispatch(authAsync.getUser()),
    getProductList: () => dispatch(productAsync.getProductList()),
    getPlanList: () => dispatch(productAsync.getPlanList()),
    getExchangeRate: () => dispatch(productAsync.getExchangeRate()),
    logout: () => dispatch(authAsync.logout()),
    getNotification: () => dispatch(notifyAsync.getNotifyList()),
    getTransactionList: () => dispatch(transactionAsync.getTransactionList()),
  })
)(App);
