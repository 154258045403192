import { Button, Grid } from "@mui/material";
import { useKeycloak } from "@react-keycloak/web";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import background from "../../assets/images/bg.png";
import { ReactComponent as Logo } from "../../vendor/logo_powered.svg";

const Login: FunctionComponent = () => {
  const { keycloak } = useKeycloak();
  const { t } = useTranslation();

  const onEnterClick = (evt: React.MouseEvent<HTMLButtonElement>) => {
    evt.preventDefault();
    keycloak.login();
  };

  return (
    <>
      <img
        src={background}
        alt="bg"
        style={{
          width: "100%",
          height: "100%",
          position: "absolute",
        }}
      />
      <Grid
        container
        padding={8}
        alignItems={"center"}
        justifyContent={"center"}
        style={{ background: "#fff", height: "100vh" }}
        flexDirection={"column"}
      >
        <Logo />

        <Button
          onClick={onEnterClick}
          variant="outlined"
          color="primary"
          size="large"
          style={{ textTransform: "uppercase", padding: 8, marginTop: "20px" }}
        >
          {t("login.enter")}
        </Button>
      </Grid>
    </>
  );
};

export default Login;
