import { CheckoutSession, Transaction } from "core/types/transaction.types";
import { ApiFactoryClients } from "../api.types";
import {
  transformCheckoutSession,
  transformTransaction,
} from "./transaction.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  getTransaction: (sessionId: string): Promise<Transaction> =>
    codeHttpClient
      .get("/orders?order_id=" + sessionId)
      .then(({ data }) => transformTransaction(data)),
  getTransactions: (): Promise<Transaction[]> =>
    codeHttpClient
      .get("/orders")
      .then(({ data }) => data.map((d: any) => transformTransaction(d))),
  createTransaction: (product: string): Promise<CheckoutSession> =>
    codeHttpClient
      .post("/orders", { product_id: product })
      .then(({ data }) => transformCheckoutSession(data)),
  createPlanTransaction: (product: string): Promise<CheckoutSession> =>
    codeHttpClient
      .post("/subscriptions", { product_id: product })
      .then(({ data }) => transformCheckoutSession(data)),
  updateUserTransaction: (sessionId: string): Promise<Transaction> =>
    codeHttpClient
      .get("/transaction/success?session_id=" + sessionId)
      .then(({ data }) => data),
  cancelUserTransaction: (sessionId: string): Promise<Transaction> =>
    codeHttpClient
      .get("/transaction/success?session_id=" + sessionId)
      .then(({ data }) => data),
});
