import { RootState } from "../../store.types";
import { State } from "./practice.types";

export const getCurrentState = (state: RootState): State => state.practice;

export const isPracticeLoading = (state: RootState): boolean =>
  getCurrentState(state).isPracticeLoading;
export const getPracticesList = (state: RootState) =>
  getCurrentState(state).practicesList;
export const getPracticeDetail = (state: RootState) =>
  getCurrentState(state).practiceDetail;
export const getPracticeRequest = (state: RootState) =>
  getCurrentState(state).practiceRequest;
export const getPracticeSuccess = (state: RootState) =>
  getCurrentState(state).success;
export const getPracticeError = (state: RootState) =>
  getCurrentState(state).error;
