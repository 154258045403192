import { RootState } from "../../store.types";
import { State } from "./transaction.types";

export const getCurrentState = (state: RootState): State => state.transaction;
export const getTransaction = (state: RootState) =>
  getCurrentState(state).transaction;
export const getCheckoutSession = (state: RootState) =>
  getCurrentState(state).checkoutSession;
export const isTransactionLoading = (state: RootState) =>
  getCurrentState(state).isTransactionLoading;
export const transactionError = (state: RootState) =>
  getCurrentState(state).transactionError;
