import { Checkbox, Grid, LinearProgress, Paper, Slider } from "@mui/material";
import { Col } from "components/atoms/col";
import { Row } from "components/atoms/row";
import { Text } from "components/atoms/text";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

interface Props {
  user: User;
}

const UserStats: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();

  const checkStatus = (value: number) => {
    if (value < 33) {
      return "error";
    } else if (value >= 33 && value <= 67) {
      return "warning";
    } else if (value > 67) {
      return "success";
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      {user?.billing_info && (
        <>
          <Text variant="h4" fontWeight={"600"} mb={"8px"}>
            {t("profile.billing-title")}
          </Text>
          <Row
            container
            style={{ marginBottom: "8px", borderBottom: "0.5px solid #979EB0" }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.available-searches")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <LinearProgress
                value={Math.round(user?.billing_info?.searches_number / 10)}
                variant="determinate"
                color={checkStatus(
                  Math.round(user?.billing_info?.searches_number / 10)
                )}
                sx={{ width: "100%" }}
              />
              <Text ml={"2px"}>{user?.billing_info?.searches_number}</Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.mantainance-months")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              flexDirection={"row"}
              alignItems={"center"}
              justifyContent={"center"}
            >
              <LinearProgress
                value={Math.round(
                  user?.billing_info?.report_maintenance_months / 10
                )}
                variant="determinate"
                color={checkStatus(
                  Math.round(user?.billing_info?.report_maintenance_months / 10)
                )}
                sx={{ width: "100%" }}
              />
              <Text ml={"2px"}>
                {user?.billing_info?.report_maintenance_months}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.remaining-reports")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <LinearProgress
                value={Math.round(
                  user?.billing_info?.report_generation_number / 10
                )}
                variant="determinate"
                color={checkStatus(
                  Math.round(user?.billing_info?.report_generation_number / 10)
                )}
                sx={{ width: "100%" }}
              />
              <Text ml={"2px"}>
                {user?.billing_info?.report_generation_number}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.consultations-numbers")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <LinearProgress
                value={Math.round(
                  user?.billing_info?.expert_consultancy_number / 10
                )}
                variant="determinate"
                color={checkStatus(
                  Math.round(user?.billing_info?.expert_consultancy_number / 10)
                )}
                sx={{ width: "100%" }}
              />
              <Text ml={"2px"}>
                {user?.billing_info?.expert_consultancy_number}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.remaining-support")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"center"}
              flexDirection={"row"}
            >
              <LinearProgress
                value={Math.round(
                  user?.billing_info?.legal_support_consultancy_number / 10
                )}
                variant="determinate"
                color={checkStatus(
                  Math.round(
                    user?.billing_info?.legal_support_consultancy_number / 10
                  )
                )}
                sx={{ width: "100%" }}
              />
              <Text ml={"2px"}>
                {user?.billing_info?.legal_support_consultancy_number}
              </Text>
            </Col>
          </Row>
        </>
      )}

      <Row
        container
        style={{
          marginBottom: "8px",
          paddingBottom: "8px",
          borderBottom: "0.5px solid #979EB0",
        }}
      >
        <Col item xs={12} md={6}>
          <Text fontSize={"13px"} fontWeight={"500"}>
            {t("profile.blockchain-enabled")}
          </Text>
        </Col>
        <Col
          item
          xs={12}
          md={6}
          display={"flex"}
          alignItems={"flex-end"}
          justifyContent={"center"}
        >
          <Checkbox
            sx={{ padding: 0, borderRadius: "20px" }}
            color="success"
            checked={user?.billing_info?.blockchain_sign}
            icon={<CancelRoundedIcon />}
            checkedIcon={<CheckCircleRoundedIcon />}
          />
        </Col>
      </Row>
    </Paper>
  );
};

export default UserStats;
