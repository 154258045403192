import { Action, RootState } from "./store.types";
import { combineReducers } from "redux";

import authReducer from "./modules/auth";
import researchReducer from "./modules/research/research.reducer";
import reportReducer from "./modules/report/report.reducer";
import productReducer from "./modules/product/product.reducer";
import transactionReducer from "./modules/transaction/transaction.reducer";
import notifyReducer from "./modules/notify/notify.reducer";
import practiceReducer from "./modules/practice";
import urlReducer from "./modules/url/url.reducer";
import domainReducer from "./modules/domain/domain.reducer";
import filmReducer from "./modules/film/film.reducer";

export default combineReducers<RootState, Action>({
  auth: authReducer,
  research: researchReducer,
  report: reportReducer,
  product: productReducer,
  transaction: transactionReducer,
  notify: notifyReducer,
  practice: practiceReducer,
  url: urlReducer,
  domain: domainReducer,
  film: filmReducer,
});
