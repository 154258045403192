import { FunctionComponent } from "react";
import { Button, ButtonProps } from "@mui/material";

const SecondaryButton: FunctionComponent<ButtonProps> = ({
  children,
  ...otherProps
}) => {
  return (
    <Button
      sx={{
        background: "#ffffff30",
        padding: "7px 12px",
        fontSize: "18px",
        fontWeight: "500"
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

export default SecondaryButton;
