import { Paper } from "@mui/material";
import { ProfileCardHeader } from "components/molecules/profile-card-header";
import ProfileCardContent from "components/organisms/profile-card-content/profile-card-content";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";

interface Props {
  user: User;
}

const ProfileCard: FunctionComponent<Props> = ({ user }) => {
  return (
    <Paper
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <ProfileCardHeader user={user} />
      <ProfileCardContent user={user} />
    </Paper>
  );
};

export default ProfileCard;
