import {
  Alert,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Paper,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import { Dispatch, RootState } from "core/store/store.types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Text } from "components/atoms/text";
import { Film } from "core/types/film.types";
import { filmActions, filmAsync, filmSelectors } from "core/store/modules/film";
import { User } from "core/types/user.types";
import { authAsync, authSelectors } from "core/store/modules/auth";
import SaveIcon from "@mui/icons-material/Save";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";

interface OwnProps {}
interface StateProps {
  user?: User;
  filmList?: Film[];
  isFilmLoading: boolean;
  success?: string;
  error?: string;
}
interface DispatchProps {
  getUser: () => void;
  getFilmList: () => void;
  createNewFilm: (title: string) => void;
  editFilm: (id: string, newTitle: string) => void;
  removeFilm: (id: string) => void;
  resetSuccess: () => void;
  resetError: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const FilmPage: FunctionComponent<Props> = ({
  user,
  filmList,
  isFilmLoading,
  getUser,
  getFilmList,
  createNewFilm,
  editFilm,
  removeFilm,
  success,
  error,
  resetSuccess,
  resetError,
}) => {
  const { t } = useTranslation();
  const [data, setData] = useState<Film[]>();
  const [addRow, setAddRow] = useState(false);
  const [editRow, setEditRow] = useState(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [manipulatedId, setManipuledId] = useState<number>();
  const [editedFilm, setEditedFilm] = useState("");

  useEffect(() => {
    getUser();
    getFilmList();
  }, []);

  useEffect(() => {
    setData(filmList);
  }, [filmList]);

  useEffect(() => {
    if (addRow) {
      setData((prev) => [...(prev ?? []), { title: "" }]);
    }
  }, [addRow]);

  const handleFieldChange = (value: string) => {
    if (data) {
      const modifiedData = [...data];
      modifiedData[data.length - 1].title = value;
      setData([...modifiedData]);
    }
  };

  const handleNewFilm = () => {
    if (data) {
      const newFilmTitle = data[data.length - 1].title;
      createNewFilm(newFilmTitle);
      setAddRow(false);
    }
  };

  useEffect(() => {
    if (success !== "") {
      getFilmList();
      setOpenSnackbar(true);
      setEditRow(false);
      setManipuledId(undefined);
      setEditedFilm("");
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  const handleFileUpdate = (value: string) => {
    setEditedFilm(value);
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid
          container
          paddingY={"15px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={5}>
            <Text fontSize={"16px"} fontWeight={"700"}>
              {t("film.title")}
            </Text>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} sm={6} display={"inline-block"} maxWidth={"100%"}>
            <Text fontSize={"16px"} fontWeight={"500"}>
              {t("film.max_titles")}: {user?.subscriptions?.max_titles}
            </Text>
          </Grid>
          <Grid item xs={12} sm={6} display={"inline-block"} maxWidth={"100%"}>
            <Text fontSize={"16px"} fontWeight={"500"}>
              {t("film.inserted")}: {filmList?.length}
            </Text>
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12}>
            {isFilmLoading ? (
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  marginTop: "20px",
                }}
              >
                <CircularProgress color="secondary" size={"100px"} />
              </div>
            ) : (
              data &&
              data.length > 0 && (
                <TableContainer
                  component={Paper}
                  style={{
                    position: "relative",
                    borderRadius: "8px",
                    maxHeight: "500px",
                    marginTop: "20px",
                  }}
                >
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell>{t("film.head_table")}</TableCell>
                        <TableCell></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody
                      sx={{
                        "& .MuiTableCell-root": {
                          padding: "10px",
                          fontSize: "14px",
                        },
                      }}
                    >
                      {data?.map((film, idx) =>
                        addRow && idx === data.length - 1 ? (
                          <TableRow>
                            <TableCell>
                              <TextField
                                fullWidth
                                type="text"
                                size="small"
                                variant="standard"
                                value={data[data?.length - 1].title}
                                onChange={(e) =>
                                  handleFieldChange(e.target.value)
                                }
                              />
                            </TableCell>
                            <TableCell></TableCell>
                          </TableRow>
                        ) : (
                          <TableRow key={idx}>
                            <TableCell>
                              {editRow && manipulatedId === film.id ? (
                                <TextField
                                  fullWidth
                                  type="text"
                                  size="small"
                                  variant="outlined"
                                  value={editedFilm}
                                  onChange={(e) => {
                                    handleFileUpdate(e.target.value);
                                  }}
                                />
                              ) : (
                                film.title
                              )}
                            </TableCell>
                            <TableCell sx={{ minWidth: "200px" }}>
                              {editRow ? (
                                <Grid container spacing={3}>
                                  <Grid
                                    item
                                    xs={6}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <IconButton
                                      onClick={() =>
                                        manipulatedId &&
                                        editFilm(
                                          manipulatedId.toString(),
                                          editedFilm
                                        )
                                      }
                                    >
                                      <SaveIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        setEditRow(false);
                                        setManipuledId(undefined);
                                        setEditedFilm("");
                                      }}
                                    >
                                      <CancelRoundedIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              ) : (
                                <Grid container spacing={3}>
                                  <Grid
                                    item
                                    xs={6}
                                    display={"flex"}
                                    alignItems={"center"}
                                  >
                                    <IconButton
                                      onClick={() => {
                                        setEditRow(true);
                                        setManipuledId(film.id);
                                        setEditedFilm(film.title);
                                      }}
                                    >
                                      <EditIcon color="primary" />
                                    </IconButton>
                                    <IconButton
                                      onClick={() => {
                                        if (film.id)
                                          removeFilm(film.id.toString());
                                      }}
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Grid>
                                </Grid>
                              )}
                            </TableCell>
                          </TableRow>
                        )
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              )
            )}
          </Grid>
        </Grid>

        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              mt: "40px",
            }}
          >
            <Button
              variant="contained"
              onClick={() => (addRow ? handleNewFilm() : setAddRow(true))}
            >
              {addRow ? t("film.confirm") : t("film.add")}
            </Button>
          </Grid>
        </Grid>
      </Paper>

      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        anchorOrigin={{ horizontal: "center", vertical: "top" }}
        onClose={() => {
          setOpenSnackbar(false);
          resetError();
          resetSuccess();
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false);
            resetError();
            resetSuccess();
          }}
          severity={error ? "error" : "success"}
          sx={{ width: "100%", borderRadius: "10px" }}
        >
          {error ? error : success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    user: authSelectors.getUser(state),
    filmList: filmSelectors.getFilmList(state),
    isFilmLoading: filmSelectors.isFilmLoading(state),
    error: filmSelectors.getFilmError(state),
    success: filmSelectors.getFilmSuccess(state),
  }),
  (dispatch: Dispatch) => ({
    getUser: () => dispatch(authAsync.getUser()),
    getFilmList: () => dispatch(filmAsync.getFilmList()),
    createNewFilm: (title: string) => dispatch(filmAsync.createNewFilm(title)),
    editFilm: (id: string, newTitle: string) =>
      dispatch(filmAsync.editFilm(id, newTitle)),
    removeFilm: (id: string) => dispatch(filmAsync.removeFilm(id)),
    resetSuccess: () => dispatch(filmActions.success({ success: "" })),
    resetError: () => dispatch(filmActions.error({ error: "" })),
  })
)(FilmPage);
