import {
  Research,
  ResearchListAutomatic,
  ResearchListManual,
  ResearchPost,
} from "core/types/research.types";
import { ApiFactoryClients } from "../api.types";
import {
  transformAutomaticResearchGet,
  transformManualResearchGet,
  transformResearch,
  transformResearchListGet,
} from "./research.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  newResearch: (payload: ResearchPost): Promise<Research> =>
    codeHttpClient
      .post("/research", {
        type: payload.type,
        impact: payload.impact,
        origin_sources: payload.originSources?.map((s) => s.toLowerCase()),
        min_duration: payload.minDuration,
        params: payload.params,
        date_from_which_to_research: payload.dateFromWhichToResearch,
      })
      .then(({ data }) => transformResearch(data)),
  getResearchList: (): Promise<ResearchListManual[]> =>
    codeHttpClient
      .get("/research")
      .then(({ data }) => transformResearchListGet(data)),
  getResearch: (id: string): Promise<any> =>
    codeHttpClient.get("/research?id=" + id).then(({ data }) => {
      if (data?.payload?.type === 300) {
        return transformAutomaticResearchGet(data);
      } else {
        return transformManualResearchGet(data);
      }
    }),
});
