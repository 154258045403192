import { CheckoutSession, Transaction } from "core/types/transaction.types";

export const transformTransaction = (data: any): Transaction => ({
  checkoutId: data?.checkout_id,
  id: data?.id,
  createdAt: data?.created_at,
  status: data?.status,
  productId: data?.product_id,
  updatedAt: data?.updated_at,
  userId: data?.user_id,
});

export const transformCheckoutSession = (data: any): CheckoutSession => ({
  url: data?.url,
});
