import { reducerWithInitialState } from "typescript-fsa-reducers";
import * as actions from "./practice.actions";
import { State } from "./practice.types";

export const initialState: Readonly<State> = {
  isPracticeLoading: false,
  practicesList: [],
  practiceDetail: undefined,
  practiceRequest: undefined,
};

export default reducerWithInitialState(initialState)
  .case(actions.resetPractice, () => ({ ...initialState }))
  .case(actions.isPracticeLoading, (state, { isPracticeLoading }) => ({
    ...state,
    isPracticeLoading,
  }))
  .case(actions.practicesList, (state, { practiceList }) => ({
    ...state,
    practicesList: practiceList,
  }))
  .case(actions.practiceDetail, (state, { practiceDetail }) => ({
    ...state,
    practiceDetail: practiceDetail,
  }))
  .case(actions.practiceRequest, (state, { practiceRequest }) => ({
    ...state,
    practiceRequest,
  }))
  .case(actions.error, (state, { error }) => ({
    ...state,
    error,
  }))
  .case(actions.success, (state, { success }) => ({
    ...state,
    success,
  }))
  .build();
