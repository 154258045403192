import styled from "@emotion/styled";
import { Text } from "components/atoms/text";
import { useTranslation } from "react-i18next";
import { Doughnut } from "react-chartjs-2";
import { ThemeProps } from "types/theme";
import { withTheme } from "@emotion/react";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import { customPink } from "core/theme/variants";
import { Source } from "core/types/research.types";
import { useEffect, useState } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartWrapper = styled.div`
  height: 200px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

type Props = {
  list: Source[];
  showLegend?: boolean;
};

const Chart = ({ theme, list, showLegend = false }: ThemeProps & Props) => {
  const [maxValue, setMaxValue] = useState<Source>();

  const data = {
    labels: list.map((l) => l.source),
    datasets: [
      {
        data: list.map((l) => l.value),
        backgroundColor: [
          theme.palette.primary.main,
          customPink[300],
          customPink[100],
          customPink[50],
        ],
      },
    ],
  };
  const options: ChartOptions<"doughnut"> = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: showLegend,
        labels: {
          usePointStyle: true,
          padding: 20,
          pointStyle: "circle",
        },
      },
    },
    elements: {
      arc: {
        borderWidth: 4,
      },
    },
    cutout: showLegend ? "60%" : "80%",
  };

  useEffect(() => {
    if (list.length > 0) {
      let maxItem = list[0];

      list.forEach((item) => {
        if (item.impact > maxItem.impact) {
          maxItem = item;
        }
      });

      setMaxValue(maxItem);
    }
  }, [list]);

  return (
    <>
      <ChartWrapper>
        {!showLegend && (
          <DoughnutInner>
            <Text variant="h4" fontWeight={"700"} fontSize={"27px"}>
              {maxValue?.impact}%
            </Text>
            <Text variant="caption" fontWeight={"600"} fontSize={"12px"}>
              {maxValue?.source}
            </Text>
          </DoughnutInner>
        )}
        <Doughnut data={data} options={options} />
      </ChartWrapper>
    </>
  );
};

export default withTheme(Chart);
