import { RootState } from "../../store.types";
import { State } from "./product.types";

export const getCurrentState = (state: RootState): State => state.product;
export const getProductList = (state: RootState) =>
  getCurrentState(state).productList;
export const getPlanList = (state: RootState) =>
  getCurrentState(state).planList;
export const isProductLoading = (state: RootState) =>
  getCurrentState(state).isProductLoading;
export const getExchangeRate = (state: RootState) =>
  getCurrentState(state).exchangeRate;
