import { LegalPracticeRequest, Practice } from "core/types/practice.types";
import { ApiFactoryClients } from "../api.types";
import {
  transformPractice,
  transformPracticeRequest,
} from "./practice.helpers";

// eslint-disable-next-line import/no-anonymous-default-export
export default ({ codeHttpClient }: ApiFactoryClients<"codeHttpClient">) => ({
  newPractice: (reportId: number): Promise<any> =>
    codeHttpClient
      .post("/legalPractices", {
        report_id: reportId,
      })
      .then(({ data }) => data),
  getPractices: (): Promise<Practice[]> =>
    codeHttpClient
      .get("/legalPractices")
      .then(({ data }) => {return data.map((d: any) => transformPractice(d))}),
  getAdminPractices: (): Promise<Practice[]> =>
    codeHttpClient
      .get("/admin/legalPractices")
      .then(({ data }) => data.map((d: any) => transformPractice(d))),
  getPracticeDetail: (practice_id: string): Promise<Practice> =>
    codeHttpClient
      .get("/legalPractices?practice_id=" + practice_id)
      .then(({ data }) => transformPractice(data)),
  sendRequests: (body: any) =>
    codeHttpClient
      .post("/admin/legalPractices/requests", body)
      .then(({ data }) => data),
  getRequests: (): Promise<LegalPracticeRequest[]> =>
    codeHttpClient
      .get("/admin/legalPractices/requests")
      .then(({ data }) => data.map((d: any) => transformPracticeRequest(d))),
  getRequestsByUrlId: (urlId: string): Promise<LegalPracticeRequest[]> =>
    codeHttpClient
      .get("/admin/legalPractices/requests?url_id=" + urlId)
      .then(({ data }) => data.map((d: any) => transformPracticeRequest(d))),
});
