import { Checkbox, Grid, LinearProgress, Paper, Slider } from "@mui/material";
import { Col } from "components/atoms/col";
import { Row } from "components/atoms/row";
import { Text } from "components/atoms/text";
import { User } from "core/types/user.types";
import { FunctionComponent } from "react";
import { useTranslation } from "react-i18next";
import { format } from "date-fns";
import i18n from "i18n";
import { enGB, it } from "date-fns/locale";

interface Props {
  user: User;
}

const UserSubStats: FunctionComponent<Props> = ({ user }) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;

  const checkStatus = (value: number) => {
    if (value < 33) {
      return "error";
    } else if (value >= 33 && value <= 67) {
      return "warning";
    } else if (value > 67) {
      return "success";
    }
  };

  return (
    <Paper
      sx={{
        width: "100%",
        padding: "20px",
        borderRadius: "10px",
      }}
    >
      <Text variant="h4" fontWeight={"600"} mb={"8px"}>
        {t("profile.subscription-title")}
      </Text>

      {user.subscriptions && (
        <>
          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.sub-name")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Text fontSize={"13px"} fontWeight={"300"}>
                {user?.subscriptions?.title}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.expire")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Text fontSize={"13px"} fontWeight={"300"}>
                {format(
                  new Date(user.subscriptions?.current_period_end),
                  "dd MMM yyyy HH:mm",
                  {
                    locale: linguaCorrente === "it" ? it : enGB,
                  }
                )}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.price")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Text fontSize={"13px"} fontWeight={"300"}>
                {user?.subscriptions?.price.toLocaleString(linguaCorrente) +
                  " €"}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.days-between-reports")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Text fontSize={"13px"} fontWeight={"300"}>
                {user?.subscriptions?.days_between_reports === 30
                  ? t("profile.monthly")
                  : user?.subscriptions?.days_between_reports === 7
                  ? t("profile.weekly")
                  : user?.subscriptions?.days_between_reports === 14
                  ? t("profile.biweekly")
                  : ""}
              </Text>
            </Col>
          </Row>

          <Row
            container
            style={{
              marginBottom: "8px",
              paddingBottom: "8px",
              borderBottom: "0.5px solid #979EB0",
            }}
          >
            <Col item xs={12} md={6}>
              <Text fontSize={"13px"} fontWeight={"500"}>
                {t("profile.available-titles")}
              </Text>
            </Col>
            <Col
              item
              xs={12}
              md={6}
              display={"flex"}
              alignItems={"flex-end"}
              justifyContent={"center"}
            >
              <Text fontSize={"13px"} fontWeight={"300"}>
                {user?.subscriptions?.max_titles}
              </Text>
            </Col>
          </Row>
        </>
      )}
    </Paper>
  );
};

export default UserSubStats;
