import {
  Alert,
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Paper,
  Snackbar,
} from "@mui/material";
import {
  domainActions,
  domainAsync,
  domainSelectors,
} from "core/store/modules/domain";
import { Dispatch, RootState } from "core/store/store.types";
import { Domain, UploadDomain } from "core/types/domain.types";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Text } from "components/atoms/text";
import { DomainsListTable } from "containers/domains-list-table";

interface OwnProps {}
interface StateProps {
  isDomainLoading: boolean;
  domainList?: Domain[];
  success?: string;
  error?: string;
}
interface DispatchProps {
  getDomains: () => void;
  updateDomain: (data: UploadDomain) => void;
  resetSuccess: () => void;
  resetError: () => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const Domains: FunctionComponent<Props> = ({
  isDomainLoading,
  domainList,
  success,
  error,
  getDomains,
  updateDomain,
  resetSuccess,
  resetError,
}) => {
  const { t } = useTranslation();
  const [mailType, setMailType] = useState<"all" | "no_mail" | "no_pec">("all");
  const [domainsListFiltered, setDomainsListFiltered] = useState<Domain[]>();
  const [openSnackbar, setOpenSnackbar] = useState(false);

  useEffect(() => {
    getDomains();
    if (success === "200") {
      setOpenSnackbar(true);
    }
  }, [success]);

  useEffect(() => {
    if (error) {
      setOpenSnackbar(true);
    }
  }, [error]);

  const handleUpdate = (domain: Domain) => {
    updateDomain({
      id: domain.id,
      domain: domain.domain,
      name: domain.name ?? "",
      email: domain.email ?? "",
      pec: domain.pec ?? "",
    });
  };

  useEffect(() => {
    if (mailType === "all") {
      setDomainsListFiltered(domainList);
    }
    if (mailType === "no_mail") {
      setDomainsListFiltered(domainList?.filter((d) => !d.email));
    }
    if (mailType === "no_pec") {
      setDomainsListFiltered(domainList?.filter((d) => !d.pec));
    }
  }, [domainList, mailType]);

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        <Grid
          container
          paddingY={"15px"}
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          <Grid item xs={5}>
            <Text fontSize={"16px"} fontWeight={"700"}>
              {t("domains.title")}
            </Text>
          </Grid>
          <Grid item container spacing={2} xs={7}>
            <Grid item xs={3}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mailType === "all"}
                    onChange={() => setMailType("all")}
                    name="All"
                  />
                }
                label={t("domains.form.all_check")}
              />
            </Grid>
            <Grid item xs={5}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mailType === "no_mail"}
                    onChange={(event) =>
                      event.target.checked
                        ? setMailType("no_mail")
                        : setMailType("all")
                    }
                    name="No mail"
                  />
                }
                label={t("domains.form.no_mail")}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={mailType === "no_pec"}
                    onChange={(event) =>
                      event.target.checked
                        ? setMailType("no_pec")
                        : setMailType("all")
                    }
                    name="No pec"
                  />
                }
                label={t("domains.form.no_pec")}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid container spacing={6}>
          <Grid item xs={12} display={"inline-block"} maxWidth={"100%"}>
            <DomainsListTable
              isDomainLoading={isDomainLoading}
              domainList={domainsListFiltered}
              onUpdateDomain={handleUpdate}
            />
          </Grid>
        </Grid>
      </Paper>
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={() => {
          setOpenSnackbar(false);
          resetError();
          resetSuccess();
        }}
      >
        <Alert
          onClose={() => {
            setOpenSnackbar(false);
            resetError();
            resetSuccess();
          }}
          severity={error ? "error" : "success"}
          sx={{ width: "100%", borderRadius: "10px"  }}
        >
          {error ? error : success}
        </Alert>
      </Snackbar>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    isDomainLoading: domainSelectors.isDomainLoading(state),
    domainList: domainSelectors.getDomainList(state),
    error: domainSelectors.getDomainError(state),
    success: domainSelectors.getDomainSuccess(state),
  }),
  (dispatch: Dispatch) => ({
    getDomains: () => dispatch(domainAsync.getAllDomains()),
    updateDomain: (data: UploadDomain) =>
      dispatch(domainAsync.updateDomain(data)),
    resetSuccess: () => dispatch(domainActions.success({ success: "" })),
    resetError: () => dispatch(domainActions.error({ error: "" })),
  })
)(Domains);
