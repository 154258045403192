import { actionCreatorFactory } from "typescript-fsa";
import { ExchangeRate, Plan, Product } from "../../../types/product.types";
import { Film } from "core/types/film.types";

const actionCreator = actionCreatorFactory("auth");

export const resetFilm = actionCreator<void>("RESET");

export const filmList = actionCreator<{ filmList: Film[] }>("FILM_LIST");

export const isFilmLoading = actionCreator<{ isFilmLoading: boolean }>(
  "IS_FILM_LOADING"
);

export const success = actionCreator<{ success: string }>("FILM_SUCCESS");

export const error = actionCreator<{ error: string }>("FILM_ERROR");
