import React, { FunctionComponent } from "react";
import { Grid, GridProps } from "@mui/material";
import { Text } from "../text";

interface Props {
  title: string;
  description: string;
  styleTitle?: React.CSSProperties;
  styleDescription?: React.CSSProperties;
}

const EmptyState: FunctionComponent<Props> = ({
  title,
  description,
  styleTitle,
  styleDescription,
}) => {
  return (
    <div
      style={{
        backgroundColor: "#ffffff87",
        textAlign: "center",
        borderRadius: "7px",
        padding: "20px 80px",
      }}
    >
      <Text
        fontSize={"30px"}
        fontWeight={"700"}
        style={{ whiteSpace: "pre-wrap", ...styleTitle }}
      >
        {title}
      </Text>
      <Text
        fontSize={"14px"}
        fontWeight={"600"}
        mt={2}
        style={{ whiteSpace: "pre-wrap", ...styleDescription }}
      >
        {description}
      </Text>
    </div>
  );
};

export default EmptyState;
