import { productApi } from "../../../api";
import { Dispatch } from "../../store.types";
import * as actions from "./product.actions";

export const getProductList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isProductLoading({ isProductLoading: true }));
  try {
    const productList = await productApi.getProduct();
    dispatch(actions.productList({ productList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isProductLoading({ isProductLoading: false }));
  }
};

export const getExchangeRate = () => async (dispatch: Dispatch) => {
  dispatch(actions.isProductLoading({ isProductLoading: true }));
  try {
    const exchangeRate = await productApi.getExchangeRate();
    dispatch(actions.exchangeRate({ exchangeRate }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isProductLoading({ isProductLoading: false }));
  }
};

export const getPlanList = () => async (dispatch: Dispatch) => {
  dispatch(actions.isProductLoading({ isProductLoading: true }));
  try {
    const planList = await productApi.getPlans();
    dispatch(actions.planList({ planList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isProductLoading({ isProductLoading: false }));
  }
};
