import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  CircularProgress,
  Grid,
  Paper,
} from "@mui/material";
import { FunctionComponent, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Dispatch, RootState } from "core/store/store.types";
import i18n from "i18next";
import "dayjs/locale/it";
import "dayjs/locale/en";
import { ResearchListAutomatic, Source } from "core/types/research.types";
import { Text } from "components/atoms/text";
import { format, parseISO } from "date-fns";
import { enGB, it } from "date-fns/locale";
import { Chart } from "components/molecules/chart";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ResearchTable } from "components/organisms/research-table";
import { Bar } from "react-chartjs-2";
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  Title,
} from "chart.js";
import { customPink } from "core/theme/variants";

ChartJS.register(LinearScale, CategoryScale, BarElement, Title);

interface OwnProps {
  selectedResearch: string;
  isResearchLoading: boolean;
  researchDetail: ResearchListAutomatic;
  onGenerateReportButton: () => void;
  selectedVideos: string[];
  setSelectedVideos?: (videos: string[]) => void;
}

interface StateProps {}

interface DispatchProps {}

export type Props = OwnProps & StateProps & DispatchProps;

const ResearchDetail: FunctionComponent<Props> = ({
  selectedResearch,
  isResearchLoading,
  researchDetail,
  onGenerateReportButton,
  selectedVideos,
  setSelectedVideos,
}) => {
  const { t } = useTranslation();
  const linguaCorrente = i18n.language;
  const [sourceList, setSourceList] = useState<Source[]>([]);
  const [data, setData] = useState<any>();

  const options: ChartOptions<"bar"> = {
    indexAxis: "y",
    scales: {
      x: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          display: false,
        },
      },
    },
    plugins: {
      legend: {
        display: false,
      },
    },
  };

  useEffect(() => {
    setSourceList(getOriginSources());
    setData({
      labels: researchDetail.results?.map((r) => r.filmTitle),
      datasets: [
        {
          label: t("research_detail.num_of_results"),
          data: researchDetail.results?.map((r) => r.results.length),
          backgroundColor: [customPink[500]],
          borderColor: [customPink[500]],
          borderWidth: 1,
          borderRadius: 20,
          barThickness: 8,
          barPercentage: 1,
          categoryPercentage: 1,
        },
      ],
    });
  }, [researchDetail]);

  const getOriginSources = () => {
    const sourceMap = new Map<string, Source>();

    if (researchDetail.results) {
      researchDetail.results?.forEach((ricerca) => {
        ricerca.results?.forEach((result) => {
          const sourceKey = result.source.toLowerCase();
          if (!sourceMap.has(sourceKey)) {
            sourceMap.set(sourceKey, {
              source:
                result.source.charAt(0).toUpperCase() + result.source.slice(1), // Capitalize the source name
              value: 1,
              impact: 0,
            });
          } else {
            const sourceData = sourceMap.get(sourceKey);
            sourceData && sourceData.value++;
          }
        });
      });

      const resultList = Array.from(sourceMap.values());

      const totalValues = resultList.reduce((acc, item) => acc + item.value, 0);

      resultList.forEach((item) => {
        item.impact = Math.round((item.value / totalValues) * 100);
      });

      return resultList;
    }
    return [];
  };

  return (
    <>
      <Paper
        sx={{
          borderRadius: "10px",
          padding: "20px",
        }}
      >
        {isResearchLoading ? (
          <div
            style={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <CircularProgress color="secondary" size={"100px"} />
          </div>
        ) : (
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <Text>
                {t("research_detail.code")} <b>{selectedResearch}</b>
              </Text>
              <Text>
                {t("research_detail.date_hour")}{" "}
                <b>
                  {format(
                    parseISO(researchDetail.createdAt?.replace(" ", "T") + "Z"),
                    "dd/MM/yyyy  HH:mm",
                    {
                      locale: linguaCorrente === "it" ? it : enGB,
                    }
                  )}
                </b>
              </Text>
              <Box>{data && <Bar data={data} options={options} />}</Box>
            </Grid>
            <Grid
              item
              xs={12}
              sm={6}
              display={"flex"}
              alignItems={"center"}
              justifyContent={"flex-end"}
            >
              <Chart list={sourceList} showLegend />
            </Grid>
          </Grid>
        )}
      </Paper>

      <Grid container spacing={3} mt={"10px"}>
        {researchDetail.results?.map((p, idx) => (
          <Grid item xs={12} key={idx}>
            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Text
                  variant="h3"
                  sx={{
                    textDecoration: "underline",
                  }}
                >
                  {p.filmTitle}
                </Text>
              </AccordionSummary>
              <AccordionDetails>
                <ResearchTable
                  research={{
                    id: idx,
                    results: p.results,
                  }}
                  selectedVideos={selectedVideos}
                  setSelectedVideos={setSelectedVideos}
                  onGenerateReportButton={onGenerateReportButton}
                  detailMode
                />
              </AccordionDetails>
            </Accordion>
          </Grid>
        ))}
      </Grid>
    </>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({}),
  (dispatch: Dispatch) => ({})
)(ResearchDetail);
