import {
  Button,
  Checkbox,
  Grid,
  Input,
  TableCell,
  TableRow,
} from "@mui/material";
import { Row } from "components/atoms/row";
import { Text } from "components/atoms/text";
import { TableData } from "pages/admin/admin";
import { FunctionComponent, useState } from "react";
import { useTranslation } from "react-i18next";

import { codeApi } from "core/api";
import "./admin-table-row.css";

interface Props {
  data: TableData;
}

const AdminTableRow: FunctionComponent<Props> = ({ data }) => {
  const { t } = useTranslation();
  const [user, setUser] = useState(data);

  const handleActivateUser = async () => {
    await codeApi.activateUser(user.id);
    setUser({ ...user, confirmed_user: true });
  };

  return (
    <>
      {user.billing_info && (
        <TableRow>
          <TableCell>
            <Text className="text">{user.firstName}</Text>
          </TableCell>
          <TableCell>
            <Text className="text">{user.lastName}</Text>
          </TableCell>
          <TableCell>
            <div
              style={{ maxWidth: "240px", cursor: "pointer" }}
              onClick={() =>
                window.open("mailto:" + user.email, "_blank", "noreferrer")
              }
            >
              <Text
                secondary
                style={{
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
                className="text"
              >
                {user.email}
              </Text>
            </div>
          </TableCell>
          <TableCell>
            <Row
              display={"flex"}
              justifyContent={"center"}
              alignItems={"center"}
            >
              <div className={user.confirmed_user ? "success" : "error"} />
              <Text marginLeft={1} className="text">
                {user.confirmed_user ? t("admin.enabled") : t("admin.disabled")}
              </Text>
            </Row>
          </TableCell>
          <TableCell>
            {user.editMode ? (
              <Input
                style={{ maxWidth: "60px" }}
                type="number"
                value={user?.billing_info?.searches_number}
                onChange={(event) =>
                  user?.billing_info &&
                  setUser({
                    ...user,
                    billing_info: {
                      ...user.billing_info,
                      searches_number: Number(event.target.value),
                    },
                  })
                }
              />
            ) : (
              <Text style={{ maxWidth: "60px" }} className="text">
                {user?.billing_info?.searches_number}
              </Text>
            )}
          </TableCell>
          <TableCell>
            {user.editMode ? (
              <Input
                style={{ maxWidth: "60px" }}
                type="number"
                value={user?.billing_info?.report_generation_number}
                onChange={(event) =>
                  user?.billing_info &&
                  setUser({
                    ...user,
                    billing_info: {
                      ...user.billing_info,
                      report_generation_number: Number(event.target.value),
                    },
                  })
                }
              />
            ) : (
              <Text className="text" style={{ maxWidth: "60px" }}>
                {user?.billing_info?.report_generation_number}
              </Text>
            )}
          </TableCell>
          <TableCell>
            {user.editMode ? (
              <Input
                style={{ maxWidth: "60px" }}
                type="number"
                value={user?.billing_info?.report_maintenance_months}
                onChange={(event) =>
                  user?.billing_info &&
                  setUser({
                    ...user,
                    billing_info: {
                      ...user.billing_info,
                      report_maintenance_months: Number(event.target.value),
                    },
                  })
                }
              />
            ) : (
              <Text className="text" style={{ maxWidth: "60px" }}>
                {user?.billing_info?.report_maintenance_months}
              </Text>
            )}
          </TableCell>
          <TableCell>
            <Checkbox
              disabled={!user.editMode}
              checked={user?.billing_info?.blockchain_sign}
              style={{ maxWidth: "60px" }}
              onChange={() =>
                user?.billing_info &&
                setUser({
                  ...user,
                  billing_info: {
                    ...user.billing_info,
                    blockchain_sign: !user?.billing_info?.blockchain_sign,
                  },
                })
              }
            />
          </TableCell>
          <TableCell>
            {user.editMode ? (
              <Input
                style={{ maxWidth: "60px" }}
                type="number"
                value={user?.billing_info?.expert_consultancy_number}
                onChange={(event) =>
                  user?.billing_info &&
                  setUser({
                    ...user,
                    billing_info: {
                      ...user.billing_info,
                      expert_consultancy_number: Number(event.target.value),
                    },
                  })
                }
              />
            ) : (
              <Text className="text" style={{ maxWidth: "60px" }}>
                {user?.billing_info?.expert_consultancy_number}
              </Text>
            )}
          </TableCell>
          <TableCell>
            {user.editMode ? (
              <Input
                type="number"
                style={{ maxWidth: "60px" }}
                value={user?.billing_info?.legal_support_consultancy_number}
                onChange={(event) =>
                  user?.billing_info &&
                  setUser({
                    ...user,
                    billing_info: {
                      ...user?.billing_info,
                      legal_support_consultancy_number: Number(
                        event.target.value
                      ),
                    },
                  })
                }
              />
            ) : (
              <Text className="text" style={{ maxWidth: "60px" }}>
                {user?.billing_info?.legal_support_consultancy_number}
              </Text>
            )}
          </TableCell>
          <TableCell sx={{ minWidth: "200px" }}>
            <div style={{ display: "flex", justifyContent: "center" }}>
              <Row
                container
                justifyContent={"space-around"}
                alignItems={"center"}
                style={{ width: "150px" }}
              >
                {!user.confirmed_user && (
                  <Button
                    variant="outlined"
                    color="info"
                    onClick={handleActivateUser}
                  >
                    {t("admin.actionsLabels.activate")}
                  </Button>
                )}
                {!user.editMode && (
                  <Button
                    variant="contained"
                    onClick={() => setUser({ ...user, editMode: true })}
                  >
                    {t("admin.actionsLabels.edit")}
                  </Button>
                )}
                {user.editMode && (
                  <Grid container spacing={1}>
                    <Grid item xs={6}>
                      <Button
                        variant="contained"
                        onClick={() => {
                          codeApi.updateUser(user);
                          setUser({ ...user, editMode: false });
                        }}
                      >
                        {t("admin.actionsLabels.save")}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        variant="outlined"
                        onClick={() => setUser({ ...user, editMode: false })}
                      >
                        {t("admin.actionsLabels.cancel")}
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Row>
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
};

export default AdminTableRow;
