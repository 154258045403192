import { PageTitle } from "components/atoms/page-title";
import { Row } from "components/atoms/row";
import { ReportListTable } from "containers/report-list-table";
import { reportAsync, reportSelectors } from "core/store/modules/report";
import { Dispatch, RootState } from "core/store/store.types";
import { Report } from "core/types/report.types";
import { FunctionComponent, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Text } from "components/atoms/text";
import { SecondaryButton } from "components/atoms/secondary-button";
import { Paper } from "@mui/material";

interface OwnProps {}

interface StateProps {
  report?: Report;
  isReportLoading: boolean;
}

interface DispatchProps {
  getReport: (id: string) => void;
}

export type Props = OwnProps & StateProps & DispatchProps;

const ReportDetail: FunctionComponent<Props> = ({
  report,
  isReportLoading,
  getReport,
}) => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    if (id) {
      getReport(id);
    }
  }, [id]);

  return (
    <Paper
      sx={{
        borderRadius: "10px",
        padding: "20px",
      }}
    >
      <Text fontSize={"16px"} fontWeight={"700"} paddingY={"15px"}>
        {t("report_detail.title")}
      </Text>
      <Row display={"block"} justifyContent={"center"}>
        {report && (
          <ReportListTable
            reportList={[report]}
            isReportLoading={isReportLoading}
          />
        )}
      </Row>
      <Row display={"flex"} flexDirection={"column"} alignItems={"center"}>
        <Text
          fontWeight={"500"}
          letterSpacing={"0.9px"}
          fontSize={"13px"}
          mt={"25px"}
          textAlign={"center"}
        >
          {t("report_detail.footer")}
        </Text>
        <SecondaryButton onClick={() => navigate("/my-reports")}>
          {t("report_detail.button")}
        </SecondaryButton>
      </Row>
    </Paper>
  );
};

export default connect<StateProps, DispatchProps, OwnProps, RootState>(
  (state: RootState) => ({
    report: reportSelectors.getReport(state),
    isReportLoading: reportSelectors.isReportLoading(state),
  }),
  (dispatch: Dispatch) => ({
    getReport: (id: string) => dispatch(reportAsync.getReport(id)),
  })
)(ReportDetail);
