import { FunctionComponent } from "react";
import {
  Box,
  Button,
  Fade,
  Grid,
  IconButton,
  Modal,
  ModalProps,
  Paper as MuiPaper,
} from "@mui/material";
import Backdrop from "@mui/material/Backdrop";
import { customPink } from "core/theme/variants";
import styled from "@emotion/styled";
import { spacing } from "@mui/system";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  // maxHeight: "70%",
  bgcolor: customPink[700],
  borderRadius: "15px",
  p: 5,
};

const Paper = styled(MuiPaper)(spacing);

const Content = styled(Paper)`
  flex: 1;
  background: #fff;
  padding: 30px;
  border-radius: 15px;
  position: relative;
`;

type Props = {
  goBackText?: string;
  submitText?: string;
  setIsVisible: (b: boolean) => void;
  onSubmitClick?: () => void;
  onDiscardClick?: () => void;
};

const InfoModal: FunctionComponent<Props & ModalProps> = ({
  children,
  goBackText,
  submitText,
  open,
  setIsVisible,
  onSubmitClick,
  onDiscardClick,
}) => {
  return (
    <>
      <Modal
        open={open}
        onClose={() => setIsVisible(false)}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Content >
              <IconButton
                onClick={() => {
                  setIsVisible(false);
                }}
                sx={{
                  position: "absolute",
                  right: 5,
                  top: 5,
                }}
              >
                <CloseRoundedIcon color="info" />
              </IconButton>
              <Box mt={"10px"}>{children}</Box>
              <Grid
                container
                display={"flex"}
                justifyContent={"flex-end"}
                spacing={2}
                mt={"20px"}
              >
                {onDiscardClick && (
                  <Grid item>
                    <Button variant="outlined" onClick={() => onDiscardClick()}>
                      {goBackText}
                    </Button>
                  </Grid>
                )}
                {onSubmitClick && (
                  <Grid item>
                    {" "}
                    <Button variant="contained" onClick={() => onSubmitClick()}>
                      {submitText}
                    </Button>
                  </Grid>
                )}
              </Grid>
            </Content>
          </Box>
        </Fade>
      </Modal>
    </>
  );
};

export default InfoModal;
