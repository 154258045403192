import React, { FunctionComponent } from "react";
import styled from "@emotion/styled";
import { Power } from "react-feather";
import { useNavigate } from "react-router-dom";

import {
  Tooltip,
  Menu,
  MenuItem,
  IconButton as MuiIconButton,
} from "@mui/material";
import { useTranslation } from "react-i18next";

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`;

interface Props {
  onSignOut: () => void;
}

const NavbarUserDropdown: FunctionComponent<Props> = ({ onSignOut }) => {
  const { t } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState<any>(null);
  const navigate = useNavigate();

  const toggleMenu = (event: React.SyntheticEvent) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const handleSignOut = async () => {
    onSignOut();
  };

  return (
    <React.Fragment>
      <Tooltip title="Account">
        <IconButton
          aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
          aria-haspopup="true"
          onClick={toggleMenu}
          color="inherit"
          size="large"
        >
          <Power color="#1D2C54" />
        </IconButton>
      </Tooltip>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
      >
        <MenuItem
          onClick={() => {
            closeMenu();
            navigate("/my-profile");
          }}
        >
          {t("profile.title")}
        </MenuItem>
        <MenuItem onClick={handleSignOut}>{t("drawer.logout")}</MenuItem>
      </Menu>
    </React.Fragment>
  );
};

export default NavbarUserDropdown;
