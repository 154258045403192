import { ExchangeRate, Plan, Product } from "core/types/product.types";

export const transformProductList = (data: any): Product[] => {
  return data.map((product: any) => transformProduct(product));
};

export const transformPlanList = (data: any): Plan[] => {
  return data.map((plan: any) => transformPlan(plan));
};

export const transformProduct = (data: any): Product => ({
  id: data?.id,
  title: data?.title,
  features: data?.features.map((f: any) => f.name),
  daysDurate: data?.days_durate,
  currency: data?.currency,
  description: data?.description,
  price: data?.price,
  blockchainSign: data?.blockchain_sign,
  expertConsultancyNumber: data?.expert_consultancy_number,
  legalSupportConsultancyNumber: data?.legal_support_consultancy_number,
  reportGenerationNumber: data?.report_generation_number,
  reportMaintenanceMonths: data?.report_maintenance_months,
  searchesNumber: data?.searches_number,
});

export const transformPlan = (data: any): Plan => ({
  daysBetweenReports: data?.days_between_reportsn,
  description: data?.description,
  env: data?.env,
  features: data?.features,
  maxTitles: data?.max_titles,
  name: data?.name,
  productId: data?.prod_id,
  prices: data?.prices?.map((p: any) => ({
    id: p?.id,
    currency: p?.currency,
    duration: p?.duration,
    price: p?.price,
    priceForMonth: p?.price_for_month,
  })),
});

export const transformExchangeRate = (data: any): ExchangeRate => ({
  base: data?.base,
  localISODate: data?.localISODate,
  putISODate: data?.putISODate,
  rates: {
    EUR: data?.rates["EUR"],
    GBP: data?.rates["GBP"],
    USD: data?.rates["USD"],
  },
});
