import styled from "@emotion/styled";
import { Text } from "components/atoms/text";
import { Doughnut } from "react-chartjs-2";
import { ThemeProps } from "types/theme";
import { withTheme } from "@emotion/react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { useEffect, useState } from "react";
import { customPink } from "core/theme/variants";
import { useTranslation } from "react-i18next";

ChartJS.register(ArcElement, Tooltip, Legend);

const ChartWrapper = styled.div`
  height: 160px;
  position: relative;
`;

const DoughnutInner = styled.div`
  width: 100%;
  position: absolute;
  top: 50%;
  left: 0;
  margin-top: -22px;
  text-align: center;
  z-index: 0;
`;

type Props = {
  list: Record<string, number>;
};

const Chart = ({ theme, list }: ThemeProps & Props) => {
  const { t } = useTranslation();
  const [maxValue, setMaxValue] = useState<[string, number] | null>();

  const data = {
    labels: Object.keys(list).map((l) => t("practices.detail.status." + l)),
    datasets: [
      {
        data: Object.keys(list).map((key) => list[key]),
        backgroundColor: [
          theme.palette.primary.main,
          customPink[300],
          customPink[100],
          customPink[50],
        ],
      },
    ],
  };
  const options = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
      },
    },
    elements: {
      arc: {
        borderWidth: 4,
      },
    },
    cutout: "80%",
  };

  function findMaxValuePair(
    obj: Record<string, number>
  ): [string, number] | null {
    let maxKey: string | null = null;
    let maxValue: number | null = null;

    for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
        const value = obj[key];

        if (maxValue === null || value > maxValue) {
          maxKey = key;
          maxValue = value;
        }
      }
    }

    if (maxKey !== null && maxValue !== null) {
      return [maxKey, maxValue];
    } else {
      return null;
    }
  }

  useEffect(() => {
    setMaxValue(findMaxValuePair(list));
  }, [list]);

  return (
    <>
      <ChartWrapper>
        <DoughnutInner>
          <Text variant="h4" fontWeight={"700"} fontSize={"27px"}>
            {maxValue && maxValue[1]}
          </Text>
          <Text variant="caption" fontWeight={"600"} fontSize={"12px"}>
            {maxValue && t("practices.detail.status." + maxValue[0])}
          </Text>
        </DoughnutInner>
        <Doughnut data={data} options={options} />
      </ChartWrapper>
    </>
  );
};

export default withTheme(Chart);
