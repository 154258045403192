import { domainApi } from "core/api";
import { Dispatch } from "../../store.types";
import * as actions from "./domain.actions";
import { UploadDomain } from "core/types/domain.types";

export const getAllDomains = () => async (dispatch: Dispatch) => {
  dispatch(actions.isDomainLoading({ isDomainLoading: true }));
  try {
    const domainList = await domainApi.getDomains();
    dispatch(actions.domainList({ domainList: domainList }));
  } catch (error) {
    console.error(error);
  } finally {
    dispatch(actions.isDomainLoading({ isDomainLoading: false }));
  }
};

export const updateDomain =
  (data: UploadDomain) => async (dispatch: Dispatch) => {
    dispatch(actions.isDomainLoading({ isDomainLoading: true }));
    try {
      await domainApi.updateDomain(data);
      dispatch(actions.success({ success: "Domain updated correctly" }));
    } catch (error: any) {
      console.error(error);
      dispatch(actions.error({ error: error.response.data }));
    } finally {
      dispatch(actions.isDomainLoading({ isDomainLoading: false }));
    }
  };
